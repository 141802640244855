import React, { useRef, useState } from "react";
import axios from "axios";
import { toastr } from "components/toastr";
import { setLocalStorage } from "util/index";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { store } from "store/index";
import API from "API";

const Login = (props) => {
	console.log("Login:");
	const location = useLocation();
	const [loading, setLoading] = useState();
	const [error, setError] = useState();
	const [rememberMeChecked, setRememberMeChecked] = useState(true);
	const emailRef = useRef();
	const passwordRef = useRef();
	const checkboxRef = useRef();
	const navigate = useNavigate();
	const params = useParams();

	const fetchInitialStates = async () => {
		API.get("/initial_states.json", { params: { light: true } })
			.then((result) => {
				if (result.data && !result.data.erorr) {
					store.dispatch({ type: "SET_ACCOUNTS", accounts: result.data.accounts });
					store.dispatch({ type: "SET_USERS", users: result.data.users });
					store.dispatch({ type: "SET_GROUPS", groups: result.data.groups });
				}
			})
			.catch((error) => {
				console.log("error:", error);
				toastr.error(error);
			});
	};

	const onSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		const username = emailRef.current.value;
		const password = passwordRef.current.value;
		const urlParams = new URLSearchParams(location.search || location?.state?.search);

		const tokens = await axios
			.post(
				"https://www.sellfinity.com/api/login_check",
				// "http://127.0.0.1:8000/api/login_check",
				{
					username,
					password,
					acc_id: params.accountId || urlParams.get("acc_id"),
					// push_token: pushToken,
					// version: store.getState().version,
				}
			)
			.then(async (result) => {
				if (result.data.error) {
					console.log("error:", result.data.error);
					toastr.error(result.data.error);
					setError(result.data.error);
					return;
				}

				store.dispatch({ type: "SET_TOKEN", token: result.data.token });
				store.dispatch({ type: "SET_REFRESH_TOKEN", refresh_token: result.data.refresh_token });
				if (rememberMeChecked) {
					setLocalStorage("refresh_token", result.data.refresh_token);
					setLocalStorage("jwt_token", result.data.token);
				}

				return result.data;
			})
			.catch((error) => {
				console.log("error:", error);
				toastr.error(error);
				setLoading(false);
			});
		if (tokens) {
			Promise.all([await API.get("/me.json").then((r) => r.data.user), await API.get("/account.json").then((r) => r.data)])
				.then((res) => {
					const [myUser, myAccount] = res;

					if (myUser) {
						store.dispatch({ type: "SET_USER", user: myUser });
						// eslint-disable-next-line no-undef
						user = myUser;
					}

					if (myAccount) {
						store.dispatch({ type: "SET_ACCOUNT", account: myAccount });
						setLocalStorage("accountId", myAccount.id);
					}

					fetchInitialStates();
					if (myAccount.id) {
						navigate(location?.state?.url || `/${myAccount.id}/chat`);
					}
				})
				.catch((er) => {
					console.error("er:", er);
				})
				.finally(() => {
					setLoading(false);
				});
		}
	};

	return (
		<div className="wrapper">
			<div
				className="toolbar"
				style={{ height: "35px", width: "100%", position: "fixed", "-webkit-app-region": "drag", left: 0, right: 0, top: 0 }}
			/>
			<div className="left_wrapper">
				<h1>Logga in</h1>
			</div>

			<div className="right_wrapper">
				<div className="form_wrapper" style={{ maxWidth: "unset" }}>
					<a
						className="logo_link"
						style={{ display: "flex", marginBottom: "50px", marginTop: "-100px", position: " unset" }}
						href="https://www.sellfinity.com"
					>
						<img alt="" className="logo" src="/sellfinity/sellfinity-highres-login-c.png" />
					</a>
					<h1 className="form_title">Välkommen tillbaka!</h1>
					<form id="loginform" className="form_wrapper" onSubmit={onSubmit}>
						<div>
							<label className="label">E-post</label>
							<input type="text" className="form_input" ref={emailRef} />
						</div>
						<div>
							<label className="label">Lösenord</label>
							<input className="form_input" type="password" ref={passwordRef} />
						</div>

						<div style={{ display: "flex", gap: "0.5rem", color: "#ffffff", alignItems: "center" }}>
							<input
								type="checkbox"
								id="remember_me"
								name="_remember_me"
								checked={rememberMeChecked}
								ref={checkboxRef}
								onChange={(v) => {
									setRememberMeChecked(v.target.checked);
								}}
							/>
							<span>Kom ihåg mig</span>
						</div>
						<button className="form_button light fullWidth" type="submit" id="_submit" name="_submit" loading={String(loading)}>
							<div className="spinner">
								<div></div>
								<div></div>
								<div></div>
								<div></div>
							</div>

							<span>Logga in</span>
						</button>
						{error && <div className="invalid form-error">{error}</div>}
					</form>
				</div>
			</div>
		</div>
	);
};
export default Login;
