import { Select, Tabs } from "@shopify/polaris";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { SettingsSection } from "views/chat/ChatSettings";
import ProfileAvatar from "components/ProfileAvatar";
import Switch from "components/Switch";
import { store } from "store/index";
import { setLocalStorage } from "util/index";
import VersionInfo from "./VersionInfo";

const ModalContent = () => {
	const [electronSettings, setElectronSettings] = useState({});
	const [settings, setSettings] = useState({});
	const navigate = useNavigate();
	const [tab, setTab] = useState("general");
	const tabs = [
		{
			content: "Allmänt",
			id: "general",
			accessibilityLabel: "general",
			panelID: "general",
		},
		{
			content: "Notifikationer",
			id: "notifications",
			accessibilityLabel: "notifications",
			panelID: "notifications",
		},
		{
			content: "Windows",
			id: "windows",
			accessibilityLabel: "windows",
			panelID: "windows",
			enabled: ["win32", "win64"].includes(window?.electron?.platform),
		},
	].filter((t) => !("enabled" in t) || t.enabled);

	const onSelectTab = (index) => {
		setTab(tabs[index]?.id);
	};

	useEffect(() => {
		(async () => {
			if (window.electron?.platform === "win32" || window.electron?.platform === "win64") {
				console.log(await window.electron?.getLoginItemSettings());
				setElectronSettings((await window.electron?.getLoginItemSettings()) || {});
			}
			setSettings((await window.electron?.getStore?.()) || {});
		})();
	}, []);

	const onChangeElectronSettings = (opt) => {
		setElectronSettings((c) => ({ ...c, ...opt }));
		window.electron.setLoginItemSettings(opt);

		setTimeout(async () => {
			setElectronSettings((await window.electron?.getLoginItemSettings()) || {});
		}, 0);
	};

	const onChange = (opt) => {
		setSettings((c) => ({ ...c, ...opt }));
		window.electron.setStoreValue(opt);
	};

	const getContent = () => {
		switch (tab) {
			case "notifications":
				return (
					<SettingsSection title="Notifikationer">
						{window.electron && (
							<StyledToggleWrapper>
								<Switch
									onChange={(enabled) => {
										onChange({ flash_enabled: enabled });
									}}
									checked={!!settings?.flash_enabled}
								/>
								<span>Flasha appen i {window.electron.platform === "darwin" ? "docken" : "aktivitetsfältet"} vid nya aviseringar</span>
							</StyledToggleWrapper>
						)}
					</SettingsSection>
				);
			case "windows":
				return (
					<SettingsSection title="Windows inställningar">
						<StyledToggleWrapper>
							<Switch
								onChange={(enabled) => {
									onChangeElectronSettings({ openAtLogin: true, executableWillLaunchAtLogin: enabled, enabled });
								}}
								checked={!!electronSettings?.executableWillLaunchAtLogin}
								// checked={!!electronSettings?.launchItems?.[0]?.enabled}
							/>
							<span>Starta tillsammans med windows</span>
						</StyledToggleWrapper>
						{/* <StyledToggleWrapper>
							<Switch
								onChange={(enabled) => {
									onChange({ flash_enabled: enabled });
								}}
								checked={!!settings?.flash_enabled}
							/>
							<span>Flasha appen i aktivitetsfältet vid nya aviseringar</span>
						</StyledToggleWrapper> */}

						{/* <StyledToggleWrapper>
								<Switch
									disabled={!electronSettings?.openAtLogin}
									height={20}
									width={50}
									uncheckedIcon={null}
									checkedIcon={null}
									onChange={(openAsHidden) => {
										onChangeElectronSettings({ openAsHidden });
									}}
									checked={!!electronSettings?.openAsHidden}
								/>
								<span>Starta minimerad</span>
							</StyledToggleWrapper> */}
					</SettingsSection>
				);
			case "general":
			default:
				return (
					<>
						<SettingsSection title={"Profilbild"} style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
							<ProfileAvatar size={50} user={store.getState().user} />
							<div>
								<h3>{store.getState().user.name}</h3>
								<p>{store.getState().user.email}</p>
							</div>
						</SettingsSection>
						{store.getState().accounts?.length > 0 && (
							<SettingsSection title="Konto">
								<Select
									disabled={store.getState().accounts?.length <= 1}
									value={String(store.getState().account.id)}
									options={store.getState().accounts.map((acc) => {
										return {
											label: acc.company,
											value: String(acc.id),
										};
									})}
									onChange={(accountId) => {
										navigate(`/${accountId}/auth?acc_id=${accountId}`);
										setLocalStorage("accountId", accountId);
										setLocalStorage("refresh_token", null);
										setLocalStorage("token", null);
									}}
								/>
							</SettingsSection>
						)}
					</>
				);
		}
	};

	return (
		<>
			<Tabs tabs={tabs} onSelect={onSelectTab} selected={tabs.findIndex((t) => t.id === tab)} />
			{getContent()}
			<VersionInfo />
		</>
	);
};
export default ModalContent;

const StyledToggleWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 0.5rem;
	margin: 1rem 0;
`;
