import React from "react";

const DocumentsIcon = ({ color = "#ffffff" }) => (
	<svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M21.0352 28.6685H7.03516C6.76994 28.6685 6.51559 28.5631 6.32805 28.3756C6.14051 28.188 6.03516 27.9337 6.03516 27.6685V9.66846C6.03516 9.40324 6.14051 9.14889 6.32805 8.96135C6.51559 8.77381 6.76994 8.66846 7.03516 8.66846H17.0352L22.0352 13.6685V27.6685C22.0352 27.9337 21.9298 28.188 21.7423 28.3756C21.5547 28.5631 21.3004 28.6685 21.0352 28.6685Z"
			stroke={color}
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M10.0352 8.66846V5.66846C10.0352 5.40324 10.1405 5.14889 10.328 4.96135C10.5156 4.77381 10.7699 4.66846 11.0352 4.66846H21.0352L26.0352 9.66846V23.6685C26.0352 23.9337 25.9298 24.188 25.7423 24.3756C25.5547 24.5631 25.3004 24.6685 25.0352 24.6685H22.0352"
			stroke={color}
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path d="M11.0352 19.6685H17.0352" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
		<path d="M11.0352 23.6685H17.0352" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
	</svg>
);

export default DocumentsIcon;
