const accounts = (state = {}, action) => {
	switch (action.type) {
		case "SET_ACCOUNTS":
			return action.accounts;
		case "ADD_ACCOUNT":
			var newState = state.slice();
			newState.push(action.account);
			return newState;
		case "UPDATE_ACCOUNT":
			const index = state.findIndex((acc) => String(acc.id) === String(action.account.id));
			state[index] = action.account;

			return state.slice();
		default:
			return state;
	}
};
export default accounts;
