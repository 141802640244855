import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { AppProvider } from "@shopify/polaris";
import { store } from "./store";
import { Provider } from "react-redux";
import Routes from "./Routes";
import "./Properties";
import "./index.scss";
import ReduxToastr from "react-redux-toastr";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";
import { Buffer } from "buffer";
global.Buffer = Buffer;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<>
		<AppProvider i18n={store.getState().translation}>
			<Provider store={store}>
				<Routes />
				<ReduxToastr
					timeOut={4000}
					newestOnTop={false}
					preventDuplicates
					position="bottom-center"
					transitionIn="fadeIn"
					transitionOut="fadeOut"
					progressBar
					closeOnToastrClick
				/>
			</Provider>
		</AppProvider>
	</>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
