import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import { markdownToDraft } from "markdown-draft-js";
import { getLocalstorage, removeLocalStorage, setLocalStorage } from "../../util";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import CodeBlock from "./blocks/CodeBlock";

var reg = /^@\[([^\]]*)\]\s*\(([^)]+)\)/;
const remarkableMentionPlugin = (md) => {
	md.inline.ruler.push("mention", (state, checkMode) => {
		// it is surely not our rule, so we could stop early
		if (state.src[state.pos] !== "@") return false;

		var match = reg.exec(state.src.slice(state.pos));
		if (!match) return false;

		// in silent mode it shouldn't output any tokens or modify pending
		if (!checkMode) {
			state.push({
				type: "mention_open",
				name: `@${match[1]}`,
				link: match[2],
				level: state.level,
			});
		}

		// every rule should set state.pos to a position after token's contents
		state.pos += match[0].length;

		return true;
	});
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const markdownToDraftOptions = {
	remarkablePlugins: [remarkableMentionPlugin],
	// Why isn't this getting called???
	// blockTypes: {
	// 	mention_open: function (item) {
	// 		console.log("itemmm", item);
	// 		return {
	// 			type: "mention",
	// 			mutability: "SEGMENTED",
	// 			data: {
	// 				mention: {
	// 					name: item.name,
	// 					link: item.link,
	// 				},
	// 			},
	// 		};
	// 	},
	// },
	blockEntities: {
		mention_open: function (item) {
			console.log("item", item);
			return {
				type: "mention",
				mutability: "IMMUTABLE",
				data: {
					mention: {
						name: item.name,
						link: item.link,
					},
				},
			};
		},
	},
};

export const markdownToEditorState = (markdown: string): EditorState => {
	// const rawData = markdownToDraft(markdown, markdownToDraftOptions);
	const rawData = markdownToDraft(markdown);
	const contentState = convertFromRaw(rawData);
	const newEditorState = EditorState.createWithContent(contentState);
	return newEditorState;
};

export const getSavedDraft = (id: string | number): EditorState => {
	const draft = getLocalstorage(`draft-${id}`);
	if (!draft) return EditorState.createEmpty();
	const contentState = convertFromRaw(draft);
	const newEditorState = EditorState.createWithContent(contentState);
	return newEditorState;
};
export const setSavedDraft = (id: string | number, editor: any): EditorState => {
	if (editor) {
		const hasText = editor?.getEditorState().getCurrentContent().hasText();
		const draft = getLocalstorage(`draft-${id}`);

		const currentContent = editor?.getEditorState()?.getCurrentContent();

		if (hasText) {
			setLocalStorage(`draft-${id}`, convertToRaw(currentContent));
		} else if (draft) {
			removeLocalStorage(`draft-${id}`);
		}
	}
};

export const blockRendererFn = (contentBlock) => {
	const type = contentBlock.getType();
	if (type === "code-block") {
		return {
			component: CodeBlock,
			editable: false,
		};
	}
	// if (type === "atomic") {
	// 	return {
	// 		component: MediaComponent,
	// 		editable: false,
	// 		props: {
	// 			foo: "bar",
	// 		},
	// 	};
	// }
};
