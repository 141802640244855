import React from "react";
import Colors from "../Colors";

const NotificationOffIcon = ({ color = Colors.textColor }) => (
	<svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M5.32733 4.52258C5.73599 4.15107 6.36844 4.18119 6.73995 4.58985L26.7399 26.5898C27.1115 26.9985 27.0813 27.631 26.6727 28.0025C26.264 28.374 25.6316 28.3439 25.2601 27.9352L5.26007 5.93519C4.88856 5.52653 4.91868 4.89409 5.32733 4.52258Z"
			fill={color}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12 23.2625C12.5523 23.2625 13 23.7102 13 24.2625V25.2625C13 26.0582 13.3161 26.8212 13.8787 27.3838C14.4413 27.9464 15.2044 28.2625 16 28.2625C16.7956 28.2625 17.5587 27.9464 18.1213 27.3838C18.6839 26.8212 19 26.0582 19 25.2625V24.2625C19 23.7102 19.4477 23.2625 20 23.2625C20.5523 23.2625 21 23.7102 21 24.2625V25.2625C21 26.5886 20.4732 27.8604 19.5355 28.798C18.5979 29.7357 17.3261 30.2625 16 30.2625C14.6739 30.2625 13.4021 29.7357 12.4645 28.798C11.5268 27.8604 11 26.5886 11 25.2625V24.2625C11 23.7102 11.4477 23.2625 12 23.2625Z"
			fill={color}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M9.17813 7.3129C9.63354 7.62535 9.74943 8.24782 9.43698 8.70322C8.51632 10.0451 8.02398 11.6346 8.0249 13.2619L8.0249 14.2625C8.0249 18.8781 7.05712 21.6579 6.12842 23.2625H23.2749C23.8272 23.2625 24.2749 23.7102 24.2749 24.2625C24.2749 24.8148 23.8272 25.2625 23.2749 25.2625H6.1249L6.12137 25.2625C5.7709 25.2612 5.4269 25.1679 5.12384 24.9919C4.82078 24.8159 4.56931 24.5633 4.39461 24.2594C4.2199 23.9556 4.12811 23.6112 4.12842 23.2607C4.12873 22.9102 4.22113 22.566 4.39637 22.2625L4.39697 22.2614C5.11809 21.0159 6.0249 18.5958 6.0249 14.2625V13.2627C6.0249 13.2626 6.0249 13.2628 6.0249 13.2627C6.02387 11.2312 6.63852 9.24689 7.78781 7.57175C8.10026 7.11634 8.72273 7.00045 9.17813 7.3129Z"
			fill={color}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M16.0522 5.26245C14.6526 5.24779 13.2745 5.60763 12.0607 6.30469C11.5817 6.57972 10.9705 6.41444 10.6955 5.93551C10.4205 5.45658 10.5857 4.84537 11.0647 4.57033C12.5873 3.69592 14.316 3.24442 16.0717 3.26255C21.5883 3.30514 25.9752 7.88182 25.9752 13.375V14.2625C25.9752 17.4941 26.4832 19.6559 27.0317 21.0615C27.2325 21.576 26.9782 22.1558 26.4637 22.3566C25.9492 22.5574 25.3694 22.303 25.1686 21.7886C24.5171 20.1191 23.9752 17.7059 23.9752 14.2625V13.375C23.9752 8.94368 20.4378 5.29568 16.0551 5.26248L16.0522 5.26245Z"
			fill={color}
		/>
	</svg>
);

export default NotificationOffIcon;
