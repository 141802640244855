import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Tooltip, useEventListener } from "@shopify/polaris";
import {
	EditorState,
	convertToRaw,
	Modifier,
	getDefaultKeyBinding,
	KeyBindingUtil,
	ContentState,
	ContentBlock,
	genKey,
	SelectionState,
} from "draft-js";

import Editor from "@draft-js-plugins/editor";
import createMentionPlugin, { defaultSuggestionsFilter } from "@draft-js-plugins/mention";
import createLinkifyPlugin from "@draft-js-plugins/linkify";
import createToolbarPlugin from "@draft-js-plugins/static-toolbar";
import { draftToMarkdown } from "markdown-draft-js";
import {
	ItalicButton,
	BoldButton,
	UnorderedListButton,
	OrderedListButton,
	// BlockquoteButton,
	CodeBlockButton,
} from "@draft-js-plugins/buttons";

import "@draft-js-plugins/static-toolbar/lib/plugin.css";
import "@draft-js-plugins/emoji/lib/plugin.css";
import "@draft-js-plugins/mention/lib/plugin.css";

import { EditorWrapper, Seperator } from "./styledComponents";
import editorStyles from "./editor.module.scss";
import buttonStyles from "./buttonStyles.module.scss";
import toolbarStyles from "./toolbarStyles.module.scss";
import mentionsStyles from "./mention/mentionsStyles.module.scss";

import API from "API";
import { store } from "store";
import { toastr } from "components/toastr";
import { Entry } from "./mention/Entry";
import Footer from "./Footer";
import EmotePicker from "./emoji/EmojiPicker";
import { getSavedDraft, markdownToEditorState, setSavedDraft } from "./Utilities";

// eslint-disable-next-line no-unused-vars
const { hasCommandModifier, isSoftNewlineEvent } = KeyBindingUtil;

const linkifyPlugin = createLinkifyPlugin({ theme: { link: editorStyles.link } });
const toolbarPlugin = createToolbarPlugin({ theme: { buttonStyles, toolbarStyles } });
const { Toolbar } = toolbarPlugin;

// const config = {
// 	blockTypesMapping: {
// 		/* mappings */
// 	},
// 	emptyLineBeforeBlock: true,
// };
// const draftToMarkdownOptions = {
// 	entityItems: {
// 		mention: {
// 			open: function (entity) {
// 				console.log(entity);
// 				return `@[`;
// 			},
// 			close: function (entity) {
// 				return `](${entity.data.mention.link})`;
// 			},
// 		},
// 	},
// };

const MessageEditor = ({ chat, onSave, message, setUserWriting, onTypingCancel, onTypingCancelDelayed, onClose }) => {
	const ref = useRef(null);
	const [editorState, setEditorState] = useState(EditorState.createEmpty());
	const [files, setFiles] = useState([]);
	const [open, setOpen] = useState(false);
	const [focused, setFocused] = useState(false);
	const [selection, setSelection] = useState();

	const members = useMemo(
		() =>
			(chat?.type === "private" ? chat?.members?.map(({ user }) => user) : store.getState().users)?.map((user) => {
				return {
					user: store.getState().users?.find((u) => String(u.id) === String(user?.id)) || user,
					name: user?.name,
					link: user?.id,
					id: user?.id,
					avatar: user?.avatar,
				};
			}),
		[chat?.type, chat?.members]
	);
	const [suggestions, setSuggestions] = useState(members);

	const { MentionSuggestions, plugins } = useMemo(() => {
		const mentionPlugin = createMentionPlugin({
			entityMutability: "IMMUTABLE",
			theme: mentionsStyles,
			mentionPrefix: "@",
			supportWhitespace: true,
		});
		// eslint-disable-next-line no-shadow
		const { MentionSuggestions } = mentionPlugin;
		// eslint-disable-next-line no-shadow
		// const plugins = [mentionPlugin, linkifyPlugin, toolbarPlugin, createMarkdownShortcutsPlugin()];
		const plugins = [mentionPlugin, linkifyPlugin, toolbarPlugin];
		return { plugins, MentionSuggestions };
	}, []);

	useEventListener("beforeunload", () => {
		setSavedDraft(chat?.id, ref.current);
	});

	const onOpenChange = useCallback((_open) => {
		setOpen(_open);
	}, []);
	const onSearchChange = useCallback(
		({ value }) => {
			setSuggestions(defaultSuggestionsFilter(value, members));
		},
		[members]
	);

	const placeHolder = `Skriv ${
		chat?.group
			? `i #${chat?.title}`
			: `till ${chat?.members?.find((mem) => String(mem?.user?.id) !== String(store.getState().user.id))?.user?.name || "..."}`
	}`;

	const addFile = useCallback((file) => {
		const ref = Date.now();
		let fileObj = {
			name: file.name,
			filename: file.name,
			size: file.size,
			type: file.type,
			loading: true,
			ref,
		};

		var reader = new FileReader();
		reader.onload = (event) => {
			fileObj.data = event.target.result;

			setFiles((files) => {
				files.push(fileObj);
				return [...files];
			});
		};
		reader.readAsDataURL(file);

		if (ref.current) {
			ref.current?.focus();
			// PolarisTextField.setSelectionRange(this.state.comment?.length, this.state.comment?.length);
		}

		fileObj.upload = new Promise(function (resolve, reject) {
			let formData = new FormData();
			formData.append("file", file);

			API.post("/uploads/multi.json", formData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			})
				.then((result) => {
					if (result.data.error) {
						console.log("error:", result.data.error);
						toastr.error(result.data.error);
						return;
					}

					fileObj = { ...result.data.upload, name: result.data.upload?.filename || result.data.upload?.name, data: fileObj.data, ref };

					resolve(fileObj);
				})
				.catch((error) => {
					console.log("error:", error);
					toastr.error(error);
				});
		});

		Promise.resolve(fileObj.upload).then((result) => {
			setFiles((files) => {
				const index = files.findIndex((file) => String(file?.ref) === String(fileObj?.ref));

				if (index >= 0) {
					files.splice(index, 1, result);
					return [...files];
				}

				return files;
			});
		});
	}, []);

	const updateFile = (index, file) => {
		// files[index] = file;
		files.splice(index, 1, file);
		setFiles([...files]);
	};

	const removeFile = (index, file) => {
		files.splice(index, 1);
		setFiles([...files]);
	};

	const handleSubmit = useCallback(() => {
		// const markdownTest = stateToMarkdown(editorState.getCurrentContent());

		const rawContentState = convertToRaw(editorState.getCurrentContent());
		const rawMarkdown = draftToMarkdown(rawContentState);
		// const rawMarkdown = draftToMarkdown(rawContentState, draftToMarkdownOptions);
		// const mentions = Object.values(rawContentState.entityMap)
		// 	.filter((entity) => entity.type === "mention")
		// 	.map((entity) => entity.data.mention?.user);

		const urlRegex =
			/(?<!`)(?<!```)(?<!\S)(https?:\/\/)(?:(?!(?<!`)(?<!```)[^`\n])+?)([\da-z.-]+\.[a-z.]{2,6})([/\w.-]*)*\/?(\?[\w-]+(=[\w-]*)?(&[\w-]+(=[\w-]*)?)*)?(#[\w-]+)?(?![^<>]*>|[^"]*?(?<=["'])[^"']*?(?=["']))/gi;

		const markdown = rawMarkdown.replace(urlRegex, (match) => `[${match}](${match.startsWith("http") ? match : "http://" + match})`);

		if (!editorState.getCurrentContent().hasText() && !files?.length) return;

		if (onSave) {
			setUserWriting.cancel();
			onTypingCancel(chat?.id);

			onSave({
				id: message?.id,
				chat_id: chat?.id,
				files: files,
				comment: markdown,
				content: markdown,
				user: store.getState().user,
			});

			setEditorState(EditorState.createEmpty());
			setFiles([]);
			setSelection(null);

			return;
		}
	}, [editorState, chat?.id, files, message?.id, onSave, onTypingCancel, setUserWriting]);
	// }, [chat?.id, comment, files, message?.id, onSave, onTypingCancel, setUserWriting]);

	const handleEditorFocus = useCallback((e) => {
		setFocused(true);

		if (ref.current) {
			const editor = ref.current.getEditorRef();
			editor.focus?.();
		}

		setSelection(ref.current.getEditorState().getSelection());
		e.preventDefault();
		e.stopPropagation();
	}, []);

	const handleFocus = useCallback((e) => {
		if (ref.current) {
			const editor = ref.current.getEditorRef();
			editor.focus?.();

			setEditorState((editorState) => {
				const newEditorState = EditorState.moveFocusToEnd(editorState);
				return newEditorState;
			});
		}
		setSelection(ref.current.getEditorState().getSelection());
	}, []);

	const handleBlur = useCallback(
		(e) => {
			// if (ref.current?.focus) {
			// 	ref.current?.blur();
			// }
			setSavedDraft(chat?.id, ref.current);
			setFocused(false);
			onTypingCancelDelayed(chat?.id);
		},
		[chat?.id, onTypingCancelDelayed]
	);

	const handleKeyCommand = (command) => {
		if (command === "submit") {
			handleSubmit();
			return "handled";
		}

		return "not-handled";
	};

	const keyBindingFn = (e) => {
		if (e.keyCode === 13 && !e.shiftKey && !isSoftNewlineEvent(e)) {
			return "submit";
		}
		return getDefaultKeyBinding(e);
	};

	const insertText = (text) => {
		const contentState = editorState.getCurrentContent();
		const newContentState = Modifier.insertText(contentState, selection, text);
		const newEditorState = EditorState.push(editorState, newContentState, "insert-characters");
		// ref.current.onChange(newEditorState);
		setEditorState(newEditorState);
		setSelection(newEditorState.getSelection());
		// handleSetEditorState(newEditorState);
		// ref.current?.focus();
	};

	const handleSetEditorState = (editorState) => {
		if (chat?.id && !store.getState().users_typing?.[chat.id]?.includes(store.getState().user.id) && editorState.getCurrentContent().hasText()) {
			setUserWriting();
			onTypingCancelDelayed();
		} else if (
			chat?.id &&
			!editorState.getCurrentContent().hasText() &&
			!files?.lenth &&
			store.getState().users_typing?.[chat.id]?.includes(store.getState().user.id)
		) {
			setUserWriting.cancel();
			onTypingCancel(chat?.id);
		}

		if (focused) {
			const newState = editorState.getCurrentContent().getPlainText("\u0001").length === 1 ? EditorState.moveFocusToEnd(editorState) : editorState;
			setEditorState(newState);
			// setSelection(editorState.getSelection());
		}
	};

	const handlePastedText = (text, html, editorState, { getEditorState, setEditorState }) => {
		// Get the current selection and content state
		const currentSelection = editorState.getSelection();
		const currentContent = editorState.getCurrentContent();

		// Check if the current block is a code block
		const currentBlock = currentContent.getBlockForKey(currentSelection.getStartKey());
		if (currentBlock.getType() !== "code-block") {
			return "not-handled";
		}

		// Get the text content of the current block
		const blockText = currentBlock.getText();

		// Get the text content of the pasted text
		// Replace any tabs with four spaces
		// const pastedText = text.trim().replace(/\t/g, "    ");
		// const pastedText = text.replace(/\t/g, "  ");
		const pastedText = text;

		// Create a new content state with the pasted text wrapped in a code block
		const newContentState = Modifier.replaceText(
			currentContent,
			currentSelection.merge({
				anchorOffset: 0,
				focusOffset: blockText.length,
			}),
			pastedText
			// "\n" + pastedText + "\n"
			// "```\n" + pastedText + "\n```"
		);

		// Update the editor state with the new content state
		setEditorState(EditorState.push(editorState, newContentState, "insert-characters"));

		return "handled";
	};

	const handleReturn = (event, editorState, { getEditorState, setEditorState }) => {
		// Get the current selection and content state
		const currentSelection = editorState.getSelection();
		const currentContent = editorState.getCurrentContent();

		// Check if the current block is a code block
		const currentBlock = currentContent.getBlockForKey(currentSelection.getStartKey());
		if (currentBlock.getType() !== "code-block") {
			return "not-handled";
		}

		// Get the text content of the current block
		const blockText = currentBlock.getText();

		// Check if the user has pressed the Enter key three times in a row
		const texts = blockText.split("\n");
		const newLineCount = texts.filter((i) => !i).length;
		if (newLineCount >= 3 || texts.length === 1) {
			// Create a new block with the default block type and the desired text
			const text = "";
			const newBlock = new ContentBlock({
				key: genKey(),
				type: "unstyled",
				depth: 0,
				text,
			});

			// Create a new content state with the new block array and the updated selection
			const blockArray = currentContent.getBlockMap().toIndexedSeq().toArray();
			const insertionIndex = blockArray.findIndex((block) => block.getKey() === currentBlock.getKey()) + 1;

			const newBlockArray = [...blockArray.slice(0, insertionIndex), newBlock, ...blockArray.slice(insertionIndex)];
			const newContentState = ContentState.createFromBlockArray(newBlockArray);

			// Update the editor state with the new content state and set the selection to the beginning of the new line
			const newEditorState = EditorState.push(editorState, newContentState, "split-block");
			const newBlockKey = newBlock.getKey();
			const newBlockSelection = SelectionState.createEmpty(newBlockKey).set("focusOffset", 0);
			const finalEditorState = EditorState.forceSelection(newEditorState, newBlockSelection);
			setEditorState(finalEditorState);
			return "handled";
		}

		return "not-handled";
	};

	useEffect(() => {
		const onPaste = (e) => {
			if (!e.clipboardData) {
				return;
			}
			var items = e.clipboardData.items;

			// eslint-disable-next-line eqeqeq
			if (items == undefined) {
				return;
			}

			for (var i = 0; i < items.length; i++) {
				// Skip content if not image
				// eslint-disable-next-line eqeqeq
				if (items[i].type.indexOf("image") == -1) continue;
				// Retrieve image on clipboard as blob
				var blob = items[i].getAsFile();

				if (blob) {
					addFile(blob);
					e.preventDefault();
					e.stopPropagation();
					/*
				var reader = new FileReader();
				reader.onload = this.addFile.bind(this, blob);
				reader.readAsDataURL(blob);
				*/
				}
			}
		};

		const editor = ref.current?.editor?.editor;

		if (editor) {
			editor.addEventListener("paste", onPaste);
		}

		return () => {
			editor?.removeEventListener("paste", onPaste);
		};
	}, [addFile]);

	useEffect(() => {
		setSuggestions(members);
	}, [members]);

	useEffect(() => {
		if (message?.id) {
			setEditorState(markdownToEditorState(message.content));
		} else {
			setEditorState(getSavedDraft(chat?.id));
		}

		return () => {
			// console.log("saving draft");
			// setSavedDraft(chat?.id, editor);
		};
	}, [chat?.id, message?.id, message?.content]);

	return (
		<>
			<EditorWrapper
				onBlur={handleBlur}
				data-focused={focused}
				onClick={(e) => {
					// setTimeout(() => {
					// 	if (focused) return true;
					// 	handleFocus();
					// }, 0);
					if (focused) return true;
					handleFocus();
				}}
			>
				<Toolbar>
					{(externalProps) => {
						const sharedProps = { preferredPosition: "above", hoverDelay: 500 };

						return (
							<>
								<Tooltip content="Bold" {...sharedProps}>
									<BoldButton {...externalProps} />
								</Tooltip>
								<Tooltip content="Italic" {...sharedProps}>
									<ItalicButton {...externalProps} />
								</Tooltip>
								<Seperator />
								<Tooltip content="Unordered list" {...sharedProps}>
									<UnorderedListButton {...externalProps} />
								</Tooltip>
								<Tooltip content="Ordered list" {...sharedProps}>
									<OrderedListButton {...externalProps} />
								</Tooltip>
								<Seperator />
								<Tooltip content="Code block" {...sharedProps}>
									<CodeBlockButton {...externalProps} />
								</Tooltip>
								<Seperator />
								<EmotePicker onClose={handleFocus} onChange={(emote) => insertText(emote)} onOpen={handleFocus} />
							</>
						);
					}}
				</Toolbar>

				<div className={editorStyles.editor}>
					<Editor
						editorKey={"editor"}
						editorState={editorState}
						onChange={handleSetEditorState}
						plugins={plugins}
						placeholder={placeHolder}
						ref={ref}
						handleKeyCommand={handleKeyCommand}
						keyBindingFn={keyBindingFn}
						onBlur={handleBlur}
						onFocus={handleEditorFocus}
						handlePastedText={handlePastedText}
						handleReturn={handleReturn}
						// readOnly={!focused}
					/>
				</div>
				<MentionSuggestions
					open={open}
					onOpenChange={onOpenChange}
					suggestions={suggestions}
					onSearchChange={onSearchChange}
					onAddMention={(mention) => {}}
					entryComponent={Entry}
				/>

				<Footer
					files={files}
					handleSubmit={handleSubmit}
					onChange={insertText}
					onEmoteOpen={handleFocus}
					addFile={addFile}
					updateFile={updateFile}
					removeFile={removeFile}
					disabled={!editorState.getCurrentContent().hasText() && !files?.length}
					onClose={onClose}
				/>
			</EditorWrapper>
		</>
	);
};

export default MessageEditor;
