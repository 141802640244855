import { ActionList, Button, Card, Icon, Modal, Tabs, TextField } from "@shopify/polaris";
import React, { useEffect, useMemo, useState } from "react";
import { toastr } from "components/toastr";
import { store } from "store/index";
import API from "API";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { setLocalStorage } from "util/index";
import Members from "./Members";
import styled from "styled-components";
import { EditMajor } from "@shopify/polaris-icons";
import ProfileAvatar from "components/ProfileAvatar";
import { useSelector } from "react-redux";
import Popover from "components/Popover";
import NotificationOnIcon from "icons/NotificationOnIcon";
import NotificationOffIcon from "icons/NotificationOffIcon";

const ChatSettings = React.forwardRef(({ chat_id, open, onClose, tabId }, ref) => {
	const chat = useSelector((state) => state.chats.find((ch) => String(ch.id) === String(chat_id)));
	const [title, setTitle] = useState(chat?.title);
	const [openChangeTitle, setOpenChangeTitle] = useState();
	const [leaveOpen, setLeaveOpen] = useState();
	const [notisOpen, setNotisOpen] = useState();
	const navigate = useNavigate();
	const [tab, setTab] = useState("general");

	const onChangeTab = (index) => {
		setTab(tabs[index]?.id);
	};
	const tabs = [
		{
			id: "general",
			content: "Allmänt",
			accessibilityLabel: "general",
			panelID: "general",
		},
		{
			id: "members",
			content: "Medlemmar",
			accessibilityLabel: "members",
			panelID: "members",
		},
	];

	useEffect(() => {
		if (open) {
			setTitle(chat?.title);
			setTab(tabId || "general");
		} else {
			setTitle("");
			setOpenChangeTitle(false);
			setLeaveOpen(false);
			setTab("general");
		}
	}, [open, chat?.title, tabId]);

	const openLeaveChat = () => {
		setLeaveOpen(true);
	};
	const closeLeaveChat = () => {
		setLeaveOpen(false);
	};

	const leaveChat = () => {
		store.dispatch({ type: "REMOVE_CHAT", chat });
		onClose();
		navigate("/chat");
		setLocalStorage("lastChatId", null);

		API.post("/chats/" + chat.id + "/leave.json", {})
			.then((result) => {
				store.dispatch({ type: "REMOVE_CHAT", chat });
				onClose();
				navigate("/chat");
				setLocalStorage("lastChatId", null);
			})
			.catch((error) => {
				toastr.error(error);
			});
	};

	const onToggleChangeTitle = () => {
		setOpenChangeTitle((c) => !c);
	};

	const onCloseChangeTitle = () => {
		setOpenChangeTitle(false);
		setTitle(chat.title);
	};

	const onSave = () => {
		const newChat = { ...chat, title };
		store.dispatch({ type: "UPDATE_CHAT", chat: newChat });
		setOpenChangeTitle(false);

		API.put(`/chats/${chat.id}.json`, newChat)
			.then((result) => {
				setTitle(result.data.chat?.title);
				store.dispatch({ type: "UPDATE_CHAT", chat: result.data.chat });
			})
			.catch((error) => {
				toastr.error(error);
			});
	};

	const mute = () => {
		chat.me_as_member.mute = false;
		store.dispatch({
			type: "UPDATE_MEMBER",
			member: chat.me_as_member,
		});

		API.post("/chats/" + chat.id + "/mute.json", {})
			.then((result) => {
				// store.dispatch({ type: "UPDATE_CHAT", chat: result.data.chat });
			})
			.catch((error) => {
				toastr.error(error);
			});
	};

	const unmute = () => {
		chat.me_as_member.mute = false;
		store.dispatch({
			type: "UPDATE_MEMBER",
			member: chat.me_as_member,
		});

		API.post("/chats/" + chat.id + "/unmute.json", {})
			.then((result) => {
				// store.dispatch({ type: "UPDATE_CHAT", chat: result.data.chat });
			})
			.catch((error) => {
				toastr.error(error);
			});
	};

	const creatorMember = useMemo(() => {
		return chat?.members?.find((mem) => mem?.creator);
	}, [chat]);

	const otherMember = useMemo(() => {
		return !chat?.group && chat.members?.find((mem) => String(mem.user?.id) !== String(store.getState().user.id));
	}, [chat]);

	const content = (() => {
		const admin = chat.me_as_member?.admin || chat.me_as_member?.creator;
		switch (tab) {
			default:
			case "general":
				return (
					<>
						{chat?.group && (
							<SettingsSection title="Kanal namn" onClick={admin && onToggleChangeTitle}>
								{chat?.title}
							</SettingsSection>
						)}

						{!chat?.group && (
							<SettingsSection title={""} style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
								<ProfileAvatar size={50} user={(otherMember || chat.me_as_member)?.user} />
								<div>
									<h3>{(otherMember || chat.me_as_member)?.user?.name}</h3>
									<p>{(otherMember || chat.me_as_member)?.user?.email}</p>
								</div>
							</SettingsSection>
						)}

						<>
							<Modal
								title="Ändra kanal namn"
								open={openChangeTitle}
								onClose={onCloseChangeTitle}
								primaryAction={{
									content: "Spara",
									onAction: onSave,
								}}
								secondaryActions={[
									{
										content: "Avbryt",
										onAction: onCloseChangeTitle,
									},
								]}
							>
								<Modal.Section>
									<TextField
										label="Kanal namn"
										value={title}
										onChange={(v) => {
											setTitle(v);
										}}
										autoFocus
										placeholder="Kanal namn.."
									/>
								</Modal.Section>
							</Modal>
						</>

						<Modal
							open={leaveOpen}
							onClose={closeLeaveChat}
							title="Lämna kanal?"
							secondaryActions={[
								{ content: "Avbryt", onAction: closeLeaveChat },
								{ content: "Lämna chat", destructive: true, onAction: leaveChat },
							]}
						>
							<Modal.Section>
								<p>Lämna {chat?.title}? </p>
							</Modal.Section>
						</Modal>
					</>
				);
			case "members":
				return (
					<SettingsSection title="Medlemmar">
						<Members />
					</SettingsSection>
				);
		}
	})();

	return (
		<>
			<Modal
				open={open}
				onClose={(e) => {
					e.preventDefault();
					e.stopPropagation();
					onClose(e);
				}}
				title={
					<>
						Inställningar
						<StyledHeader>
							<Popover
								active={notisOpen}
								onClose={() => {
									setNotisOpen(false);
								}}
								activator={
									<Button
										icon={chat?.me_as_member?.mute ? NotificationOffIcon : NotificationOnIcon}
										onClick={() => {
											setNotisOpen((c) => !c);
										}}
										ariaExpanded={notisOpen}
										connectedDisclosure={{
											actions: [
												{ active: !chat?.me_as_member?.mute, content: "Slå på alla notiser", onAction: unmute, prefix: <NotificationOnIcon /> },
												{ active: chat?.me_as_member?.mute, content: "Stäng av alla notiser", onAction: mute, prefix: <NotificationOffIcon /> },
											],
										}}
										disclosure={notisOpen ? "up" : "down"}
									>
										{`Notiser är ${chat?.me_as_member?.mute ? "inaktiverade" : "aktiverade"}`}
									</Button>
								}
							>
								<ActionList
									onActionAnyItem={() => {
										setNotisOpen(false);
									}}
									items={[
										{ active: !chat?.me_as_member?.mute, content: "Slå på alla notiser", onAction: unmute, prefix: <NotificationOnIcon /> },
										{ active: chat?.me_as_member?.mute, content: "Stäng av alla notiser", onAction: mute, prefix: <NotificationOffIcon /> },
									]}
								/>
							</Popover>
						</StyledHeader>
						<Tabs tabs={tabs} onSelect={onChangeTab} selected={tabs.findIndex((t) => t.id === tab)} />
					</>
				}
				secondaryActions={[{ content: "Lämna chat", destructive: true, onAction: openLeaveChat }]}
				footer={
					<p style={{ color: "var(--textColor2)", fontSize: 10 }}>
						Skapad: {moment(chat.created_at).format("YYYY-MM-DD HH:mm")} {creatorMember?.user?.name ? `av ${creatorMember?.user?.name}` : ""}
					</p>
				}
				large
				fullScreen
			>
				<Modal.Section>
					<StyledContentWrapper>{content}</StyledContentWrapper>
				</Modal.Section>
			</Modal>
		</>
	);
});

export default ChatSettings;

const StyledHeader = styled.div`
	display: flex;
	align-items: center;
	margin-right: 50px;
	padding: 1rem 0;
	gap: 1rem;
`;
const StyledContentWrapper = styled.div`
	padding-top: 1rem;

	.Polaris-Card {
		background-color: var(--tertiary);
	}
`;

const StyledSection = styled.div`
	cursor: ${({ onClick }) => (onClick ? "pointer" : "initial")};
	position: relative;
	margin: 1rem 0;
	font-size: 0.9rem;

	.Polaris-Card {
		background-color: var(--tertiary);
	}

	h1 {
		font-size: 2em;
	}
	h2 {
		font-size: 1.35em;
	}
	h3 {
		font-size: 1.15em;
	}
`;
const StyledSectionEditBtn = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	padding: 0.5rem;
	opacity: 0.75;
	transition: opacity 250ms, scale 250ms;

	.Polaris-Icon {
		height: 14px;
		width: 14px;

		svg {
			fill: #ffffff;
		}
	}

	&:hover {
		opacity: 1;
		scale: 1.05;
	}
`;

export const SettingsSection = ({ title, onClick, secondaryFooterActions, primaryFooterAction, actions, style, children }) => {
	return (
		<StyledSection onClick={onClick}>
			<Card title={title} sectioned actions={actions} secondaryFooterActions={secondaryFooterActions} primaryFooterAction={primaryFooterAction}>
				{onClick && (
					<StyledSectionEditBtn>
						<Icon source={EditMajor} />
					</StyledSectionEditBtn>
				)}
				<div style={style}>{children}</div>
			</Card>
		</StyledSection>
	);
};
