import React, { useState } from "react";
import { Button, TextField, Tooltip, Modal } from "@shopify/polaris";
import { CancelMajor, EditMajor, SaveMinor } from "@shopify/polaris-icons";
import styled from "styled-components";
import { isImage } from "util/index";
import Colors from "../Colors";
import { store } from "store/index";
import moment from "moment";

const SmallAttachment = ({ file: originalFile, onRemove, onChange }) => {
	const [open, setOpen] = useState();
	const [editOpen, setEditOpen] = useState();
	const [file, setFile] = useState(originalFile);

	const onEditOpen = (e) => {
		e?.stopPropagation?.();
		setEditOpen(true);
		setOpen(false);
	};
	const onEditClose = (e) => {
		e?.stopPropagation?.();
		setEditOpen(false);
		setFile(originalFile);
	};

	const onOpen = (e) => {
		if (editOpen) return;
		e?.stopPropagation?.();
		setOpen(true);
	};
	const onClose = (e) => {
		e?.stopPropagation?.();
		setOpen(false);
	};

	const openImageDataInNewTab = () => {
		if (file.url) {
			const imageWindow = window.open(file.url);
			imageWindow.document.close();
			return;
		}
		const image = new Image();
		image.src = file.data;

		const imageWindow = window.open("file", file.data);
		imageWindow.document.write(image.outerHTML);

		setTimeout(function () {
			imageWindow.document.title = file.name;
		}, 0);

		setTimeout(function () {
			imageWindow.document.title = file.name;
		}, 100);

		imageWindow.document.close();
	};

	const fileIconName = (() => {
		switch (file.type) {
			case "application/vnd.ms-excel":
				return "XLS.png";
			case "application/x-zip-compressed":
				return "ZIP.png";
			case "text/plain":
				return "TXT.png";
			case "application/msword":
				return "DOC.png";
			case "application/pdf":
				return "PDF.png";
			// case "application/psd":
			// 	return "PSD.png";
			// case "application/exe":
			// 	return "EXE.png";
			case "audio/mpeg":
				return "MP3.png";
			case "video/avi":
				return "AVI.png";
			case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
			case "application/vnd.ms-powerpoint":
				return "PPT.png";
			case "image/gif":
				return "GIF.png";
			case "image/jpeg":
			case "image/png":
				return "JPG.png";
			// case "image/png":
			// 	return "PNG.png";
			default:
				return "File.png";
		}
	})();
	const chat = store.getState().chats.find((c) => String(c.id) === String(file.chat_id));

	const url = `${file.url?.replace("/admin/", "/api/")}`;
	// const url = `${file.url?.replace("/admin/", "/api/")}?token=${store.getState().user.token}&acc_id=${store.getState()?.account?.id}`;

	return (
		<Wrapper
			onClick={(e) => {
				if (isImage(file.path || file.url || file.name)) {
					onOpen();
					e.preventDefault();
				}
				e.stopPropagation();
			}}
			href={url || file.url}
			download={file.filename || file.name}
			target="_blank"
			rel="noreferrer"
		>
			<StyledFile>
				<img alt="icon" src={`/icons/${fileIconName}`} />
				<StyledTextWrapper>
					<StyledFileName>{file.filename || file.name}</StyledFileName>
					{/* <StyledFileType>{file.type}</StyledFileType> */}{" "}
					<div style={{ color: Colors.subdued }}>
						<span>{chat?.group ? `# ${chat?.title}` : chat?.title} - </span>
						<span>{moment(file.created_at).locale("sv").format("YYYY-MM-DD")}</span>
					</div>
				</StyledTextWrapper>
			</StyledFile>

			<StyledActionMenu size={file.id ? "large" : "small"} onClick={(e) => e.stopPropagation()}>
				{file.id && (
					<Tooltip content="Ladda ner">
						<Button download url={url} icon={SaveMinor} />
					</Tooltip>
				)}
				<Modal
					activator={
						onChange && (
							<Tooltip content="Redigera fil">
								<Button onClick={onEditOpen} icon={EditMajor} />
							</Tooltip>
						)
					}
					open={editOpen}
					onClose={onEditClose}
					title={`Redigera fil "${originalFile?.name || originalFile?.filename || ""}"`}
					primaryAction={{
						content: "Spara",
						onAction: (e) => {
							onChange?.(file, false);
							setEditOpen(false);
						},
					}}
					secondaryActions={[
						{
							content: "Ta bort",
							onAction: onRemove,
							destructive: true,
						},
						{
							content: "Avbryt",
							onAction: onEditClose,
						},
					]}
				>
					<Modal.Section>
						<TextField
							label="Fil namn"
							placeholder={"Skriv in namn på filen..."}
							value={file.name || file.filename}
							onChange={(value) => {
								setFile((c) => ({
									...c,
									name: value,
									filename: value,
								}));
							}}
						/>
					</Modal.Section>
				</Modal>
				<Modal
					large
					fullScreen={true}
					noScroll={true}
					open={open}
					onClose={onClose}
					title={
						<div>
							<span style={{ marginRight: "1rem" }}> {`${file.name || originalFile?.filename || ""}`}</span>
							{onChange && (
								<Tooltip content="Redigera fil">
									<Button plain icon={EditMajor} onClick={onEditOpen} />
								</Tooltip>
							)}
							<StyledSubText>
								{file.size > 1048576 ? (
									<span style={{ whiteSpace: "nowrap", fontSize: "0.9em" }}>{(file.size / 1048576).toFixed(1) || "-"} MB</span>
								) : (
									<span style={{ whiteSpace: "nowrap", fontSize: "0.9em" }}>{Math.ceil(file.size / 1024) || "-"} kB</span>
								)}
							</StyledSubText>
							<StyledSubText>{file.type}</StyledSubText>
						</div>
					}
					limitHeight={false}
				>
					<Modal.Section>
						<StyledFullscreenImage onClick={openImageDataInNewTab} src={url || file.data || file.url} />
					</Modal.Section>
				</Modal>
				{onRemove && (
					<Tooltip content="Ta bort fil">
						<Button icon={CancelMajor} onClick={onRemove} />
					</Tooltip>
				)}
			</StyledActionMenu>
		</Wrapper>
	);
};

const StyledActionMenu = styled.div`
	display: flex;
	flex-direction: row;
	gap: 0.5rem;
	z-index: 2;
	opacity: 0;
	transition: opacity 250ms;

	button,
	a {
		padding: 0.5rem;
		border-radius: 50%;
		min-height: unset;
		min-width: unset;
		border: none;

		&:not(.Polaris-Button--destructive) {
			background: var(--secondary);
		}

		.Polaris-Icon {
			width: ${({ size }) => (size === "large" ? 20 : 12)}px;
			height: ${({ size }) => (size === "large" ? 20 : 12)}px;
		}
	}
`;

const Wrapper = styled.a`
	position: relative;
	cursor: pointer;
	transition: filter 250ms;
	min-height: 40px;
	height: max-content;
	align-items: center;
	display: flex;

	justify-content: space-between;
	text-decoration: none;

	&:hover {
		filter: brightness(1.2);
		${StyledActionMenu} {
			opacity: 1;
		}
	}

	.Polaris-Icon {
		margin: auto 0;
		width: 1.4em;
		height: 1.4em;
	}
`;

// const StyledImage = styled.img`
// 	height: 150px;
// 	max-width: 300px;
// 	border-radius: 10px;
// 	box-shadow: 2px 2px 4px #00000061;
// 	transition: height 250ms;
// 	object-fit: contain;
// `;
const StyledFullscreenImage = styled.img`
	height: 100%;
	border-radius: 10px;
	box-shadow: 2px 2px 4px #00000061;
	cursor: pointer;
	width: 100%;
	object-fit: contain;
`;

const StyledSubText = styled.p`
	font-size: 12px;
	color: var(--textColor2);
`;

export const RemoveButton = styled.div.attrs({ className: "removeButton" })`
	position: absolute;
	right: 0;
	top: 0;
	transform: translate(40%, -50%);

	button {
		padding: 0.5rem;
		border-radius: 50%;
		min-height: unset;
		min-width: unset;
	}

	.Polaris-Icon {
		width: 12px;
		height: 12px;
	}
`;

const StyledFile = styled.div`
	border-radius: 10px;
	height: 50px;
	text-overflow: ellipsis;
	overflow: hidden;
	-webkit-line-clamp: 1;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	word-break: break-all;
	padding: 0.5rem;
	display: flex;
	align-items: center;
	border: 1px solid ${Colors.tertiary};
	gap: 0.75rem;

	&&& {
		text-decoration: none;
		color: ${Colors.textColor};
	}

	img {
		height: 80%;
	}
`;

const StyledTextWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

const StyledFileName = styled.p`
	text-decoration: none;
	text-overflow: ellipsis;
	overflow: hidden;
	-webkit-line-clamp: 1;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	word-break: break-all;
`;
// const StyledFileType = styled.p`
// 	color: rgba(255, 255, 255, 0.5);
// 	font-size: 0.9em;
// 	text-overflow: ellipsis;
// 	overflow: hidden;
// 	-webkit-line-clamp: 1;
// 	display: -webkit-box;
// 	-webkit-box-orient: vertical;
// 	word-break: break-all;
// `;

export default SmallAttachment;
