import Colors from "Colors";
import styled from "styled-components";

export const EditorWrapper = styled.div.attrs({ className: "editorWrapper" })`
	/* padding: 1rem; */
	cursor: ${({ readOnly }) => (readOnly ? "initial" : "text")};
	border-radius: 2px;
	box-sizing: border-box;
	background: ${({ readOnly }) => (readOnly ? "transparent" : Colors.primary)};
	flex: 1;

	&[data-focused="false"] {
		div[class^="toolbarStyles_toolbar"] {
			opacity: 0.5;
		}
	}

	&[data-focused="true"] {
		div[class^="toolbarStyles_toolbar"] {
			opacity: 1;
		}
	}

	.public-DraftEditorPlaceholder-root {
		position: absolute;
		opacity: 0.5;
	}

	.public-DraftEditorPlaceholder-hasFocus {
		display: none;
	}

	/* .public-DraftStyleDefault-block, */
	.public-DraftStyleDefault-pre {
		background-color: var(--secondary);
		// color: #ffffff;
		border-radius: 5px;
		border: 1px solid var(--tertiary);
	}

	.public-DraftStyleDefault-block.public-DraftStyleDefault-ltr {
		white-space: break-spaces;
	}

	pre {
		white-space: break-spaces;
	}
`;

export const Seperator = styled.span`
	background: ${Colors.tertiary};
	width: 1px;
	display: inline-block;
	height: 25px;
	margin-inline: 0.5rem;
`;
