import React from "react";
import styled from "styled-components";
import Colors from "../Colors";

const OnlineDot = ({ user }) => {
	if (user?.online) {
		return <StyledDot />;
	}
	return null;
};
export default OnlineDot;

const StyledDot = styled.div.attrs({ className: "online-dot" })`
	background-color: ${Colors.green};
	position: absolute;
	right: 0;
	bottom: 0;
	transform: translate(25%, 25%);
	width: 10px;
	height: 10px;
	border-radius: 50%;
	border: thin solid ${Colors.primary};
	z-index: 1;
`;
