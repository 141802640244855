import { Spinner } from "@shopify/polaris";
import React, { useContext } from "react";
import styled from "styled-components";
import ChatContext from "contexts/ChatContext";

const WelcomeScreen = (props) => {
	const { loading } = useContext(ChatContext) || {};

	return (
		<StyledWrapper>
			<StyledInnerWrapper>
				<StyledLogoText src="/sellfinity/sellfinity-highres-login-c.png" />
				<StyledTitle>Välkommen till Sellfinity's chat</StyledTitle>
				<StyledText></StyledText>
				{(loading || props.loading) && <Spinner />}
			</StyledInnerWrapper>
		</StyledWrapper>
	);
};
export default WelcomeScreen;

const StyledWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	grid-area: messages;
	height: 100%;
`;
const StyledInnerWrapper = styled.div`
	margin-top: -100px;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 3rem;
	position: relative;

	.Polaris-Spinner {
		position: absolute;
		bottom: 0;
	}
`;

const StyledTitle = styled.h1`
	font-size: 2.4rem;
	text-align: center;
	line-height: 2.4rem;
	margin-bottom: 1rem;
`;
const StyledText = styled.p`
	margin-bottom: 1rem;
`;

const StyledLogoText = styled.img`
	max-width: 100%;
	height: 100px;
	max-height: 100px;
	margin-bottom: 1rem;
`;
