import { Icon } from "@shopify/polaris";
import { ChevronRightMinor } from "@shopify/polaris-icons";
import Colors, { rgba } from "Colors";
import useClicksOutside from "hooks/useClicksOutside";
import React, { useCallback, useRef, useState } from "react";
import styled, { css } from "styled-components";
import Spinner from "./Spinner";
import { createPortal } from "react-dom";
import { debounce } from "lodash";
import { useEffect } from "react";

const MIN_HEIGHT = 0;
const MIN_WIDTH = 150;

const styles = css`
	background-color: ${Colors.tertiary};
	border-radius: 8px;
	z-index: 9999999;
	padding: 5px 0;

	/* & > * {
		cursor: pointer;
		transition: color 250ms;
		font-size: 0.9rem;
		padding: 8px 14px;
		color: ${Colors.textColor};
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		width: 100%;
		transition: color 100ms, background-color 100ms;


		.Polaris-Icon {
			width: unset;
			max-height: unset;
			max-width: unset;
			margin: 0;
		}

		svg {
			margin-right: 5px;
			color: inherit;
			fill: ${Colors.textColor};
			height: 20px;
			width: 20px;
			transition: fill 100ms;
		}

		&:hover {
			color: #ffffff;
			opacity: 1;
			background-color: ${Colors.primary};
			overflow: visible;
		}

		&:hover svg {
			fill: #ffffff;
			opacity: 1;
		}
	} */
`;

const StyledArrow = styled.div`
	&&& {
		position: absolute;
		right: 0;
		transform: rotate(0deg);
		transition: transform 250ms, fill 250ms;
		fill: rgb(200, 200, 200);
		top: 0;
		height: 100%;
		bottom: 0;
		display: flex;
		align-items: center;
	}
`;

export const ContextMenuDropDownList = styled.div`
	opacity: 0;
	pointer-events: none;
	position: absolute;
	right: 0;
	transform: translateX(85%);
	top: -5px;
	transition: opacity 250ms, transform 250ms;

	${styles}

	&:hover {
		opacity: 1;
		pointer-events: all;
		transform: translateX(100%);

		${StyledArrow} {
			transform: rotate(45deg);
			fill: rgb(255, 255, 255);
		}
	}
`;

const StyledDropDownTrigger = styled.div.attrs({ className: "StyledDropDownTrigger" })`
	position: relative;

	&:hover,
	&[data-open="true"] {
		${ContextMenuDropDownList} {
			opacity: 1;
			pointer-events: all;
			transform: translateX(100%);
		}
		${StyledArrow} {
			transform: rotate(45deg);
			fill: rgb(255, 255, 255);
		}
	}
`;

export const ContextMenuDropDown = ({ trigger, children }) => {
	const [open, setOpen] = useState();

	const onMouseLeave = debounce(() => {
		setOpen(false);
	}, 250);

	const onMouseLeaveNoDelay = () => {
		onMouseLeave.cancel();
		setOpen(false);
	};

	const onMouseEnter = () => {
		onMouseLeave.cancel();
		setOpen(true);
	};

	useEffect(() => {
		return onMouseLeave.cancel;
	}, [onMouseLeave.cancel]);

	return (
		<StyledDropDownTrigger data-open={open} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
			{trigger || null}
			<StyledArrow>
				<Icon source={ChevronRightMinor} />
			</StyledArrow>
			<ContextMenuDropDownList onMouseLeave={onMouseLeaveNoDelay}>{children || null}</ContextMenuDropDownList>
		</StyledDropDownTrigger>
	);
};

const Container = styled.div`
	position: fixed;
	min-height: ${MIN_HEIGHT}px;
	min-width: ${MIN_WIDTH}px;
	max-height: ${({ maxHeight }) => maxHeight + "px"};

	left: ${({ left }) => left + "px"};
	top: ${({ top }) => top + "px"};
	visibility: ${({ show }) => (show ? "visible" : "hidden")};
	pointer-events: ${({ show }) => (show ? "all" : "none")};
	/* padding: 0px 10px; */
	font-weight: bold;
	color: rgb(225, 225, 225);

	${styles}
`;

const getColors = ({ primary, destructive }) => {
	if (primary) {
		return {
			color: Colors.textColor,
			backgroundColor: rgba(Colors.primary, 0.15),
		};
	}
	if (destructive) {
		return {
			color: Colors.red,
			backgroundColor: Colors.tertiary,
		};
	}

	return {
		color: Colors.textColor,
		backgroundColor: Colors.tertiary,
	};
};

const getHoverColors = (props) => {
	const { primary, destructive, onClick } = props;

	if (!onClick) return getColors(props);

	if (primary) {
		return {
			color: Colors.textColor,
			backgroundColor: rgba(Colors.tertiary, 0.5),
		};
	}
	if (destructive) {
		return {
			color: Colors.textColor,
			backgroundColor: rgba(Colors.red, 0.5),
		};
	}

	return {
		color: Colors.textColor,
		backgroundColor: Colors.primary,
	};
};

const StyledItem = styled.button.attrs({ "data-toggle": "dropdown" })`
	&&& {
		display: flex;
		align-items: center;
		gap: 0.5rem;
		border: none;
		color: ${(props) => getColors(props).color};
		background-color: ${(props) => getColors(props).backgroundColor};
		cursor: ${({ onClick }) => (onClick ? "pointer" : "initial")};
		font-size: 0.9rem;
		padding: 8px 14px;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		width: 100%;
		transition: color 100ms, background-color 100ms;

		/* &:last-child {
			border-radius: 0 0 8px 8px;
		}
		&:first-child {
			border-radius: 8px 8px 0 0;
		} */

		.Polaris-Icon {
			width: unset;
			max-height: unset;
			max-width: unset;
			margin: 0;
		}

		svg {
			margin-right: 5px;
			color: inherit;

			height: 20px;
			width: 20px;
			transition: fill 100ms;
			fill: ${(props) => getColors(props).color};
		}

		&:hover {
			color: #ffffff;
			opacity: 1;
			overflow: visible;

			color: ${(props) => getHoverColors(props).color};
			background-color: ${(props) => getHoverColors(props).backgroundColor};

			svg {
				opacity: 1;
				fill: ${(props) => getHoverColors(props).color};
			}
		}
	}
`;

const Item = ({ children, ...props }) => {
	return <StyledItem {...props}>{children}</StyledItem>;
};

const ContextMenu = ({ outerContainer = document.body, includeMarginTop, children, activator, items, onClose }) => {
	const [show, setShow] = useState({});
	const menuRef = useRef();
	const toggleShowHide = useCallback(
		(e) => {
			if (show) return true;
			e.preventDefault();
			e.stopPropagation();

			const boundary = outerContainer?.getBoundingClientRect() || {
				width: window.innerWidth,
				height: window.innerHeight,
				bottom: 0,
				left: 0,
				top: 0,
			};
			const mouseX = e.clientX - boundary.left;
			const mouseY = e.clientY - (includeMarginTop ? boundary.top : 0);

			const menu = menuRef?.current?.getBoundingClientRect?.();
			const menuHeight = menu.height;
			const menuWidth = menu.width;
			setShow({
				show: true,
				x: Math.max(0, Math.min(mouseX, boundary.width - menuWidth)) - 2.5,
				y: Math.max(0, Math.min(mouseY, boundary.height - menuHeight)) - 2.5,
				maxHeight: boundary.height,
			});
			return false;
		},
		[outerContainer, show, includeMarginTop]
	);
	useClicksOutside(menuRef, () => {
		setShow(false);
		onClose?.();
	});

	const renderItems = (items) => {
		return items.map(({ icon, content, onAction, loading, primary, destructive, style, items }, index) => {
			if (items?.length) {
				return (
					<ContextMenuDropDown
						trigger={
							<Item style={style} key={index} onClick={onAction} primary={primary} destructive={destructive}>
								{loading ? <Spinner size={20} /> : icon}
								{content}
							</Item>
						}
					>
						{renderItems(items)}
					</ContextMenuDropDown>
				);
			}
			return (
				<Item style={style} key={index} onClick={onAction} primary={primary} destructive={destructive}>
					{loading ? <Spinner size={20} /> : icon}
					{content}
				</Item>
			);
		});
	};

	return (
		<>
			{React.cloneElement(activator, { onContextMenu: toggleShowHide })}
			{createPortal(
				<Container left={show?.x} top={show?.y} ref={menuRef} maxHeight={show?.maxHeight} show={show?.show} onClick={() => setShow({})}>
					{renderItems(items)}
					{children || null}
				</Container>,
				document.body
			)}
		</>
	);
};
export default ContextMenu;
