import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Navigate, Outlet, Route, Routes, useParams } from "react-router-dom";
import AuthenticationScreen from "./views/auth";
import ChatIndex from "./views/chat/ChatIndex";
import ChatsList from "./views/chat/ChatsList";
import Toolbar from "./views/toolbar";
import { ChatProvider } from "./contexts/ChatContext";
import Login from "./views/login";
import SearchIndex from "./views/search";
import { store } from "./store";
import Logout from "views/logout";

const Main = () => {
	const params = useParams();
	// const navigate = useNavigate();

	// useEffect(() => {
	// 	if (!store.getState().user?.id) {
	// 		if (params.id) {
	// 			navigate(`/${params.accountId}/auth`);
	// 		}
	// 	}
	// }, [params.accountId, params.id, navigate]);

	if (!store.getState().user?.id) {
		return <Navigate to={`/${params.accountId}/auth`} replace state={{ pathname: window.location.pathname, search: window.location.search }} />;
	}

	return (
		<>
			<ChatProvider>
				<div className={`chat-view`}>
					<Toolbar />
					<ChatsList />

					<Outlet />
				</div>
			</ChatProvider>
		</>
	);
};

const loginroutes = [
	{
		element: <AuthenticationScreen />,
		path: "/",
		index: true,
	},
	{
		element: <Login />,
		path: "/login",
	},
	{
		element: <Logout />,
		path: "/logout",
	},
	{
		element: <AuthenticationScreen />,
		path: "/auth",
	},
	{
		element: <AuthenticationScreen />,
		path: "/:accountId/",
	},
	{
		element: <Login />,
		path: "/:accountId/login",
	},
	{
		element: <AuthenticationScreen />,
		path: "/:accountId/auth",
	},
	{
		children: [
			{
				element: <SearchIndex />,
				exact: true,
				path: "search",
			},
			{
				element: <ChatIndex />,
				exact: true,
				path: "chat",
			},
			{
				element: <ChatIndex />,
				path: "chat/:id",
			},
			{
				element: <AuthenticationScreen />,
				path: "auth",
			},
		].map(({ element, exact, path }) => {
			return <Route element={element} exact={exact} path={path} />;
		}),

		element: <Main />,
		path: ":accountId/*",
		// authRequired: true,
	},
];

const RoutesIndex = () => {
	// eslint-disable-next-line no-unused-vars
	const users = useSelector((state) => state.users);
	// eslint-disable-next-line no-unused-vars
	const user = useSelector((state) => state.user);

	useEffect(() => {
		const getPlatformName = () => {
			if (!window?.electron) return "webb";
			if (window?.electron?.platform === "linux") return "Linux";
			if (window?.electron?.platform === "win32" || window?.electron?.platform === "win64") return "Windows";
			if (window?.electron?.platform === "darwin") return "Mac";
			return null;
		};

		document.querySelector("body")?.setAttribute("platform", getPlatformName());
	}, []);

	return (
		<BrowserRouter>
			<Routes>
				{loginroutes.map(({ element, authRequired, children, ...rest }, key) => {
					return (
						<Route
							{...rest}
							key={key}
							element={
								authRequired ? (
									store.getState().user?.id ? (
										element
									) : (
										<Navigate to="/auth" replace state={{ pathname: window.location.pathname, search: window.location.search }} />
									)
								) : (
									element
								)
							}
						>
							{children}
						</Route>
					);
				})}
			</Routes>
		</BrowserRouter>
	);
};

export default RoutesIndex;
