import React, { useMemo } from "react";
import styled from "styled-components";
import Colors from "Colors";

const StyledLoadingMessage = ({ avatar = true, index = 1, compact, children, backgroundColor }) => {
	const width = useMemo(() => {
		return Math.random() * 50;
	}, []);

	return (
		<StyledWrapper compact={compact}>
			<StyledLoadingAvatar show={avatar} compact={compact} />
			<StyledTextWrapper>
				<StyledEmptyText width={width} backgroundColor={backgroundColor}>
					{children}
				</StyledEmptyText>
				{!compact && <StyledEmptyText width={width} style={{ marginTop: 5 }} backgroundColor={backgroundColor} />}
				{/* <StyledEmptyText width={Math.max(10, Math.random() * 25)} />
				<StyledEmptyText width={Math.max(35, Math.random() * 100)} /> */}
			</StyledTextWrapper>
		</StyledWrapper>
	);
};
export default StyledLoadingMessage;

const StyledWrapper = styled.div.attrs({ className: "loading" })`
	display: flex;
	position: relative;
	padding: 5px 0;
`;
const StyledTextWrapper = styled.div`
	width: 50%;
`;

const StyledEmptyText = styled.div`
	min-height: 15px;
	border-radius: 10px;
	background: ${({ backgroundColor }) => backgroundColor || Colors.tertiary};
	position: relative;
	width: ${({ width }) => (width ? `${width}` : "100")}%;
	min-width: 15%;
`;

const StyledLoadingAvatar = styled.div`
	height: ${({ compact }) => (compact ? "100%" : "40px")};
	width: ${({ compact }) => (compact ? "40px" : "40px")};
	border-radius: 50%;
	background: ${({ backgroundColor }) => backgroundColor || Colors.tertiary};
	margin-right: 15px;
	opacity: ${({ show }) => (show ? 1 : 0)};
`;
