import React from "react";
import styled from "styled-components";
import Colors from "../Colors";

const NewMessageLine = () => {
	return (
		<StyledMessageLine>
			<div className="line" />
			<span className="text">Olästa meddelanden</span>
			<div className="line" />
		</StyledMessageLine>
	);
};

export default NewMessageLine;

const StyledMessageLine = styled.div.attrs()`
	display: flex;
	align-items: center;
	opacity: 0.75;
	z-index: 2;
	position: relative;
	font-size: 12px;
	line-height: 12px;

	.text {
		padding: 0 1rem;
	}

	.line {
		flex: 1;
		height: 1px;
		background: ${Colors.red};
		opacity: 0.5;
	}
`;
