import { Button, Modal, Select } from "@shopify/polaris";
import { LogOutMinor, SettingsMajor } from "@shopify/polaris-icons";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Popover from "components/Popover";
import ProfileAvatar from "components/ProfileAvatar";
import SettingsIcon from "icons/SettingsIcon";
import { store } from "store/index";
import ModalContent from "./ModalContent";
import { setLocalStorage } from "util";
import { setPromise } from "API";

const Settings = () => {
	const [open, setOpen] = useState();
	const [allSettingsOpen, setAllSettingsOpen] = useState();
	const navigate = useNavigate();

	const onToggle = () => {
		setOpen((c) => !c);
	};
	const onClose = () => {
		setOpen(false);
	};

	const openAllSettingsModal = () => {
		setAllSettingsOpen(true);
	};
	const closeAllSettingsModal = () => {
		setAllSettingsOpen(false);
	};

	const logout = () => {
		navigate("/logout");
	};

	return (
		<>
			<Popover
				activator={
					<StyledTrigger>
						<Button plain icon={<SettingsIcon color={"var(--textColor)"} />} onClick={onToggle} />
					</StyledTrigger>
				}
				active={open}
				onClose={onClose}
			>
				<StyledInnerWrapper>
					<StyledProfile>
						<ProfileAvatar user={store.getState().user} />
						<StyledTitle>{store.getState().user?.name}</StyledTitle>
					</StyledProfile>

					<StyledList>
						<Select
							disabled={store.getState().accounts?.length <= 1}
							value={String(store.getState().account.id)}
							options={store.getState().accounts.map((acc) => {
								return {
									label: acc.company,
									value: String(acc.id),
								};
							})}
							onChange={(accountId) => {
								store.dispatch({ type: "SET_CHATS", chats: [] });
								store.dispatch({ type: "SET_CHAT_MESSAGES", messages: {} });
								store.dispatch({ type: "SET_REF", ref: 0 });

								store.dispatch({ type: "SET_ACCOUNTS", accounts: [] });
								store.dispatch({ type: "SET_USERS", users: [] });
								store.dispatch({ type: "SET_TYPING", users: {} });
								store.dispatch({ type: "SET_MESSAGES_COUNTS", counts: {} });

								setLocalStorage("accountId", accountId);
								setLocalStorage("refresh_token", null);
								setLocalStorage("token", null);
								setPromise(null);

								navigate(`/${accountId}/auth?acc_id=${accountId}`);
							}}
						/>
						<Button plain icon={SettingsMajor} onClick={openAllSettingsModal}>
							Alla inställningar
						</Button>
						<Button plain icon={LogOutMinor} onClick={logout}>
							Logga ut
						</Button>
					</StyledList>
				</StyledInnerWrapper>
			</Popover>

			<Modal title="Inställningar" open={allSettingsOpen} onClose={closeAllSettingsModal} large fullScreen>
				<Modal.Section>
					<ModalContent />
				</Modal.Section>
				{/* <Modal.Section>Version: {process.env.REACT_APP_VERSION}</Modal.Section> */}
			</Modal>
		</>
	);
};

export default Settings;

const StyledTrigger = styled.div`
	padding-top: 1rem;
	.Polaris-Icon {
		width: 2rem;
		height: 2rem;
	}
`;
const StyledInnerWrapper = styled.div`
	background: var(--tertiary);
	padding: 1rem;
`;
const StyledProfile = styled.div`
	display: flex;
	gap: 1rem;
	align-items: center;
`;
const StyledTitle = styled.h1`
	font-size: 16px;
	font-weight: 500;
`;

const StyledList = styled.div`
	margin-top: 1.5rem;
	display: flex;
	flex-direction: column;

	.Polaris-Select--disabled {
		.Polaris-Select__Icon {
			display: none;
		}
		.Polaris-Select__Content {
			color: #ffffff;
			padding: 0;
		}
	}

	.Polaris-Select {
		.Polaris-Select__Icon .Polaris-Icon svg {
			fill: #ffffff;
		}

		.Polaris-Select__Backdrop {
			background: transparent;
			border: none;
		}
	}

	> button {
		justify-content: start;
		padding: 0.5rem 0;

		.Polaris-Icon {
			height: 1.5rem;
			width: 1.5rem;
			svg {
				fill: var(--textColor);
			}
		}
	}
`;
