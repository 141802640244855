import { Button } from "@shopify/polaris";
import { ChevronLeftMinor, ChevronRightMinor } from "@shopify/polaris-icons";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import API from "../API";
import Colors from "../Colors";
import { store } from "store/index";

const PaginationList = ({ renderItem, url, params, items: initialItems, count, limit = 5, dataKey, loading, keyGetter, resourceName, onClick }) => {
	const [items, setItems] = useState(initialItems || {});
	const [offset, setOffset] = useState(0);

	useEffect(() => {
		setItems(initialItems);
	}, [initialItems]);

	const fetch = async (offset = 0) => {
		const result = await API.get(url, { params: { ...params, limit, offset } });
		if (dataKey === "chats") {
			store.dispatch({ type: "ADD_MESSAGES", messages: result.data[dataKey] || [] });
		}

		setItems(result.data[dataKey] || []);
	};

	const paginationForward = () => {
		const newOffset = offset + limit;
		setOffset(newOffset);

		fetch(newOffset);
	};
	const paginationBackwards = () => {
		const newOffset = Math.max(0, offset - limit);
		setOffset(newOffset);

		fetch(newOffset);
	};

	const getKey = (item, index) => {
		if (keyGetter) return keyGetter(item, index);
		return item?.id || index;
	};

	return (
		<ScrollWrapper>
			{!loading && !items?.length && <StyledItem>Hittade inga {resourceName || "resultat"}</StyledItem>}
			{loading
				? [...Array(3)].map((item, index) => <StyledItem key={getKey(item, index)}>{renderItem(item, index)}</StyledItem>)
				: items?.map?.((item, index) => (
						<StyledItem onClick={() => onClick(item, index)} key={getKey(item, index)}>
							{renderItem(item, index)}
						</StyledItem>
				  ))}
			<StyledPaginationWrapper>
				{items?.length < count && (
					<div style={{ display: "flex", gap: "1rem" }}>
						<Button plain icon={ChevronLeftMinor} disabled={offset <= 0} onClick={paginationBackwards} />
						<span>
							Visar {offset + 1} - {offset + items?.length} av {count}
						</span>
						<Button plain icon={ChevronRightMinor} disabled={offset + items?.length >= count} onClick={paginationForward} />
					</div>
				)}
			</StyledPaginationWrapper>
		</ScrollWrapper>
	);
};
export default PaginationList;

const ScrollWrapper = styled.div`
	overflow-y: auto;
	position: relative;
	padding: 0 1rem;

	height: ${() =>
		`calc(100% - ${document.querySelector(".search-header")?.clientHeight ? document.querySelector(".search-header")?.clientHeight + "px" : 0})`};
	outline: none;
	overflow-y: auto;
	position: relative;
	flex: 1 1 0%;
`;

const StyledPaginationWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
`;

const StyledItem = styled.div`
	margin: 1rem 0;
	border-radius: 6px;
	background-color: ${Colors.secondary};
	padding: 0.5rem;
	cursor: ${({ onClick }) => (onClick ? "pointer" : "initial")};

	.chat-list-item {
		padding: 0;
	}
`;
