import styled from "styled-components";

export const StyledSelectWrapper = styled.div.attrs({ className: "StyledSelectWrapper" })`
	.Polaris-Select--disabled {
		.Polaris-Select__Icon {
			display: none;
		}
		.Polaris-Select__Content {
			padding: 0;
			color: #ffffff;
		}
	}

	.Polaris-Select {
		.Polaris-Select__Icon .Polaris-Icon svg {
			fill: #ffffff;
		}

		.Polaris-Select__Backdrop {
			background: transparent;
			border: none;
		}
	}
`;
