const user = (state = {}, action) => {
	switch (action?.type) {
		case "SET_USER":
			return action.user;
		case "UPDATE_ME":
			return { ...state, ...action.user };
		default:
			return state;
	}
};
export default user;
