import React from "react";

const EmojiIcon = ({ color = "var(--textColor)" }) => (
	<svg width="20" height="20" viewBox="0 0 64 64" fill="none" filltype="pathstroke" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M32 10C19.8497 10 10 19.8497 10 32C10 44.1503 19.8497 54 32 54C44.1503 54 54 44.1503 54 32C54 19.8497 44.1503 10 32 10ZM6 32C6 17.6406 17.6406 6 32 6C46.3594 6 58 17.6406 58 32C58 46.3594 46.3594 58 32 58C17.6406 58 6 46.3594 6 32Z"
			fill={color}
		/>
		<path d="M23 30C24.6569 30 26 28.6569 26 27C26 25.3431 24.6569 24 23 24C21.3431 24 20 25.3431 20 27C20 28.6569 21.3431 30 23 30Z" fill={color} />
		<path d="M41 30C42.6569 30 44 28.6569 44 27C44 25.3431 42.6569 24 41 24C39.3431 24 38 25.3431 38 27C38 28.6569 39.3431 30 41 30Z" fill={color} />
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M20.5959 36.2703C21.5512 35.7158 22.7752 36.0407 23.3297 36.996C24.2111 38.5146 25.4759 39.775 26.9975 40.6511C28.5191 41.5273 30.2441 41.9884 31.9999 41.9884C33.7558 41.9884 35.4808 41.5273 37.0024 40.6511C38.524 39.775 39.7888 38.5146 40.6702 36.996C41.2247 36.0407 42.4486 35.7158 43.4039 36.2703C44.3593 36.8248 44.6842 38.0487 44.1297 39.004C42.8965 41.1285 41.1271 42.8918 38.9984 44.1175C36.8696 45.3433 34.4563 45.9884 31.9999 45.9884C29.5435 45.9884 27.1302 45.3433 25.0015 44.1175C22.8728 42.8918 21.1033 41.1285 19.8702 39.004C19.3157 38.0487 19.6406 36.8248 20.5959 36.2703Z"
			fill={color}
		/>
	</svg>
);

export default EmojiIcon;
