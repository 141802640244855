import { createStore, combineReducers } from "redux";
import { reducer as toastrReducer } from "react-redux-toastr";
import user from "./reducers/user";
import account from "./reducers/account";
import accounts from "./reducers/accounts";
import users from "./reducers/users";
import chats from "./reducers/chats";
import chat_messages from "./reducers/chat_messages";
import message_ref from "./reducers/message_ref";
import users_typing from "./reducers/users_typing";
import chats_message_count from "./reducers/chats_message_count";
import refresh_token from "./reducers/refresh_token";
import token from "./reducers/token";
import socket from "./reducers/socket";

const reducers = {
	user: user,
	account: account,
	accounts: accounts,
	users: users,
	chats,
	chat_messages,
	message_ref,
	users_typing,
	chats_message_count,
	refresh_token,
	token,
	socket,
	toastr: toastrReducer, // <- Mounted at toastr.
};
const combinedReducer = combineReducers(reducers);

let initialState = {};
initialState.chats = [];
initialState.message_ref = 0;
initialState.chat_messages = {};
initialState.users = [];
initialState.accounts = [];
initialState.users_typing = {};
initialState.chats_message_count = {};
export const store = createStore(combinedReducer, initialState);
