import React, { useCallback, useEffect } from "react";

// import ChatWindow from "./ChatWindow";
import { store } from "store/index";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ChatWindow from "./ChatWindow";
import API from "API";
import { toastr } from "components/toastr";
import { getLocalstorage, setLocalStorage } from "util/index";
import WelcomeScreen from "./WelcomeScreen";

const ChatIndex = () => {
	const id = useParams()?.id;
	const chat = useSelector((state) => state.chats?.find?.((ch) => String(ch?.id) === String(id)));
	const navigate = useNavigate();

	const createChat = useCallback(
		(user) => {
			if (!user) return null;

			API.post("/chats.json", {
				users: [user],
				group: false,
				type: "private",
			})
				.then((result) => {
					if (result.data.info) {
						toastr.message(result.data.info);
					}

					if (result.data.chat) store.dispatch({ type: "ADD_CHAT", chat: result.data.chat });

					navigate(`/${store.getState().account.id}/chat/${result.data.chat?.id}`);
				})
				.catch((error) => toastr.error(error));
		},
		[navigate]
	);

	const joinChat = useCallback(
		(id) => {
			API.post(`/chats/${id}/join.json`)
				.then((result) => {
					if (result.data.info) {
						toastr.message(result.data.info);
					}
					if (result.data.chat) store.dispatch({ type: "ADD_CHAT", chat: result.data.chat });

					navigate(`/${store.getState().account.id}/chat/${result.data.chat?.id}`);
				})
				.catch((error) => {
					// toastr.error(error);

					navigate(`/${store.getState().account.id}/chat`);
					setLocalStorage("lastChatId", null);
				});
		},
		[navigate]
	);

	const storeUsers = store.getState().users;

	useEffect(() => {
		const userId = id?.split?.("-")?.[1];
		const otherUserId = id?.split?.("-")?.[0];
		const self = userId === otherUserId;
		const chat = store
			.getState()
			.chats?.find?.(
				(ch) =>
					String(ch?.id) === String(id) ||
					(!ch?.group &&
						ch?.members?.find((mem) => String(mem?.user?.id) === String(userId)) &&
						ch?.members?.find((mem) => String(mem?.user?.id) === String(otherUserId)) &&
						(!self || (self && ch?.members.length === 1)))
			);
		const user = storeUsers.find((usr) => String(usr.id) === userId);

		if (id && !chat && user) {
			createChat(user);
		} else if (id && !chat) {
			joinChat(id);
		}

		// else if (id && chat) {
		// 	navigate(`/chat/${chat?.id}`);
		// }
	}, [id, createChat, storeUsers, navigate, joinChat]);

	useEffect(() => {
		if (!id) {
			const lastChatId = getLocalstorage("lastChatId");
			if (lastChatId) navigate(`/${store.getState().account.id}/chat/${lastChatId}`);
		}
	}, [id, navigate]);

	if (chat) return <ChatWindow chat_id={id} disabled={!chat} />;
	return <WelcomeScreen />;
};

export default ChatIndex;
