/* eslint-disable eqeqeq */
import React, { useEffect, useRef } from "react";
import moment from "moment";
import { connect, useSelector } from "react-redux";
import { store } from "store/index";
import { toastr } from "components/toastr.js";
import API from "API.js";
import ChatWindowHeader from "./ChatWindowHeader.js";
import ChatMessage from "./ChatMessage.js";
import WelcomeScreen from "./WelcomeScreen.js";
import InputField from "components/InputField.js";
import { Virtuoso } from "react-virtuoso";
import debounce from "lodash/debounce";
import DateLine from "components/DateLine";
// import NewMessageIndicator from "components/NewMessageIndicator";
import NewMessageLine from "components/NewMessageLine";
import useMessages, { getSortedMessages } from "hooks/useMessages";
import StyledLoadingMessage from "./StyledLoadingMessage";
import { useSearchParams } from "react-router-dom";

const ChatWindow = ({ chat_id, disabled, ...props }) => {
	const id = String(chat_id);
	const virtuoso = useRef();
	const initial = useRef(true);
	const chat = useSelector((state) => state.chats.find((ch) => String(ch.id) === String(chat_id)));
	const position = useRef(chat?.last_message?.position);
	const [searchParams] = useSearchParams();

	const { messages, lastReadId, itemsRenderedFunc, myMessage, setLockedScrollItem, handleScroll } = useMessages(id, virtuoso.current);

	const commentInputRef = useRef();

	const markAsRead = debounce(
		(messageId) => {
			// const chat = store.getState().chats.find((ch) => String(ch.id) === String(id));
			if (!chat) return;
			if (!messageId || messageId <= chat.me_as_member?.last_read_id) return;

			console.log(`Marking message with id ${messageId} as read!`);

			store.dispatch({
				type: "UPDATE_MEMBER",
				member: {
					last_read_id: messageId,
					chat_id: chat.id,
					id: chat.me_as_member?.id,
				},
			});

			//Reset document.title
			const chatTitle = (() => {
				if (!chat) return "Sellfinity's chat";
				if (chat.group) return `#${chat.title || ""} | Sellfinity's chat`;
				return `${chat.title || ""} | Sellfinity's chat`;
			})();

			document.title = chatTitle;

			API.put(`/chats/${chat.id}/messages/${messageId}/read.json`)
				.then((result) => {})
				.catch((error) => console.log("error:", error));
		},
		1000,
		{ leading: false }
	);

	const sendMessage = (message) => {
		// const chat = store.getState().chats.find((c) => String(c.id) === String(chat_id));
		if (!chat.id) return null;
		myMessage.current = true;
		const ref = ++store.getState().message_ref;

		const msg = {
			...message,
			ref,
			user_id: store.getState().user.id,
			created_at: moment().format("YYYY-MM-DD HH:mm:ss.SSS"),

			position: ++position.current,
		};

		store.dispatch({
			type: "ADD_CHAT_MESSAGE",
			chat_id: id,
			message: msg,
			ref,
		});

		store.dispatch({ type: "SET_REF", ref: ref });

		Promise.allSettled(message?.files.map((file) => file.upload)).then((res) => {
			const files = message.files
				.map((file) => {
					if (file.id) return file;
					return res.find((promise) => String(promise?.value?.ref) === String(file.ref))?.value;
				})
				.map(({ data, ...rest }) => ({ ...rest }))
				.filter((i) => i);

			msg.files = files;

			API.post("/chats/" + chat.id + "/messages.json", { ...msg, ref })
				.then((result) => {
					store.dispatch({ type: "ADD_CHAT_MESSAGE", chat_id: id, message: result.data.message, ref: result.data.ref });
					store.dispatch({
						type: "UPDATE_CHAT",
						chat: { ...chat, last_message: result.data.message },
					});
				})
				.catch((error) => toastr.error(error));
		});
	};

	useEffect(() => {
		const onKeyDown = (e) => {
			const focusedElement = document.activeElement;

			if (
				!["Escape"].includes(e.key) &&
				(!e.ctrlKey || (e.ctrlKey && e.key === "v") || e.ctrlKey + e.key === "c") &&
				(!e.metaKey || (e.metaKey && e.key === "v") || e.metaKey + e.key === "c") &&
				(!focusedElement?.tagName || !["INPUT", "TEXTAREA"].includes(focusedElement?.tagName)) &&
				focusedElement.getAttribute("contenteditable") !== "true" &&
				!focusedElement.className.includes("-DraftEditor-content") &&
				!document.querySelector(".Polaris-Modal-Dialog") &&
				!document.querySelector(".Polaris-Popover__PopoverOverlay--open")
			) {
				const editorWrapper = commentInputRef.current?.querySelector(".editorWrapper");
				if (editorWrapper) {
					editorWrapper.click();
				}
			}
		};

		window.addEventListener("keydown", onKeyDown);
		return () => {
			window.removeEventListener("keydown", onKeyDown);
		};
	}, []);

	useEffect(() => {
		initial.current = true;
		position.current = chat.last_message?.position;

		if (chat) {
			document.title = `${chat?.group ? `#${chat?.title}` : chat?.title} | Sellfinity's chat`;
		} else {
			document.title = `Sellfinity's chat`;
		}
	}, [chat]);

	useEffect(() => {
		const messageFromUrl = store.getState()?.chat_messages?.[chat?.id]?.find((m) => String(m.id) === String(searchParams.get("message_id")));
		if (messageFromUrl) {
			virtuoso.current.scrollToIndex({ index: messageFromUrl?.position, align: "center" });
		}
	}, [searchParams, chat.id]);

	const renderMessage = (index, message) => {
		if (!message.id && !message.ref) return <StyledLoadingMessage compact />;
		const prevMessage = messages.find(({ position }) => position === message.position - 1);
		return (
			<React.Fragment>
				{lastReadId &&
					lastReadId < message.id &&
					(lastReadId >= prevMessage?.id || !prevMessage?.id) &&
					message.user_id !== store.getState().user.id && <NewMessageLine />}
				{!prevMessage ||
					(prevMessage && moment(message.created_at).isAfter(moment(prevMessage?.created_at), "day") && (
						<DateLine key={moment(message.created_at).unix()}>{moment(message.created_at).format("YYYY-MM-DD")}</DateLine>
					))}
				<ChatMessage
					index={index}
					message={message}
					prevMessage={prevMessage}
					nextMessage={messages[message.position + 1]}
					setLockedScrollItem={setLockedScrollItem}
					handleScroll={handleScroll}
				/>
			</React.Fragment>
		);
	};

	if (!chat) return <WelcomeScreen />;

	var fakeMessages = [...messages] || [];
	if (chat.last_message?.position) {
		for (var i = 0; i <= chat.last_message.position; i++) {
			// eslint-disable-next-line no-loop-func
			if (messages.findIndex((m) => String(m.position) === String(i)) < 0) {
				fakeMessages.push({ position: i });
			}
		}
	}

	const messageFromUrl = messages.find((m) => String(m.id) === String(searchParams.get("message_id")));

	const initialIndex = Math.max(0, messageFromUrl?.position - 10) || fakeMessages.length * 2 || 99999999999999;

	return (
		<>
			<ChatWindowHeader chat_id={chat_id} disabled={disabled} />

			<div className={`messages hideScrollbar`}>
				<Virtuoso
					ref={virtuoso}
					totalListHeightChanged={(height) => {
						// if (!myMessage.current) handleScroll();
					}}
					alignToBottom
					initialTopMostItemIndex={initialIndex}
					atBottomThreshold={10}
					atTopThreshold={0}
					firstItemIndex={0}
					// atTopThreshold={0}
					style={{ flex: 1 }}
					data={getSortedMessages(fakeMessages)}
					increaseViewportBy={{ top: 250, bottom: 250 }}
					overscan={0}
					// firstItemIndex={0}
					endReached={() => {
						myMessage.current = false;
						initial.current = false;
					}}
					itemsRendered={(items) => {
						itemsRenderedFunc(items);

						if (items.at(-1)?.data?.id > (chat.me_as_member.last_read_id || 0) && document.hasFocus()) {
							markAsRead(items.at(-1)?.data?.id);
						}

						// const messageFromUrl = messages.find((m) => String(m.id) === String(searchParams.get("message_id")));
						// if (!messageFromUrl) {
						// 	const scroller = document.querySelector('div[data-test-id="virtuoso-scroller"]');
						// 	console.log("scroller.scrollHeight:", scroller.scrollHeight);
						// 	scroller.scrollTop = scroller.scrollHeight * 2;
						// }
					}}
					followOutput={(isAtBottom) => {
						if (!initial.current) return "auto";
						if (myMessage.current) {
							return isAtBottom ? "smooth" : "auto";
						} else {
							return isAtBottom ? "smooth" : false;
						}
					}}
					computeItemKey={(_, message) => {
						return message.id || message.ref || message.position;
					}}
					itemContent={renderMessage}
					components={{
						EmptyPlaceholder: () => {
							return null;
						},
					}}
				/>
				{/* {!!unreadCount && <NewMessageIndicator virtuoso={virtuoso} messages={messages} unreadCount={unreadCount} />} */}
			</div>
			<InputField id="chatwindow_inputfield" onSave={sendMessage} chat={chat} ref={commentInputRef} />
		</>
	);
};

const mapStateToProps = (state, ownProps) => ({
	chat_messages: state.chat_messages,
	users: state.users,
});

export default connect(mapStateToProps)(ChatWindow);
