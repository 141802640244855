import React, { useMemo, useRef, useState } from "react";
import { EditorState } from "draft-js";
import Editor from "@draft-js-plugins/editor";
import createMentionPlugin from "@draft-js-plugins/mention";
import createLinkifyPlugin from "@draft-js-plugins/linkify";

import "@draft-js-plugins/static-toolbar/lib/plugin.css";
import "@draft-js-plugins/emoji/lib/plugin.css";
import "@draft-js-plugins/mention/lib/plugin.css";

import { EditorWrapper } from "./styledComponents";
import editorStyles from "./editor.module.scss";
import mentionsStyles from "./mention/mentionsStyles.module.scss";
import { blockRendererFn, markdownToEditorState } from "./Utilities";
// import createMarkdownShortcutsPlugin from "draft-js-markdown-shortcuts-plugin";

const linkifyPlugin = createLinkifyPlugin({ theme: { link: editorStyles.link }, target: "_blank" });

const Viewer = ({ message }) => {
	const [editorState, setEditorState] = useState(message.content ? markdownToEditorState(message.content) : EditorState.createEmpty());
	const ref = useRef(null);

	const { plugins } = useMemo(() => {
		const mentionPlugin = createMentionPlugin({
			entityMutability: "IMMUTABLE",
			theme: mentionsStyles,
			mentionPrefix: "@",
			supportWhitespace: true,
		});

		const plugins = [mentionPlugin, linkifyPlugin];
		return { plugins };
	}, []);

	return (
		<>
			<EditorWrapper readOnly>
				<div>
					<Editor
						onChange={setEditorState}
						editorKey={"editor"}
						editorState={editorState}
						plugins={plugins}
						ref={ref}
						blockRendererFn={blockRendererFn}
						readOnly
					/>
				</div>
			</EditorWrapper>
		</>
	);
};

export default Viewer;
