import axios from "axios";
import { store } from "store";
import { getLocalstorage, setLocalStorage, getSessionStorage, decodeTokenUser, getCookie } from "util";
import { toastr } from "./components/toastr";
import { getTokenTTL } from "components/API/utils";

export const BASE_URL = "https://www.sellfinity.com/api";
// export const BASE_URL = "http://127.0.0.1:8000/api";
// export const BASE_URL = "https://itbmedia3.eu.ngrok.io/api";
const API = axios.create({
	baseURL: BASE_URL,
	timeout: 60000,
});

const TTL = 1000 * (60 * 60) * 12;

/**
 *
 * @param {String} token - Whole base64 encoded token
 * @returns {String} token - TTL UNIX timestamp in ms
 */

// const controller = new AbortController();
let promise = null;

export const setPromise = (data) => {
	promise = data;
};

const getToken = async () => {
	const token = store.getState().token || getCookie("JWTTOKEN") || getSessionStorage("jwttoken") || getLocalstorage("jwttoken");
	const tokenData = decodeTokenUser(token);
	if ((tokenData?.exp || 0) * 1000 - 60 * 1000 > Date.now()) {
		return token;
	} else {
		const request = await tokenValidation();
		return request?.data?.token;
	}
};

const tokenValidation = async () => {
	if (!promise?.request || Date.now() > promise?.ttl) {
		const request = fetchTokens();

		promise = {
			request: request,
			ttl: Date.now() + TTL - 60000,
		};

		if (promise) promise.ttl = getTokenTTL(await request.then((r) => r?.data?.token));
	}

	return promise?.request;
};

const fetchTokens = async () => {
	const refresh_token = store.getState().refresh_token || getLocalstorage("refresh_token");
	// const token = store.getState().token || getLocalstorage("jwt_token");

	if (refresh_token) {
		return axios
			.post(`${BASE_URL}/token/refresh_token`, { refresh_token })
			.then((res) => {
				setLocalStorage("refresh_token", res.data.refresh_token);
				setLocalStorage("jwt_token", res.data.token);

				store.dispatch({ type: "SET_TOKEN", token: res.data.token });
				store.dispatch({ type: "SET_REFRESH_TOKEN", refresh_token: res.data.refresh_token });

				return res;
			})
			.catch((error) => {
				console.error("error:", error);
				if (axios.isCancel(error)) {
					return Promise.reject(error);
				}

				toastr.error(error);

				window.location = "/login";
			});
	} else {
		console.log('"No refresh_token available"');
		toastr.error("No refresh_token available");
		window.location = "/login";
	}
};

API.interceptors.request.use(
	async (config) => {
		try {
			const refresh_token = store.getState().refresh_token || getLocalstorage("refresh_token");
			if ((!config.headers?.["Authorization"]?.length || config.headers?.["Authorization"]?.length < 20) && refresh_token && !config.skipToken) {
				const token = await getToken();
				config.headers["Authorization"] = `Bearer ${token}`;
			}
		} catch (e) {
			console.log("Error", e);
		}
		return config;
	},
	(error) => {
		Promise.reject(error);
	}
);

API.interceptors.response.use(
	function (response) {
		if (response.data.error) {
			console.error("error:", response.data.error);
			toastr.error(response.data.error);
			throw response.data.error;
		}
		return response;
	},
	async function (error) {
		if (axios.isCancel(error)) {
			return Promise.reject(error);
		}

		const { code /*message*/ } = error.response?.data || {};
		//Expired JWT Token
		//Invalid JWT Token
		if (code === 401 || error?.headers?.location === "/admin/authentication/login") {
			const token = await getToken();
			return axios({ ...(error.config || {}), headers: { ...(error.config.headers || {}), Authorization: `Bearer ${token}` } });
		}

		console.error("error:", error.message);
		toastr.error(error.message);

		// console.log("API error:", error);
		return Promise.reject(error.message);
	}
);

export default API;
