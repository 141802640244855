import React from "react";
import Colors from "../Colors";

const NotificationOnIcon = ({ color = Colors.textColor, size = 32 }) => (
	<svg width={size} height={size} viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M15.9999 6.28754C14.9526 6.28754 13.9156 6.49382 12.948 6.8946C11.9804 7.29538 11.1013 7.88281 10.3607 8.62336C9.62017 9.36391 9.03274 10.2431 8.63196 11.2106C8.23118 12.1782 8.0249 13.2152 8.0249 14.2625C8.0249 18.8782 7.05711 21.658 6.12842 23.2625H25.8714C24.9427 21.658 23.9749 18.8782 23.9749 14.2625C23.9749 13.2152 23.7686 12.1782 23.3678 11.2106C22.9671 10.2431 22.3796 9.36391 21.6391 8.62336C20.8985 7.88281 20.0194 7.29538 19.0518 6.8946C18.0842 6.49382 17.0472 6.28754 15.9999 6.28754ZM12.1826 5.04684C13.3929 4.54555 14.69 4.28754 15.9999 4.28754C17.3098 4.28754 18.6069 4.54555 19.8172 5.04684C21.0274 5.54813 22.127 6.28288 23.0533 7.20915C23.9795 8.13541 24.7143 9.23505 25.2156 10.4453C25.7169 11.6555 25.9749 12.9526 25.9749 14.2625C25.9749 18.5959 26.8817 21.0159 27.6028 22.2615L27.6034 22.2625C27.7787 22.5661 27.8711 22.9103 27.8714 23.2608C27.8717 23.6112 27.7799 23.9557 27.6052 24.2595C27.4305 24.5633 27.179 24.8159 26.8759 24.9919C26.5729 25.168 26.2289 25.2613 25.8784 25.2625L25.8749 25.2625H6.1249L6.12137 25.2625C5.7709 25.2613 5.4269 25.168 5.12384 24.9919C4.82078 24.8159 4.56931 24.5633 4.39461 24.2595C4.2199 23.9557 4.12811 23.6112 4.12842 23.2608C4.12873 22.9103 4.22113 22.5661 4.39637 22.2625L4.39697 22.2615C5.11809 21.0159 6.0249 18.5959 6.0249 14.2625C6.0249 12.9526 6.28291 11.6555 6.7842 10.4453C7.28549 9.23505 8.02024 8.13541 8.94651 7.20915C9.87277 6.28288 10.9724 5.54813 12.1826 5.04684Z"
			fill={color}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12 23.2625C12.5523 23.2625 13 23.7102 13 24.2625V25.2625C13 26.0582 13.3161 26.8212 13.8787 27.3838C14.4413 27.9464 15.2044 28.2625 16 28.2625C16.7956 28.2625 17.5587 27.9464 18.1213 27.3838C18.6839 26.8212 19 26.0582 19 25.2625V24.2625C19 23.7102 19.4477 23.2625 20 23.2625C20.5523 23.2625 21 23.7102 21 24.2625V25.2625C21 26.5886 20.4732 27.8604 19.5355 28.798C18.5979 29.7357 17.3261 30.2625 16 30.2625C14.6739 30.2625 13.4021 29.7357 12.4645 28.798C11.5268 27.8604 11 26.5886 11 25.2625V24.2625C11 23.7102 11.4477 23.2625 12 23.2625Z"
			fill={color}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M22.0793 2.72873C22.3741 2.26171 22.9917 2.1221 23.4587 2.4169C25.6499 3.80009 27.4184 5.75939 28.5706 8.08036C28.8162 8.57504 28.6143 9.17514 28.1196 9.42072C27.6249 9.6663 27.0248 9.46436 26.7792 8.96968C25.7902 6.97734 24.2721 5.29547 22.3911 4.10813C21.9241 3.81333 21.7845 3.19575 22.0793 2.72873Z"
			fill={color}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M9.92054 2.72873C10.2153 3.19575 10.0757 3.81333 9.60872 4.10813C7.72779 5.29547 6.20971 6.97734 5.22063 8.96968C4.97505 9.46436 4.37495 9.6663 3.88027 9.42072C3.38559 9.17514 3.18365 8.57504 3.42923 8.08036C4.58146 5.75939 6.34994 3.80009 8.54113 2.4169C9.00815 2.1221 9.62574 2.26171 9.92054 2.72873Z"
			fill={color}
		/>
	</svg>
);

export default NotificationOnIcon;
