const socket = (
	state = {
		logs: [],
	},
	action
) => {
	switch (action.type) {
		case "SET_SOCKET_ERROR":
			return { ...state, error: action.error };
		case "SET_SOCKET_CONNECTED":
			return { ...state, connected: action.connected };
		case "ADD_SOCKET_LOG":
			return { ...state, logs: [action.log, ...state.logs] };
		default:
			return state;
	}
};

export default socket;
