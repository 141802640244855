import { Avatar, Icon, Tooltip } from "@shopify/polaris";
import React, { Component } from "react";
import OnlineDot from "./OnlineDot";

class ProfileAvatar extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	onClick() {
		if (this.props.onClick) this.props.onClick(this.props.user);
	}

	render() {
		const { user, children, size = "medium", tooltip, icon, onClick, initials } = this.props;
		if (!user)
			return (
				<div className="Profile-Avatar">
					<Avatar name="--" size={typeof size !== "string" ? "medium" : size} initials={initials} />
				</div>
			);

		if (!user.initials && user.name) {
			const name = user.name;
			const nameArray = name && name.split(" ");
			const firstName = (nameArray && nameArray.shift()) || "";
			const lastName = (nameArray && nameArray.pop()) || "";

			user.initials = ((firstName || "").charAt(0) + (lastName || "").charAt(0)).toUpperCase();
		}

		const avatar = icon ? (
			<Icon source={icon} />
		) : (
			<Avatar source={user.avatar || ""} name={user.name || ""} initials={user.initials || ""} size={typeof size === "string" ? size : "medium"} />
		);

		if ((size && !["small", "medium", "large"].includes(size)) || icon) {
			return (
				<Tooltip
					content={
						tooltip === undefined ? (
							<div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
								<div style={{ position: "relative" }}>
									{icon ? (
										<Icon source={icon} />
									) : (
										<Avatar source={user.avatar || ""} name={user.name || ""} initials={user.initials || ""} size={"large"} />
									)}
									{!this.props.hideStatus && <OnlineDot user={user} />}
								</div>
								<div style={{ display: "flex", flexDirection: "column" }}>
									{user.name}
									<span style={{ opacity: 0.75, fontSize: "0.9em" }}>{user.email}</span>
								</div>
							</div>
						) : (
							tooltip
						)
					}
				>
					{this.props.onClick ? (
						<button
							onClick={this.onClick.bind(this)}
							className={`profile-icon ${this.props.className || ""}`}
							style={Object.assign(size ? { width: size + "px", height: size + "px" } : {}, {
								cursor: onClick ? "pointer" : "initial",
							})}
						>
							{!this.props.hideStatus && <OnlineDot user={user} />}
							{avatar}
							<div className="children">{children}</div>
						</button>
					) : (
						<div
							className={`profile-icon ${this.props.className || ""}`}
							style={Object.assign(size ? { width: size + "px", height: size + "px" } : {})}
						>
							{!this.props.hideStatus && <OnlineDot user={user} />}
							{avatar}
							<div className="children">{children}</div>
						</div>
					)}
				</Tooltip>
			);
		}
		return (
			<Tooltip
				content={
					tooltip === undefined ? (
						<div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
							<div style={{ position: "relative" }}>
								{icon ? (
									<Icon source={icon} />
								) : (
									<Avatar
										source={user.avatar || ""}
										name={user.name || ""}
										initials={user.initials || ""}
										size={typeof size === "string" ? size : "medium"}
									/>
								)}
								{!this.props.hideStatus && <OnlineDot user={user} />}
							</div>
							<div style={{ display: "flex", flexDirection: "column" }}>
								{user.name}
								<span style={{ opacity: 0.75, fontSize: "0.9em" }}>{user.email}</span>
							</div>
						</div>
					) : (
						tooltip
					)
				}
			>
				{this.props.onClick ? (
					<button
						onClick={this.onClick.bind(this)}
						className={`Profile-Avatar ${this.props.className || ""}`}
						style={{ cursor: onClick ? "pointer" : "initial", position: "relative", display: "flex", gap: "1rem", alignItems: "center" }}
					>
						<div style={{ position: "relative" }}>
							{!this.props.hideStatus && <OnlineDot user={user} />}
							{avatar}
						</div>
						{children}
					</button>
				) : (
					<div
						className={`Profile-Avatar ${this.props.className || ""}`}
						style={{ position: "relative", display: "flex", gap: "1rem", alignItems: "center" }}
					>
						<div style={{ position: "relative" }}>
							{!this.props.hideStatus && <OnlineDot user={user} />}
							{avatar}
						</div>
						{children}
					</div>
				)}
			</Tooltip>
		);
	}
}
export default ProfileAvatar;
