import { AddCookie } from "./../../util";

const token = (state = null, action) => {
	switch (action?.type) {
		case "SET_TOKEN":
			const tokenData = JSON.parse(Buffer.from(action.token?.split(".")[1], "base64").toString());
			AddCookie("JWTTOKEN", action.token, {
				// domain: `${tokenData?.account?.handle || ""}.sellfinity.com`,
				domain: `sellfinity.com`,
				expires: new Date(tokenData.exp * 1000 - 60000).toUTCString(),
			});
			return action.token;
		default:
			return state;
	}
};
export default token;
