import { Button, Icon, Modal, Spinner } from "@shopify/polaris";
import { CircleCancelMajor, MobileVerticalDotsMajor } from "@shopify/polaris-icons";
import React, { useState } from "react";
import styled from "styled-components";
import API from "../API";
import Colors from "../Colors";
import NotificationOffIcon from "../icons/NotificationOffIcon";
import { store } from "store/index";
import Badge from "./Badge";
import Popover from "./Popover";
import ProfileAvatar from "./ProfileAvatar";
import { toastr } from "./toastr";

const ChatMemberListItem = ({ member, onRemove, showAll = true, chat, fontSize = "1em" }) => {
	const [open, setOpen] = useState();
	const [removeOpen, setRemoveOpen] = useState();
	const [adminOpen, setAdminOpen] = useState();

	const onToggle = () => {
		setOpen((c) => !c);
	};
	const onClose = () => {
		setOpen(false);
	};

	const onRemoveToggle = () => {
		setRemoveOpen((c) => !c);
	};
	const onRemoveClose = () => {
		setRemoveOpen(false);
	};

	const onAdminToggle = () => {
		setAdminOpen((c) => !c);
	};
	const onAdminClose = () => {
		setAdminOpen(false);
	};

	const onRemoveMember = () => {
		const index = chat?.members?.findIndex((mem) => String(mem.id) === String(member.id));
		if (index >= 0) {
			chat?.members?.splice(index, 1);
			store.dispatch({ type: "UPDATE_CHAT", chat: { ...chat, members: [...(chat.members || [])] } });
		}

		API.delete(`/chats/${chat?.id}/members/${member?.id}.json`)
			.then((result) => {})
			.catch((error) => {
				console.log("error:", error);
				toastr.error(error);
			});
	};

	const onAdminGrant = () => {
		const index = chat?.members?.findIndex((mem) => String(mem.id) === String(member.id));
		const mem = chat.members?.[index];
		console.log("index:", index);
		if (index >= 0) {
			mem.admin = true;
			store.dispatch({ type: "UPDATE_CHAT", chat });
		}

		API.put(`/chats/${chat?.id}/members/${member?.id}.json`, { ...mem })
			.then((result) => {})
			.catch((error) => {
				console.log("error:", error);
				toastr.error(error);
			});
	};
	const onAdminGrantRemove = () => {
		const index = chat?.members?.findIndex((mem) => String(mem.id) === String(member.id));
		const mem = chat.members?.[index];
		if (index >= 0) {
			mem.admin = false;
			store.dispatch({ type: "UPDATE_CHAT", chat });
		}

		API.put(`/chats/${chat?.id}/members/${member?.id}.json`, { ...mem })
			.then((result) => {})
			.catch((error) => {
				console.log("error:", error);
				toastr.error(error);
			});
	};

	const getUser = () => {
		return store.getState().users.find((u) => String(u.id) === String(member?.user?.id));
	};

	return (
		<StyledWrapper key={member?.user.id}>
			<StyledInnerWrapper>
				<ProfileAvatar user={getUser()} />
				<StyledTitle fontSize={fontSize}>{member?.user?.name}</StyledTitle>
				{!showAll && onRemove && (
					<span className="remove remove_user">
						<Button plain destructive icon={CircleCancelMajor} onClick={onRemove} />
					</span>
				)}
			</StyledInnerWrapper>

			{showAll && (
				<StyledFlexWrapper>
					{member?.mute && <Icon source={NotificationOffIcon} />}

					{member?.creator && <Badge color={Colors.pink}>Skapare</Badge>}
					{member?.admin && <Badge color={Colors.accent}>Admin</Badge>}
					{(chat?.me_as_member?.admin || chat?.me_as_member?.creator) && (
						<Popover
							active={open}
							activator={!member.id ? <Spinner size="small" /> : <Button plain icon={MobileVerticalDotsMajor} onClick={onToggle} />}
							onClose={onClose}
						>
							<StyledList onClick={onClose}>
								{member?.id && (chat?.me_as_member?.creator || chat?.me_as_member?.admin) && (
									<>
										{member?.admin ? (
											<Button plain icon={CircleCancelMajor} onClick={onAdminToggle} disabled={!member.id}>
												Ta bort admin behörighet
											</Button>
										) : (
											<Button plain icon={CircleCancelMajor} onClick={onAdminToggle} disabled={!member.id}>
												Ge admin behörighet
											</Button>
										)}
									</>
								)}

								<Button plain destructive icon={CircleCancelMajor} onClick={onRemoveToggle} disabled={!member.id}>
									Ta bort
								</Button>
							</StyledList>
						</Popover>
					)}
				</StyledFlexWrapper>
			)}

			{(chat?.me_as_member?.creator || chat?.me_as_member?.admin) && (
				<>
					<Modal
						title={`Ta bort ${member?.user?.name} från "${chat?.title}"?`}
						onClose={onRemoveClose}
						open={removeOpen}
						// primaryAction={{
						// 	content: "Ta bort",
						// 	onAction: () => {
						// 		if (onRemove) {
						// 			onRemove();
						// 		} else {
						// 			onRemoveMember();
						// 		}
						// 		onRemoveClose();
						// 	},
						// }}
						secondaryActions={[
							{ content: "Avbryt", onAction: onRemoveClose },
							{
								content: "Ta bort",
								destructive: true,
								onAction: () => {
									if (onRemove) {
										onRemove();
									} else {
										onRemoveMember();
									}
									onRemoveClose();
								},
							},
						]}
					>
						<Modal.Section>
							Ta bort {member?.user?.name} ifrån "{chat?.title}" kanalen.
							<br /> {member?.user?.name}'s meddelanden kommer vara kvar i kanalen.
						</Modal.Section>
					</Modal>

					<Modal
						title={`${member?.admin ? "Ta bort" : "Ge"} admin behörighet till ${member?.user?.name}?`}
						onClose={onAdminClose}
						open={adminOpen}
						// primaryAction={{
						// 	content: `${member?.admin ? "Ta bort" : "Ge"} admin behörighet`,

						// 	onAction: () => {
						// 		if (member?.admin) {
						// 			onAdminGrantRemove();
						// 		} else {
						// 			onAdminGrant();
						// 		}
						// 		onAdminClose();
						// 	},
						// }}
						secondaryActions={[
							{ content: "Avbryt", onAction: onAdminClose },
							{
								content: `${member?.admin ? "Ta bort" : "Ge"} admin behörighet`,
								destructive: member?.admin ? true : false,
								primary: !member?.admin,
								onAction: () => {
									if (member?.admin) {
										onAdminGrantRemove();
									} else {
										onAdminGrant();
									}
									onAdminClose();
								},
							},
						]}
					>
						<Modal.Section>
							{member?.admin
								? `Ta bort admin behörighet för ${member?.user?.name}?`
								: `${member?.user?.name} kommer få admin behörighet för ${chat?.title} kanalen och kommer kunna hantera alla aspekter av kanalen`}
						</Modal.Section>
					</Modal>
				</>
			)}
		</StyledWrapper>
	);
};
export default ChatMemberListItem;

const StyledWrapper = styled.div`
	display: flex;
	gap: 1rem;
	align-items: center;
	justify-content: space-between;

	.Polaris-Avatar {
		background-color: rgba(255, 255, 255, 0.1);

		text {
			color: rgba(255, 255, 255, 0.5);
		}
	}

	.remove_user {
		transition: opacity 250ms;
		opacity: 0;
	}

	&:hover {
		.remove,
		.remove_user {
			opacity: 1;
		}
	}
`;
const StyledInnerWrapper = styled.div`
	display: flex;
	gap: 1rem;
	align-items: center;
`;

const StyledTitle = styled.p`
	font-size: ${({ fontSize }) => fontSize || "16px"};
	font-weight: 700;
	text-overflow: ellipsis;
	overflow: hidden;
	-webkit-line-clamp: 1;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	word-break: break-all;
`;

const StyledList = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.5rem;

	& > * {
		justify-content: start;
	}
`;

const StyledFlexWrapper = styled.div`
	display: flex;
	gap: 1rem;
`;
