import axios from "axios";
import { debounce } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import API from "../API";
import { store } from "store/index";
import { setLocalStorage } from "util/index";

const LIMIT = 50;
const controller = new AbortController();

export const getSortedMessages = (messages) => {
	return messages?.sort((a, b) => {
		return a.position - b.position;
		// if (typeof a.id === "number" && typeof b.id === "number") {
		// 	return b.id - a.id;
		// } else if (typeof a.ref === "number" && typeof b.ref === "number") {
		// 	return b.ref - a.ref;
		// } else if (typeof a.ref === "number" && typeof !b.ref === "number") {
		// 	return -1;
		// } else if (typeof b.ref === "number" && typeof !a.ref === "number") {
		// 	return 1;
		// } else if (typeof a.position === "number" && typeof b.position === "number") {
		// 	return b.position - a.position;
		// }
	});
	// ?.reverse();
};

const useMessages = (id, virtuoso) => {
	const storeMessages = useSelector((state) => state.chat_messages?.[id]);
	const [initialLoadDone, setInitialLoadDone] = useState();

	const timeout = useRef();
	const fetchingMessages = useRef([]);
	const lockedScrollItem = useRef();
	const itemsRendered = useRef([]);
	const myMessage = useRef(false);

	const setLockedScrollItem = (item) => {
		lockedScrollItem.current = item;
	};

	const itemsRenderedFunc = (itemsRendered2) => {
		itemsRendered.current = itemsRendered2;

		const nullMessages = itemsRendered2.filter((m) => {
			return !m.data?.id && !m.data?.ref && fetchingMessages.current.indexOf(m.data?.position) < 0;
		});
		if (nullMessages.length < 1) {
			return;
		}

		const firstNullIndex = nullMessages.at(0).data?.position;
		const lastNullIndex = nullMessages.at(-1).data?.position;

		// if (firstNullIndex !== null && initialLoadDone) {
		if (firstNullIndex !== null) {
			fetchMessages(firstNullIndex, lastNullIndex);
		}
	};

	const chat = useMemo(() => {
		const chat = store.getState().chats?.find((chat) => String(chat?.id) === String(id));
		return chat;
	}, [id]);

	const [lastReadId, setLastReadId] = useState(chat.me_as_member?.last_read_id);
	const [messages, setMessages] = useState(getSortedMessages(storeMessages || []));

	const fetchMessages = useMemo(
		() =>
			debounce(
				(start, end) => {
					// console.log("fetchMessages:");
					clearTimeout(timeout.current);
					if (!id) return null;

					const chatId = id;
					const params = {};
					if (!start && !end) {
						params.limit = LIMIT;
					} else {
						params.position_start = start;
						params.position_end = end;
						for (let i = start; i <= end; i++) {
							fetchingMessages.current.push(i);
						}
					}
					API.get("/chats/" + chatId + "/messages.json", {
						params: params,
						signal: controller.signal,
					})
						.then((result) => {
							const alreadyFetchedMessages = itemsRendered.current?.filter((m) => m.data.id || m.data.ref);
							const nullMessages = itemsRendered.current.filter((m) => {
								return !m.data.id && !m.data.ref && fetchingMessages.current.indexOf(m.data.position) < 0;
							});

							const searchParams = new URLSearchParams(window.location.search);
							const messageFromUrl = itemsRendered.current.find((m) => String(m.data?.id) === String(searchParams.get("message_id")));
							const lockedItem = messageFromUrl || (alreadyFetchedMessages.length && alreadyFetchedMessages.at(-1)) || nullMessages.at(-1);

							if (lockedItem) {
								lockedScrollItem.current = {
									item: JSON.parse(JSON.stringify(lockedItem)),
									currentScrollTop: document.querySelector('div[data-test-id="virtuoso-scroller"]')?.scrollTop,
									currentScrollHeight: document.querySelector('div[data-test-id="virtuoso-scroller"]')?.scrollHeight,
								};
							}

							setLocalStorage("lastChatId", chatId);

							if (result.data.messages?.length) store.dispatch({ type: "ADD_MESSAGES", messages: result.data.messages });

							//setTimeout(() => {
							setInitialLoadDone(true);
							//}, 250);
						})
						.catch((error) => {
							console.log("error:", error);
							if (axios.isCancel(error)) {
								return;
							}
						});
				},
				250,
				{ maxWait: 1000, leading: true }
			),
		[id]
	);

	useEffect(() => {
		const onBlur = () => {
			setLastReadId(chat.me_as_member?.last_read_id);
		};

		window.addEventListener("blur", onBlur);

		return () => {
			window.removeEventListener("blur", onBlur);
		};
	}, [id, chat.me_as_member?.last_read_id]);

	useEffect(() => {
		const chat = store.getState().chats.find((chat) => String(chat?.id) === String(id));
		setLastReadId(chat.me_as_member?.last_read_id);
		setInitialLoadDone(false);

		return () => {
			setInitialLoadDone(false);
			// controller.abort();
		};
	}, [id]);

	const handleScroll = () => {
		const lockedElement = lockedScrollItem.current;
		// virtuoso?.scrollToIndex?.({ align: "center", index: lockedElement?.item?.index });
		const scroller = document.querySelector('div[data-test-id="virtuoso-scroller"]');
		const { top } = document.querySelector(`div[data-index="${lockedElement?.item?.index}"]`)?.getBoundingClientRect() || {};
		if (!isNaN(top) && lockedElement) {
			const newOffset = Math.round(top) - scroller.getBoundingClientRect().top;
			const oldOffset = lockedElement.item.offset - lockedElement.currentScrollTop;
			const diff = newOffset - oldOffset;

			if (diff) {
				scroller.scrollTop = scroller.scrollTop - Math.abs(diff);
			}
		}
	};

	useEffect(() => {
		setMessages(getSortedMessages(storeMessages || []));
		if (!myMessage.current) {
			handleScroll();
			// setTimeout(() => {
			// 	handleScroll();
			// }, 0);
		}
	}, [id, storeMessages]);

	useEffect(() => {
		const timer = timeout.current;

		// if (id && !id?.includes("-") && storeMessages?.length < 50 && !searchParams.has("message_id")) {

		if (id && !id?.includes("-") && (store.getState().chat_messages?.[id]?.length || 0) < 50) {
			fetchMessages();
		} else {
			// setTimeout(() => {
			// 	const scroller = document.querySelector('div[data-test-id="virtuoso-scroller"]');
			// 	console.log("scroller.scrollHeight:", scroller.scrollHeight);
			// 	scroller.scrollTop = scroller.scrollHeight * 2;
			// }, 0);

			// setTimeout(() => {
			setInitialLoadDone(true);
			// }, 250);
		}

		return () => {
			if (timer) clearTimeout(timer);
		};
	}, [id, fetchMessages]);

	return {
		messages,
		lastReadId,
		handleScroll,
		itemsRenderedFunc,
		initialLoadDone,
		myMessage,
		itemsRendered: itemsRendered.current,
		setLockedScrollItem,
	};
};
export default useMessages;
