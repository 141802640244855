const chats = (state = {}, action) => {
	switch (action.type) {
		case "SET_CHATS":
			return action.chats;
		case "ADD_CHAT": {
			const index = state.findIndex((c) => String(c.id) === String(action.chat.id));
			if (index >= 0) {
				state.splice(index, 1, Object.assign(state[index], action.chat));
				return [...state];
			}

			return [action.chat, ...state];
		}
		case "UPDATE_CHAT": {
			var index = state.findIndex((ch) => String(ch.id) === String(action.chat.id));
			if (!action.chat?.group) {
				// eslint-disable-next-line no-undef
				const otherMember = action.chat?.members?.find((mem) => String(mem.user?.id) !== String(user?.id));

				state.splice(index, 1, { ...(state[index] || {}), ...action.chat, title: otherMember?.user?.name || otherMember?.user?.email });
			} else {
				state.splice(index, 1, Object.assign(state[index] || {}, action.chat));
			}

			return [...state];
		}
		case "UPDATE_MEMBER": {
			const chatIndex = state.findIndex((ch) => String(ch.id) === String(action.member?.chat_id));

			if (chatIndex >= 0) {
				const memberIndex = state[chatIndex]?.members?.findIndex((mem) => String(mem.id) === String(action.member.id));
				const member = { ...(state[chatIndex]?.members?.[memberIndex] || {}), ...action.member };

				if (memberIndex >= 0 && member) {
					member.last_read_id *= 1;
					state[chatIndex]?.members.splice(memberIndex, member);
					state[chatIndex].mute = member.mute;
				}
				// eslint-disable-next-line no-undef
				if (String(member?.user?.id) === String(user?.id)) {
					state.splice(chatIndex, 1, { ...(state[chatIndex] || {}), me_as_member: member });
				} else {
					state.splice(chatIndex, 1, { ...(state[chatIndex] || {}) });
				}
			}

			return [...state];
		}
		case "REMOVE_CHAT": {
			const index = state.findIndex((ch) => String(ch.id) === String(action.chat.id));
			if (index >= 0) {
				state.splice(index, 1);
				return [...state];
			}
			return state;
		}
		default:
			return state;
	}
};
export default chats;
