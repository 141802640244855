import styled from "styled-components";
import React from "react";

const StyledSpinner = styled.div.attrs({ className: "spinner" })`
	position: relative;
	width: ${({ size = 24 }) => (typeof size === "number" ? `${size}px` : size)};
	height: ${({ size = 24 }) => (typeof size === "number" ? `${size}px` : size)};

	div {
		box-sizing: border-box;
		display: block;
		position: absolute;
		width: ${({ size = 24 }) => (typeof size === "number" ? `${size}px` : size)};
		height: ${({ size = 24 }) => (typeof size === "number" ? `${size}px` : size)};
		/* margin: 3px; */
		border: ${({ color = "#ffffff", thickness = 3 }) => `${thickness}px solid ${color}`};
		border-radius: 50%;
		animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
		border-color: #fff transparent transparent transparent;
		border-color: ${({ color = "#ffffff" }) => `${color} transparent transparent transparent`};
	}
	div:nth-child(1) {
		animation-delay: -0.45s;
	}
	div:nth-child(2) {
		animation-delay: -0.3s;
	}
	div:nth-child(3) {
		animation-delay: -0.15s;
	}

	@keyframes lds-ring {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
`;

/**
 * @param {Number} [size=24] - size (height & width)
 * @param {Number} [thickness=3] - thickness
 * @param {String} [color="#ffffff"] - color
 * @returns JSX <Spinner />
 */
const Spinner = ({ size, thickness, color }) => (
	<StyledSpinner size={size} thickness={thickness} color={color}>
		<div />
		<div />
		<div />
		<div />
	</StyledSpinner>
);

export default Spinner;
