/* eslint-disable eqeqeq */
import React, { Component } from "react";
import { TextContainer, Autocomplete, Icon, Stack, Spinner, OptionList, KeypressListener } from "@shopify/polaris";
import axios from "axios";
import { SearchMajor, CirclePlusMajor } from "@shopify/polaris-icons";
import { toastr } from "./toastr.js";
import trans from "../trans.js";
import API from "../API.js";

class SearchField extends Component {
	constructor(props) {
		super(props);

		var pcs = props.resource.split("/");
		var resourceHandle = pcs[pcs.length - 1];

		var resourceName = props.resourceName
			? props.resourceName
			: {
					singular: props.resource_handle ? props.resource_handle : resourceHandle,
					plural: props.resource_handle ? props.resource_handle : resourceHandle,
			  };

		var randNum = Math.ceil(Math.random() * 10000001);

		this.state = {
			myId: randNum,
			selected: [],
			limit: 25,
			value: "",
			tags: props.tags ? props.tags : false,
			active: false,
			loading: true,
			resource: props.resource,
			timeout: null,
			cancelToken: null,
			results: [],
			resourceName,
			id_handle: props.id_handle ? props.id_handle : "id",
			label_handle: props.label_handle ? props.label_handle : "title",
			resource_handle: props.resource_handle ? props.resource_handle : resourceHandle,
		};

		if (this.props.value) {
			this.state.value = this.props.value;
		}
	}

	componentDidMount() {
		if (this.props.autoFocus) {
			setTimeout(this.focusInput.bind(this), 5);
		}
	}

	focusInput() {
		document.querySelector("#searchfield" + this.state.myId + " input")?.focus();
	}

	componentWillReceiveProps(props) {
		this.setState({ resource: props.resource });
	}

	update(value) {
		if (this.props.onChange) this.props.onChange(value);
		if (this.props.items) {
			this.setState(
				{
					loading: false,
					value,
					results: [],
					offset: 0,
				},
				this.doSearch.bind(this, 0)
			);
			return;
		}
		if (value.substr(-1) == ",") {
			this.commaEvent();
			return;
		}
		this.cancelRequest();
		clearTimeout(this.state.timeout);
		var newState = { value, loading: true, timeout: setTimeout(this.doSearch.bind(this), 3000) };
		if (!this.state.active) {
			newState.active = true;
		}
		this.setState(newState);
	}

	createCancelToken(c) {
		this.setState({ cancelToken: c });
	}

	doSearch() {
		if (this.props.items) {
			const filterRegex = new RegExp(this.state.value, "i");
			const resultOptions = this.props.items.filter((option) => option.fullname && option.fullname.match(filterRegex));

			this.setState({ results: resultOptions.slice(0, 4) });
			return;
		}
		const CancelToken = axios.CancelToken;
		var query = this.state.value;
		var params = Object.assign({}, this.props.params);
		params.q = query;
		params.offset = 0;
		params.limit = this.state.limit;

		if (this.state.resource == "categories") {
			params.type = "custom";
		}

		if (this.state.resource == "google_address") {
			API.get("/google/addresses.json", {
				cancelToken: new CancelToken(this.createCancelToken.bind(this)),
				params,
			})
				.then((result) => {
					this.setState({ loading: false, results: result.data, cancelToken: null });
				})
				.catch((error) => {
					if (axios.isCancel(error)) {
						console.log("Request canceled");
					} else {
						toastr.error(error);
					}
				});
		} else {
			API.get("/" + this.state.resource + (this.state.resource.indexOf(".json") >= 0 ? "" : ".json"), {
				cancelToken: new CancelToken(this.createCancelToken.bind(this)),
				params,
			})
				.then((result) => {
					var resourceHandle = this.state.resource_handle;
					this.setState({
						loading: false,
						results: this.props.filterResults ? this.props.filterResults(result.data[resourceHandle]) : result.data[resourceHandle],
						cancelToken: null,
					});
				})
				.catch((error) => {
					if (axios.isCancel(error)) {
						console.log("Request canceled");
					} else {
						toastr.error(error);
					}
				});
		}
	}

	cancelRequest() {
		if (this.state.cancelToken) {
			this.state.cancelToken();
			this.setState({ cancelToken: null });
		}
	}

	selectAddress(item) {
		const CancelToken = axios.CancelToken;

		API.get("/api/google/addresses/" + item.id + ".json", {
			cancelToken: new CancelToken(this.createCancelToken.bind(this)),
		})
			.then((result) => {
				this.props.onSelect(result.data);
			})
			.catch((error) => {
				if (axios.isCancel(error)) {
				} else {
					toastr.error(error);
				}
			});
	}

	selectItem(selection) {
		var selectedId = selection[0];
		var item = null;
		if (selectedId === null) {
			item = {};
			item[this.state.id_handle] = null;
			item[this.state.label_handle] = this.state.value;
		} else {
			for (var i = 0; i < this.state.results.length; i++) {
				if (this.state.results[i][this.state.id_handle] == selectedId) {
					item = this.state.results[i];
					break;
				}
			}
		}
		this.setState({
			value: "",
			active: false,
			loading: false,
			results: [],
			selection: [],
		});
		if (this.state.resource == "google_address") {
			this.selectAddress(item);
		} else {
			this.props.onSelect(item);
		}
	}

	blur() {
		/*
      May be buggy!
    */
		if (this.props.onBlur) {
			setTimeout(this.props.onBlur.bind(this), 100);
		}
		setTimeout(this.setState.bind(this, { active: false, loading: false }), 100);
	}

	focus() {
		this.setState({ active: true });
		this.update(this.state.value);
	}

	closePopover() {
		// this.setState({active:!this.state.active});
		/*
    if (this.props.onBlur) {
      this.props.onBlur();
    }
    */
	}

	enterEvent() {
		return;
	}

	commaEvent() {
		return;
	}

	in_results() {
		for (var i = 0; i < this.state.results.length; i++) {
			if (this.state.results[i][this.state.label_handle].toLowerCase() == this.state.value.toLowerCase()) {
				return true;
			}
		}
		return false;
	}

	render() {
		const activator = (
			<Autocomplete.TextField
				label={this.props.label}
				prefix={<Icon source={SearchMajor} color="inkLighter" />}
				autoComplete={false}
				placeholder={this.props.placeholder}
				onFocus={this.focus.bind(this)}
				onBlur={this.blur.bind(this)}
				error={this.props.error}
				type="search"
				value={this.state.value}
				onChange={this.update.bind(this)}
			/>
		);

		var options = [];
		if (this.props.clearOption) {
			options.push({
				value: null,
				label: this.props.clearOption,
			});
		}
		if (this.props.allowCreate || (this.state.tags && this.state.value && !this.in_results())) {
			if (this.state.tags) {
				options.push({
					value: null,
					label: (
						<Stack>
							<Icon source={CirclePlusMajor} /> <div>{trans("searches.add_%tagName%", { "%tagName%": this.state.value })}</div>
						</Stack>
					),
				});
			} else {
				options.push({
					value: null,
					label: (
						<Stack>
							<Icon source={CirclePlusMajor} />{" "}
							<div>{trans("searches.create_new_%resourceName%", { "%resourceName%": this.state.resourceName.singular })}</div>
						</Stack>
					),
				});
			}
		}
		if (this.state.results) {
			for (var i = 0; i < this.state.results.length; i++) {
				if (this.props.renderLabel) {
					options.push({ value: this.state.results[i][this.state.id_handle], label: this.props.renderLabel(this.state.results[i]) });
				} else {
					options.push({
						value: this.state.results[i][this.state.id_handle],
						label: this.state.results[i][this.state.label_handle],
					});
				}
			}
		}

		const emptyState = (
			<React.Fragment>
				<Icon source={SearchMajor} />
				<div style={{ textAlign: "center" }}>
					{/* <TextContainer>{trans("searches.no_%resourceName%_found", { "%resourceName%": this.state.resourceName.plural })}</TextContainer> */}
					<TextContainer>{`Inga ${this.state.resourceName.plural} hittades`}</TextContainer>
				</div>
			</React.Fragment>
		);

		if (this.props.inline) {
			return (
				<div id={"searchfield" + this.state.myId}>
					<Stack vertical spacing="tight">
						{activator}
						{this.state.loading ? <Spinner size="small" color="teal" /> : null}
						<div style={{ marginLeft: -15, marginRight: -15 }}>
							<OptionList options={options} selected={this.state.selected} onChange={this.selectItem.bind(this)} />
						</div>
					</Stack>
					<KeypressListener handler={this.enterEvent.bind(this)} keyCode={13} />
				</div>
			);
		}

		return (
			<div id={"searchfield" + this.state.myId}>
				<Autocomplete
					options={options}
					selected={this.state.selected}
					onSelect={this.selectItem.bind(this)}
					loading={this.state.loading}
					disabled={this.props.disabled}
					emptyState={emptyState}
					textField={activator}
				/>
				<KeypressListener handler={this.enterEvent.bind(this)} keyCode={13} />
			</div>
		);
	}
}

export default SearchField;
