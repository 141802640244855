import { Button, DropZone, Icon, Tooltip } from "@shopify/polaris";
import { AttachmentMajor, SendMajor } from "@shopify/polaris-icons";
import React, { Component } from "react";
import Attachment from "../Attachment";
import styled, { css } from "styled-components";
import Colors, { rgba } from "Colors";

class Footer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			files: [],
		};
	}

	render() {
		return (
			<Wrapper>
				<div style={{ width: 50, height: 50, display: "none" }}>
					<DropZone
						dropOnPage
						openFileDialog={this.state.openFileDialog}
						onFileDialogClose={() => {
							this.setState({ openFileDialog: false });
						}}
						onDrop={(files, acceptedFiles, rejectedFiles) => {
							for (var i = 0; i < acceptedFiles.length; i++) {
								this.props.addFile(acceptedFiles[i]);
							}
						}}
					>
						<DropZone.FileUpload />
					</DropZone>
				</div>
				{this.props.files.length ? (
					<div className="attachments_container">
						{this.props.files.map((file, index) => (
							<Attachment
								editable
								onRemove={(file) => this.props.removeFile(index)}
								onChange={(file) => this.props.updateFile(index, file)}
								key={file.id || index}
								file={file}
							/>
						))}
					</div>
				) : null}
				<div
					className="commentInput-suffix"
					spacing="extraTight"
					style={{ display: "flex", alignItems: "center", gap: "1rem", justifyContent: "space-between", marginTop: "0.5rem" }}
				>
					<div className="suffixes_container">
						<Tooltip preferredPosition="above" content="Ladda upp fil">
							<Button
								plain
								size="slim"
								onClick={() => {
									this.setState({ openFileDialog: true });
								}}
							>
								<Icon color="skyDark" source={AttachmentMajor} />
							</Button>
						</Tooltip>
					</div>

					<div style={{ display: "flex", gap: "1rem" }}>
						{this.props.onClose && <Button onClick={this.props.onClose}>Avbryt</Button>}
						<SButton onClick={this.props.handleSubmit} loading={String(this.state.saving || this.props.loading)} data-disabled={this.props.disabled}>
							<SButtonText> {this.state.id ? "Spara" : "Skicka"} </SButtonText> <Icon source={SendMajor} />
						</SButton>
					</div>
				</div>
			</Wrapper>
		);
	}
}
export default Footer;

const Wrapper = styled.div``;
const SButtonText = styled.div`
	transition: width 500ms;
`;
const SButton = styled.button`
	background-color: ${rgba(Colors.accent, 0.85)};
	color: #ffffff;
	border: none;
	font-weight: 700;
	display: flex;
	padding: 0.6rem 1.5rem;
	align-items: center;
	justify-content: center;
	border-radius: 6px;
	opacity: 1;
	transition: background-color 250ms, opacity 250ms;

	.Polaris-Icon {
		margin-left: 3px;
		svg {
			fill: #ffffff;
		}
	}

	${(props) => {
		if (props["data-disabled"]) {
			return css`
				background-color: ${Colors.secondary};
				pointer-events: none;
				opacity: 0.5;
			`;
		}
	}}
`;
