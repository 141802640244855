import { Button, Icon } from "@shopify/polaris";
import React, { useMemo, useState } from "react";
import { toastr } from "components/toastr";
import { store } from "store/index";
import { MobileCancelMajor } from "@shopify/polaris-icons";
import ProfileAvatar from "components/ProfileAvatar";
import { Link, useNavigate, useParams } from "react-router-dom";
import API from "API";
import Popover from "components/Popover";
import { useSelector } from "react-redux";
import { setLocalStorage } from "util/index";
import styled from "styled-components";
import Colors from "Colors";
import TypingDots from "components/TypingDots";
import MessageUserPopover from "./MessageUserPopover";
import ContextMenu from "components/ContextMenu";
import { ChatIcon, NotificationOffIcon, NotificationOnIcon } from "icons";
import ChatSettings from "./ChatSettings";

const ChatsListItem = ({ chat, user }) => {
	const id = useParams()?.id;
	const navigate = useNavigate();
	const [loading, setLoading] = useState();
	const [confirmOpen, setConfirmOpen] = useState();
	const [contextConfirmLeave, setContextConfirmLeave] = useState();
	const [settingsTab, setSettingsTab] = useState({ open: false, id: "general" });
	const typing_users = useSelector((state) =>
		state.users_typing?.[chat?.id]?.filter((user_id) => String(user_id) !== String(store.getState().user.id))
	);
	const unreadCount = useSelector((state) => state?.chats_message_count?.[chat?.id]);

	const onCloseSettings = (e) => {
		console.log("e:", e);
		setSettingsTab({ open: false, id: "general" });
	};

	const onOpenSettings = () => {
		setSettingsTab({ open: true, id: "general" });
	};

	const leaveChat = (e) => {
		e.preventDefault();
		e.stopPropagation();
		setLoading(true);
		store.dispatch({ type: "REMOVE_CHAT", chat });
		setLocalStorage("lastChatId", null);
		setConfirmOpen(false);

		API.post("/chats/" + chat?.id + "/leave.json", {})
			.then((result) => {
				setLoading(false);
				setConfirmOpen(false);
				setLocalStorage("lastChatId", null);
				if (result.data.chat) store.dispatch({ type: "REMOVE_CHAT", chat: result.data.chat });
				navigate("chat");
			})
			.catch((error) => {
				toastr.error(error);
			});
	};

	const mute = () => {
		chat.me_as_member.mute = true;
		store.dispatch({
			type: "UPDATE_MEMBER",
			member: chat.me_as_member,
		});

		API.post("/chats/" + chat.id + "/mute.json", {})
			.then((result) => {
				// store.dispatch({ type: "UPDATE_CHAT", chat: result.data.chat });
			})
			.catch((error) => {
				toastr.error(error);
			});
	};

	const unmute = () => {
		chat.me_as_member.mute = false;
		store.dispatch({
			type: "UPDATE_MEMBER",
			member: chat.me_as_member,
		});

		API.post("/chats/" + chat.id + "/unmute.json", {})
			.then((result) => {
				// store.dispatch({ type: "UPDATE_CHAT", chat: result.data.chat });
			})
			.catch((error) => {
				toastr.error(error);
			});
	};

	const toggleAskToLeaveChat = (e) => {
		e?.preventDefault?.();
		e?.stopPropagation?.();
		setConfirmOpen((c) => !c);
	};
	const closeAskToLeaveChat = (e) => {
		e?.preventDefault?.();
		e?.stopPropagation?.();
		setConfirmOpen(false);
	};

	const otherMember = useMemo(() => {
		return chat?.group ? null : chat?.members?.find?.(({ user } = {}) => String(user.id) !== String(store.getState().user?.id));
	}, [chat]);

	const getUser = () => {
		if (user) return user;
		if (!chat?.group && (otherMember?.user || chat?.members?.[0]?.user)) {
			return store.getState().users.find((u) => String(u.id) === String((otherMember?.user || chat?.members?.[0]?.user)?.id));
		}

		return null;
	};

	const title = user?.name || user?.email || (chat?.group ? chat?.title : getUser()?.name);

	return (
		<>
			<ContextMenu
				onClose={() => {
					setContextConfirmLeave(false);
				}}
				items={[
					{
						content: chat.group ? `Öppna kanal instälningar` : "Öppna konverstation inställningar",
						icon: <ChatIcon />,
						onAction: onOpenSettings,
					},
					chat?.me_as_member?.mute
						? {
								content: "Slå på alla notiser",
								icon: <NotificationOnIcon />,
								onAction: unmute,
						  }
						: {
								content: "Stäng av alla notiser",
								icon: <NotificationOffIcon />,
								onAction: mute,
						  },
					{
						content: chat.group ? `Lämna kanal` : "Stäng konverstation",
						icon: <Icon source={MobileCancelMajor} />,
						onAction: chat.group
							? (e) => {
									e?.preventDefault?.();
									e?.stopPropagation?.();
									setContextConfirmLeave((c) => !c);
							  }
							: leaveChat,
						destructive: true,
						items: contextConfirmLeave && [
							{
								content: (
									<div className="confirm-leave-chat">
										<span>Vill du verkligen lämna "{title}" kanalen?</span>
										<div className="buttons-wrapper">
											<Button onClick={leaveChat} destructive loading={loading}>
												Lämna kanal
											</Button>
											<Button
												onClick={() => {
													setContextConfirmLeave(false);
												}}
											>
												Stäng
											</Button>
										</div>
									</div>
								),
							},
						],
					},
				]}
				activator={
					<StyledWrapper
						className={`${unreadCount ? " unread" : ""}`}
						data-typing={true || typing_users?.length}
						to={
							user
								? `/${store.getState().account.id}/chat/${store.getState().user?.id}-${user?.id}`
								: `/${store.getState().account.id}/chat/${chat?.id}`
						}
						muted={chat?.me_as_member?.mute}
						data-muted={String(chat?.me_as_member?.mute)}
						data-active={String(String(id) === String(chat?.id))}
					>
						<div
							type="button"
							className={`chat-list-item ${chat?.group ? "group" : ""} ${id && String(id) === String(chat?.id) ? "active" : ""}${
								loading ? " leaving" : ""
							}${unreadCount ? " unread" : ""}`}
							data-unreadcount={unreadCount > 1000 ? `${1000}+` : unreadCount}
						>
							{typing_users?.length ? (
								<TypingDots style={{ marginRight: "1rem", borderRadius: "20px", background: Colors.tertiary }} />
							) : (
								<MessageUserPopover
									user={getUser()}
									chat={chat}
									activator={<ProfileAvatar user={getUser()} initials={chat?.group ? "#" : null} tooltip={false} />}
								/>
							)}

							<h1 className={`name`}>
								{chat?.group && "#"}
								{title}
							</h1>

							{chat?.group ? (
								<Popover
									activator={
										<span title="Lämna chat" className={`leave-chat-button`}>
											<Button onClick={toggleAskToLeaveChat} plain icon={MobileCancelMajor} />
										</span>
									}
									active={confirmOpen}
									onClose={closeAskToLeaveChat}
								>
									<div className="confirm-leave-chat">
										<span>Vill du verkligen lämna "{title}" kanalen?</span>
										<div className="buttons-wrapper">
											<Button onClick={leaveChat} destructive loading={loading}>
												Lämna kanal
											</Button>
											<Button onClick={closeAskToLeaveChat}>Stäng</Button>
										</div>
									</div>
								</Popover>
							) : (
								<span title="Stäng konverstation" className={`leave-chat-button`}>
									<Button onClick={leaveChat} plain icon={MobileCancelMajor} />
								</span>
							)}
						</div>
					</StyledWrapper>
				}
			></ContextMenu>
			<ChatSettings open={settingsTab?.open} settingsTabId={settingsTab?.id} chat_id={chat.id} onClose={onCloseSettings} />
		</>
	);
};
export default ChatsListItem;

const StyledWrapper = styled(Link)`
	&&&& {
		min-height: 36.5px;

		.Polaris-Avatar {
			path {
				fill: ${Colors.textColor};
				opacity: 0.5;
			}
		}
		&[data-typing] {
			.Polaris-Avatar {
				background: ${Colors.tertiary};

				text {
					color: ${Colors.textColor};
				}
			}
		}

		&[data-muted="true"]:not([data-active="true"]) {
			.name {
				opacity: 0.5;
			}
		}
	}
`;
