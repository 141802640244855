import { ActionList, Avatar, Frame, Select, TopBar } from "@shopify/polaris";
import { CustomersMajor, LogOutMinor, SearchMajor } from "@shopify/polaris-icons";
import { debounce } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import API from "API";
import ProfileAvatar from "components/ProfileAvatar";
import { store } from "store/index";
import { setLocalStorage } from "util/index";
import Popover from "components/Popover";
import { StyledSelectWrapper } from "./styledComponents";
import { SocketIOIcon } from "icons";
import { useSelector } from "react-redux";
import SocketLogModal from "./SocketLogModal";
import Badge from "components/Badge";
import Colors from "Colors";
import { useContext } from "react";
import ChatContext from "contexts/ChatContext";

const Toolbar = () => {
	const [searchParams] = useSearchParams();
	const [query, setQuery] = useState(searchParams.get("q") || "");
	const [options, setOptions] = useState([]);
	const [searchOpen, setSearchOpen] = useState(false);
	const [userMenuOpen, setUserMenuOpen] = useState(false);
	const [socketLogIsOpen, setSocketLogIsOpen] = useState(false);
	const navigate = useNavigate();
	const storeSocket = useSelector((state) => state.socket);
	const { socket } = useContext(ChatContext);

	const updateSearch = (value) => {
		setQuery(value);
		setSearchOpen(true);

		if (value === "") {
			setOptions([]);
			return;
		}

		search(value);
	};

	const search = useMemo(
		() =>
			debounce(
				(q) => {
					API.get(`/chat/search.json`, {
						params: {
							q,
						},
					})
						.then((result) => {
							const users = result.data.users?.map((user) => ({
								content: <p>{user.name}</p>,
								icon: () => <ProfileAvatar user={user} size={16} />,
								onAction: () => {
									console.log("onAction:");
									navigate(`/${store.getState().account.id}/chat/${store.getState().user.id}-${user.id}`);
								},
							}));

							const chats = result.data.chats?.map((chat) => {
								const pcs = chat.title.split(" ");

								return {
									content: <p>{chat.title}</p>,
									icon: () => (
										<Avatar
											source={""}
											name={chat.title || ""}
											initials={`${pcs?.at(0)?.charAt?.(0)}${pcs?.at?.(-1)?.charAt?.(0)}`}
											size={"medium"}
										/>
									),
									onAction: () => {
										console.log("onAction:");
										navigate(`/${store.getState().account.id}/chat/${chat.id}`);
									},
								};
							});

							setOptions([
								{ title: "Chattar", items: chats },
								{ title: "Användare", items: users },
							]);
							setSearchOpen(true);
						})
						.catch((error) => {
							console.log("error:", error);
						});
				},
				1000,
				{ maxWait: 5000 }
			),
		[navigate]
	);

	const logout = () => {
		navigate("/logout");
	};

	useEffect(() => {
		const input = document.querySelector(".Polaris-TopBar-SearchField__Input");

		const onKeyDown = (e) => {
			if (e.key === "Enter") {
				navigate(
					`/${store.getState().account.id}/search?` +
						new URLSearchParams({
							q: query,
						})
				);
				search.cancel();
				setSearchOpen(false);
				input?.blur?.();
			}
		};

		if (input) {
			input.addEventListener("keydown", onKeyDown);
		}

		return () => {
			input?.removeEventListener?.("keydown", onKeyDown);
		};
	}, [query, navigate, search]);

	useEffect(() => {
		const onKeyDown = (e) => {
			if (e.key === "f" && (e.metaKey || e.ctrlKey)) {
				e.preventDefault();
				e.stopPropagation();
				const input = document.querySelector(".Polaris-TopBar-SearchField__Input");
				input?.focus();
			}
		};
		document.addEventListener("keydown", onKeyDown);

		return () => {
			document.removeEventListener("keydown", onKeyDown);
		};
	}, []);

	// useEffect(() => {
	// 	console.log("useEffect:");
	// 	const topbar = document.querySelector("#AppFrameTopBar");
	// 	const handleDoubleClick = () => {
	// 		console.log("handleDoubleClick:");
	// 		const doubleClickAction = remote.systemPreferences.getUserDefault("AppleActionOnDoubleClick", "string");
	// 		const win = remote.getCurrentWindow();
	// 		if (doubleClickAction === "Minimize") {
	// 			win.minimize();
	// 		} else if (doubleClickAction === "Maximize") {
	// 			if (!win.isMaximized()) {
	// 				win.maximize();
	// 			} else {
	// 				win.unmaximize();
	// 			}
	// 		}
	// 	};

	// 	console.log("window?.electron?.platform:", window?.electron?.platform);
	// 	console.log("topbar:", topbar);
	// 	if (topbar && window?.electron?.platform === "darwin") {
	// 		topbar.addEventListener("dblclick", handleDoubleClick);
	// 	}

	// 	return () => {
	// 		if (topbar) {
	// 			topbar.removeEventListener("dblclick", handleDoubleClick);
	// 		}
	// 	};
	// }, []);

	// eslint-disable-next-line no-unused-vars
	const userMenuActions = [
		{
			items: [
				{
					content: (
						<Select
							disabled={store.getState().accounts?.length <= 1}
							value={String(store.getState().account.id)}
							options={store.getState().accounts.map((acc) => {
								return {
									label: acc.company,
									value: String(acc.id),
								};
							})}
							onChange={(accountId) => {
								navigate(`/${accountId}/auth?acc_id=${accountId}`);
								setLocalStorage("accountId", accountId);
							}}
						/>
					),
				},
				{ content: "Logga ut", icon: LogOutMinor, onAction: logout },
			],
		},
	];

	const userMenuMarkup = (
		<Popover
			open={userMenuOpen}
			onClose={() => {
				setUserMenuOpen(false);
			}}
			activator={
				<div className="Polaris-TopBar-Menu__ActivatorWrapper">
					<ProfileAvatar
						className="Polaris-TopBar-Menu__Activator"
						onClick={() => {
							setUserMenuOpen((c) => !c);
						}}
						user={store.getState().user}
					/>
				</div>
			}
		>
			<ActionList
				items={[
					{
						content: (
							<StyledSelectWrapper>
								<Select
									disabled={store.getState().accounts?.length <= 1}
									value={String(store.getState().account.id)}
									options={store.getState().accounts.map((acc) => {
										return {
											label: acc.company,
											value: String(acc.id),
										};
									})}
									onChange={(accountId) => {
										navigate(`/${accountId}/auth?acc_id=${accountId}`);
										setLocalStorage("accountId", accountId);
									}}
								/>
							</StyledSelectWrapper>
						),
						icon: store.getState().accounts?.length <= 1 ? CustomersMajor : null,
					},
					{ content: "Logga ut", icon: LogOutMinor, onAction: logout },
					{
						content: (
							<p>
								Socket:{" "}
								<Badge fontSize={12} color={storeSocket.connected ? Colors.green : Colors.red}>
									{storeSocket.connected ? "connected" : "disconnected"}
								</Badge>
								{socket.connected !== storeSocket.connected && (
									<Badge fontSize={12} color={socket.connected ? Colors.green : Colors.red}>
										{socket.connected ? "connected" : "disconnected"}
									</Badge>
								)}
							</p>
						),
						icon: SocketIOIcon,
						enabled: [2, 419, 3, 16, 418, 494, 1837, 339].includes(store.getState().user.id),
						onAction: () => {
							setSocketLogIsOpen(true);
						},
					},
				].filter((i) => !("enabled" in i) || i.enabled)}
			/>
		</Popover>
	);

	const searchFieldMarkup = (
		<TopBar.SearchField
			onFocus={() => {
				if (query) {
					setSearchOpen(true);
				}
			}}
			onChange={updateSearch}
			value={query}
			placeholder={`Sök i ${store.getState().account.company}`}
		/>
	);
	const getSearchResultsMarkup = () => {
		// const empty = options.every(({ items }) => !items.length);
		// if (empty) return <StyledEmptyText>Inga resultat..</StyledEmptyText>;

		return (
			<ActionList
				onActionAnyItem={() => {
					setSearchOpen(false);
				}}
				sections={[
					{
						title: "",
						items: [
							{
								content: `${query ? `${query} - ` : ""}Sök bland meddelanden, filer och mer`,
								icon: () => <SearchMajor />,
								onAction: () => {
									search.cancel();
									navigate(
										`/${store.getState().account.id}/search?` +
											new URLSearchParams({
												q: query,
											})
									);
								},
							},
						],
					},
					...options,
				]}
			/>
		);
	};

	return (
		<Frame
			topBar={
				<TopBar
					searchResultsVisible={searchOpen}
					userMenu={userMenuMarkup}
					searchResults={getSearchResultsMarkup()}
					onSearchResultsDismiss={() => {
						setSearchOpen(false);
					}}
					searchField={searchFieldMarkup}
				></TopBar>
			}
		>
			<SocketLogModal open={socketLogIsOpen} onClose={() => setSocketLogIsOpen(false)} />
		</Frame>
	);
};
export default Toolbar;
