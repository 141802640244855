/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */
import { store } from "./store/index.js";

function trans(key, replace) {
	var found = store.getState().terms?.find?.((item) => item.handle == key);
	if (found) {
		return replaceTrans(found.title, replace);
	}
	var found = key.split(".").reduce(getTrans, store.getState().translation);
	if (found !== null) {
		return replaceTrans(found, replace);
	}
	return replaceTrans(key, replace);
}
function replaceTrans(trans, replace) {
	if (typeof replace == "object") {
		for (var i in replace) {
			trans = trans.replace(i, replace[i]);
		}
	}
	return trans;
}
function getTrans(obj, key) {
	if (!obj) {
		return null;
	}
	if (key in obj) {
		return obj[key];
	}
	return null;
}
function getTerm(obj, key) {
	if (!obj) {
		return null;
	}
	if (key in obj) {
		return obj[key];
	}
	return null;
}

export default trans;
