const users_typing = (state = {}, action) => {
	switch (action.type) {
		case "ADD_TYPING":
			if (state[action.chat_id]) {
				state[action.chat_id] = [...new Set([...state[action.chat_id], action.user_id])];
			} else {
				state[action.chat_id] = [action.user_id];
			}
			return { ...state };
		case "REMOVE_TYPING":
			state[action.chat_id] = [...(state[action.chat_id]?.filter((id) => String(id) !== String(action.user_id)) || [])];
			return { ...state };
		case "SET_TYPING":
			return action.users;
		default:
			return state;
	}
};
export default users_typing;
