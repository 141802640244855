import React from "react";
import styled from "styled-components";
import Colors, { rgba } from "../Colors";

/**
 * @param {children} [children] - Children
 * @param {String} [color] - color
 * @param {Number} [alpha] - alpha
 * @param {Number} [fontSize] - alpha
 * @param {Object} [style] - style
 */
const Badge = ({ children, textColor, color = Colors.yellow, alpha, fontSize, style, round, className }) => {
	return (
		<StyledWrapper
			className={`number number_badge ${className}`}
			style={style}
			textColor={textColor}
			color={color}
			alpha={alpha}
			fontSize={fontSize}
			round={round}
		>
			{children}
		</StyledWrapper>
	);
};

export default Badge;

const StyledWrapper = styled.div`
	padding: 0.15rem 1rem;
	border-radius: 0.3em;
	// display: flex;
	align-items: center;
	justify-items: center;
	transition: all 250ms;
	font-weight: 600;
	background-color: ${rgba(Colors.yellow, 0.3)};
	width: max-content;
	display: inline-flex;

	border-radius: ${({ round }) => (round ? 20 : 0)}px;
	font-size: ${({ fontSize }) => fontSize || "0.75em"};
	background-color: ${({ backgroundColor, color, alpha }) => (backgroundColor ? backgroundColor : rgba(color || Colors.yellow, alpha || 0.3))};
	color: ${({ color, textColor }) => textColor || color || Colors.yellow};

	&.hover {
		&:hover {
			filter: brightness(1.2);
		}
	}

	// &:hover {
	// 	// filter: brightness(1.25);
	// 	// -webkit-filter: brightness(1.25);
	// 	font-weight: 600;
	// }
`;
