import { Button, Icon, Tooltip } from "@shopify/polaris";
import { ChevronDownMinor } from "@shopify/polaris-icons";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import ProfileAvatar from "components/ProfileAvatar";
import { store } from "store/index";
import ChatSettings from "./ChatSettings";
import { NotificationOffIcon, NotificationOnIcon } from "icons";
import MessageUserPopover from "./MessageUserPopover";
import PinnedMessages from "./PinnedMessages";

const ChatWindowHeader = ({ chat_id, disabled }) => {
	const [tab, setTab] = useState({ open: false, id: "general" });
	const chat = useSelector((state) => state.chats.find((ch) => String(ch.id) === String(chat_id)));

	const openInviteModal = () => {
		setTab({ open: true, id: "members" });
	};

	const openChatSettings = () => {
		setTab({ open: true, id: "general" });
	};

	const onClose = () => {
		setTab({ open: false, id: "general" });
	};

	const otherMember = chat.group
		? null
		: chat && chat.members && chat.members.find(({ user } = {}) => String(user.id) !== String(store.getState().user?.id));

	const getUser = () => {
		if (chat?.group) return null;
		return store.getState().users.find((u) => String(u.id) === String((otherMember?.user || chat?.members?.[0]?.user)?.id));
	};

	const title = chat.group ? chat.title : otherMember?.user?.name || chat?.me_as_member.user?.name || "...";

	return (
		<StyledWrapper className="ChatWindowHeader">
			<StyledInnerWrapper className="StyledInnerWrapper">
				<Tooltip content={chat?.grupp ? "Kanal inställningar" : "Chat inställningar"}>
					<StyledChatTitleWrapper onClick={openChatSettings} disabled={disabled}>
						<MessageUserPopover
							user={getUser()}
							chat={chat}
							activator={<ProfileAvatar size={30} initials={(chat?.group && "#") || ""} user={getUser()} tooltip={false} />}
						/>

						<StyledTitle>{title}</StyledTitle>
						{!disabled && <Icon source={ChevronDownMinor} />}
						<StyledNotificationIcon>
							<Icon source={chat?.me_as_member?.mute ? NotificationOffIcon : NotificationOnIcon} />
						</StyledNotificationIcon>
					</StyledChatTitleWrapper>
				</Tooltip>
				<PinnedMessages chat={chat} />
			</StyledInnerWrapper>

			{chat.group && (
				<MembersWrapper onClick={openInviteModal}>
					<StyledMembersStack>
						{chat?.members
							?.slice(0, 20)
							?.filter((mem) => String(mem?.user?.id) !== String(store.getState().user?.id) && mem?.user?.enabled)
							?.sort((a, b) => (a?.user?.name || a?.user?.email)?.localeCompare(b?.user?.name || b?.user?.email))
							?.map((mem, index, arr) => {
								return (
									<StyledProfileAvatar key={mem.id} index={arr.length - index}>
										<ProfileAvatar user={mem?.user} />
									</StyledProfileAvatar>
								);
							})}
						{chat?.members?.length > 20 && (
							<EmptyAvatar>
								<Button plain>
									<ProfileAvatar initials={`+${chat?.members?.slice(20)?.length}`} />
								</Button>
							</EmptyAvatar>
						)}
					</StyledMembersStack>

					{!disabled && (chat.me_as_member?.admin || chat.me_as_member?.creator) && (
						<EmptyAvatar>
							<Button plain>
								<ProfileAvatar initials="+" />
							</Button>
						</EmptyAvatar>
					)}
				</MembersWrapper>
			)}

			<ChatSettings open={tab?.open} tabId={tab?.id} chat_id={chat_id} onClose={onClose} />
		</StyledWrapper>
	);
};
export default ChatWindowHeader;

const StyledProfileAvatar = styled.div`
	&:not(:last-child) {
		transform: ${({ index }) => `translateX(${25 * index}%);`}
		z-index: ${({ index }) => index};
	}
`;

const MembersWrapper = styled.div`
	display: flex;
	gap: 1rem;
	margin-right: 2rem;
	cursor: pointer;

	${StyledProfileAvatar},
	.Polaris-avatar {
		cursor: pointer;
	}

	.Polaris-Button--plain {
		padding: 0;
	}
`;

const EmptyAvatar = styled.div`
	.Polaris-Avatar {
		background-color: rgba(255, 255, 255, 0.1);

		text {
			color: rgba(255, 255, 255, 0.5);
		}
	}
`;

export const StyledMembersListTitle = styled.h2`
	font-weight: 400;
	font-size: 1.2rem;
	padding: 2rem 0;
`;

export const StyledMembersList = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
`;

const StyledWrapper = styled.div`
	width: 100%;
	background: $primary;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;

	box-shadow: 0px 10px 25px #00000069;
	z-index: 1;
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);

	/* &::after {
		content: "";
		position: absolute;
		bottom: 0;
		left: 0px;
		right: 0px;
		height: 1px;
		background-color: rgba(255, 255, 255, 0.1);
	} */
`;

export const StyledInnerWrapper = styled.div`
	display: flex;
	gap: 1rem;
	align-items: center;
	justify-content: space-between;

	.Polaris-Avatar {
		background-color: rgba(255, 255, 255, 0.1);

		text {
			color: rgba(255, 255, 255, 0.5);
		}
	}

	.remove_user {
		transition: opacity 250ms;
		opacity: 0;
	}

	&:hover .remove_user {
		opacity: 1;
	}
`;

const StyledTitle = styled.h1`
	font-size: 16px;
	font-weight: 500;
`;

const StyledMembersStack = styled.div`
	display: flex;
`;

const StyledChatTitleWrapper = styled.div`
	display: flex;
	gap: 1rem;
	align-items: center;
	cursor: ${({ disabled }) => (disabled ? "unset" : "pointer")};
	pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
	padding: 0.35rem 1rem;
	transition: background 250ms;
	border-radius: 6px;

	.Polaris-Icon svg {
		fill: var(--textColor);
	}

	&:hover {
		background: var(--secondary);
	}
`;

const StyledNotificationIcon = styled.div`
	.Polaris-Icon {
		height: 1.5rem;
		width: 1.5rem;
	}
`;
