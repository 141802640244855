const chats_message_count = (state = {}, action) => {
	switch (action.type) {
		case "GET_CHAT_MESSAGES_COUNT": {
			return state[action.chat.id];
		}
		case "GET_MESSAGES_COUNTS": {
			return state;
		}
		case "SET_MESSAGES_COUNTS": {
			return { ...action.counts };
		}
		default:
			return state;
	}
};
export default chats_message_count;
