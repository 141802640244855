/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getLocalstorage, setLocalStorage } from "util/index";
import { store } from "store/index";
import { toastr } from "components/toastr";
import API, { BASE_URL, setPromise } from "API";
import WelcomeScreen from "views/chat/WelcomeScreen";
import { getTokenTTL } from "components/API/utils";
import axios from "axios";

const AuthenticationScreen = () => {
	const location = useLocation();
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const params = useParams();

	const fetchInitialStates = async () => {
		API.get("/initial_states.json", { params: { light: true } })
			.then((result) => {
				if (result.data.accounts) store.dispatch({ type: "SET_ACCOUNTS", accounts: result.data.accounts });
				if (result.data.users) store.dispatch({ type: "SET_USERS", users: result.data.users });
				if (result.data.groups) store.dispatch({ type: "SET_GROUPS", groups: result.data.groups });
			})
			.catch((error) => {
				console.log("error:", error);
				toastr.error(error);
			});
	};

	const authenticate = useCallback(async () => {
		console.log("authenticate:");
		console.log("location.search:", location.search);
		console.log("window.location.href:", window.location.href);
		setLoading(true);
		const urlParams = new URLSearchParams(location.search || location?.state?.search);
		const refreshToken = urlParams.get("refresh_token")?.replaceAll('"', "") || store.getState().refresh_token || getLocalstorage("refresh_token");
		// const token = urlParams.get("token")?.replaceAll('"', "") || getLocalstorage("jwt_token");

		const accountId = urlParams.get("acc_id")?.replaceAll('"', "") || params?.["accountId"] || getLocalstorage("accountId");
		const redirectUrl =
			location?.state?.pathname === "/auth"
				? `/${accountId}/chat`
				: (location?.state?.pathname || `/${accountId}/chat`) + `${urlParams ? `?${urlParams.toString()}` : ""}`;

		console.log("refreshToken:", refreshToken);
		if (refreshToken) store.dispatch({ type: "SET_REFRESH_TOKEN", refresh_token: refreshToken });
		if (!refreshToken) {
			navigate("/login", { state: { url: redirectUrl } });
			return;
		}
		// const tokens = await axios
		// 	.post(
		// 		`${BASE_URL}/token/refresh_token`,
		// 		{ refresh_token: refreshToken, acc_id: accountId },
		// 		{
		// 			skipToken: true,
		// 		}
		// 	)
		const data = {
			refresh_token: refreshToken,
		};
		if (accountId) data.acc_id = accountId;
		const tokens = await API.post("/token/refresh_token", data, {
			skipToken: true,
		})
			.then((result) => {
				console.log("result:", result);
				if (result.data.error) {
					toastr.error(result.data.error);
					navigate("/login");
					return;
				}

				store.dispatch({ type: "SET_TOKEN", token: result.data.token });
				store.dispatch({ type: "SET_REFRESH_TOKEN", refresh_token: result.data.refresh_token });

				setLocalStorage("refresh_token", result.data.refresh_token);
				setLocalStorage("jwt_token", result.data.token);

				setPromise({
					request: result,
					ttl: getTokenTTL(result.data.token),
				});
				return result.data;
			})
			.catch((e) => {
				console.error("e:", e);
				navigate("/login", { state: { url: redirectUrl } });
			});

		if (tokens) {
			await Promise.all([await API.get("/me.json").then((r) => r.data.user), await API.get("/account.json").then((r) => r.data)])
				.then((res) => {
					const [myUser, myAccount] = res;
					fetchInitialStates();

					if (myUser) {
						store.dispatch({ type: "SET_USER", user: myUser });
						// eslint-disable-next-line no-undef
						user = myUser;
					}

					if (myAccount) {
						store.dispatch({ type: "SET_ACCOUNT", account: myAccount });
					}

					if (myAccount.id) {
						navigate(location?.state?.url || `/${myAccount.id}/chat`);
					}

					setLoading(false);
				})
				.catch(() => {
					navigate("/login", { state: { url: redirectUrl } });
				});
		}

		setLoading(false);
	}, [navigate, location?.state?.pathname, location?.state?.search, location?.search, params, location?.state?.url]);

	useEffect(() => {
		if (!loading) authenticate();
	}, [authenticate, loading]);

	return <WelcomeScreen loading={loading} />;
};

export default AuthenticationScreen;
