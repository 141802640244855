import { Icon, Modal, Tooltip } from "@shopify/polaris";
import { CancelMajor } from "@shopify/polaris-icons";
import API from "API";
import Colors from "Colors";
import { toastr } from "components/toastr";
import moment from "moment";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { store } from "store";
import styled from "styled-components";
import MessageUserPopover from "./MessageUserPopover";
import ProfileAvatar from "components/ProfileAvatar";

const PinnedMessageSmall = ({ message, loading }) => {
	const [confirmModalIsOpen, setConfirmModalIsOpen] = useState();
	const navigate = useNavigate();

	const user = store.getState().users.find((usr) => String(usr.id) === String(message.user_id));

	const unpin = (e) => {
		e?.preventDefault?.();
		e?.stopPropagation?.();

		message.pinned = false;
		store.dispatch({ type: "UPDATE_CHAT_MESSAGE", message: { ...message } });
		setConfirmModalIsOpen(false);

		API.put(`/chats/${message.chat_id}/messages/${message.id}/pin.json`, message)
			.then((result) => {
				store.dispatch({ type: "UPDATE_CHAT_MESSAGE", message: result.data.message });
			})
			.catch((error) => {
				toastr.error(error);
			});
	};

	const navigateToUserChat = () => {
		const userChatId = store
			.getState()
			?.chats.find((ch) => !ch.group && ch?.members.find((mem) => String(mem.user?.id) === String(message?.user_id)))?.id;
		const tempUserChatId = `${store.getState().user.id}-${user?.id}`;

		navigate(`/${store.getState().account.id}/chat/${userChatId || tempUserChatId}`);
	};

	const navigateToMessage = () => {
		navigate(
			`/${store.getState().account.id}/chat/${message.chat_id}?` +
				new URLSearchParams({
					message_id: message.id,
				})
		);
	};

	const messageElement = (
		<StyledOuterWrapper onClick={navigateToMessage}>
			<StyledWrapper>
				<StyledHeader>
					<StyledProfileWrapper>
						{loading ? <StyledLoadingProfile /> : <ProfileAvatar size="small" user={user} />}
						<MessageUserPopover user={user} activator={<StyledTitle onClick={navigateToUserChat}>{user?.name || user?.email}</StyledTitle>} />

						<Tooltip content={moment(message.created_at).format("YYYY-MM-DD HH:mm:ss")}>
							<StyledDate>{moment(message.created_at).locale("sv").format("YYYY-MM-DD HH:mm")}</StyledDate>
						</Tooltip>
					</StyledProfileWrapper>
					{!confirmModalIsOpen && (
						<StyledPlainButton onClick={() => setConfirmModalIsOpen(true)}>
							<Icon source={CancelMajor} />
						</StyledPlainButton>
					)}
				</StyledHeader>
				{message.content}
			</StyledWrapper>
		</StyledOuterWrapper>
	);

	return (
		<>
			{messageElement}
			<Modal
				open={confirmModalIsOpen}
				title="Lossa meddelande"
				secondaryActions={[
					{
						content: "Avbryt",
						onAction: (e) => {
							e.preventDefault();
							e.stopPropagation();
							setConfirmModalIsOpen(false);
						},
					},
				]}
				primaryAction={{ content: "Lossa meddelandet", onAction: unpin }}
				onClose={(e) => {
					e?.preventDefault?.();
					e?.stopPropagation?.();
					setConfirmModalIsOpen(false);
				}}
			>
				<Modal.Section>
					<p style={{ marginBottom: "0.5rem" }}>Säker du vill lossa detta meddelandet?</p>
					{messageElement}
				</Modal.Section>
			</Modal>
		</>
	);
};
export default PinnedMessageSmall;

const StyledOuterWrapper = styled.div`
	padding: 0.375rem;
	cursor: pointer;
`;
const StyledWrapper = styled.div`
	/* margin: 0.75rem; */
	background-color: ${Colors.primary};
	padding: 0.75rem;
	width: 100%;
	border-radius: 0.35rem;
	border: 1px solid ${Colors.secondary};
`;

const StyledHeader = styled.div`
	display: flex;
	justify-content: space-between;

	margin-bottom: 0.35rem;
`;

const StyledProfileWrapper = styled.div`
	display: flex;
	gap: 0.5rem;
	align-items: center;

	button.Profile-Avatar {
		padding: 0;
	}
`;

const StyledDate = styled.span`
	opacity: 0.5;
	font-size: 12px;
`;
const StyledTitle = styled.span`
	font-size: 13px;
	font-weight: 700;
	line-height: 16px;

	color: var(--textColor);
	text-decoration: none;
`;

const StyledPlainButton = styled.button`
	background: none;
	border: none;
	cursor: pointer;
	opacity: 0.5;
	transition: opacity 250ms;

	svg {
		fill: #ffffff;
	}

	&:hover {
		opacity: 1;
	}
`;

const StyledLoadingProfile = styled.div`
	border-radius: 13px;
	width: 26px;
	height: 26px;
`;
