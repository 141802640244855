const addNotification = ({ title, icon, body, onClick }) => {
	const notification = new Notification(title, {
		icon: icon || `${process.env.PUBLIC_URL}/sellfinity/sellfinity-main-menu-c.png`,
		badge: icon || `${process.env.PUBLIC_URL}/sellfinity/sellfinity-main-menu-c.png`,
		body,
	});

	notification.onclick = function () {
		// e.preventDefault();

		if (onClick) {
			onClick();
			return;
		}

		window?.moveTop?.();
		window.focus();
		// window.open(`http://localhost:3000/chat/${data.chat_id}`, `sellfinity_chat_${data.chat_id}`);
	};
};

const showNotification = ({ title, icon, body, onClick }) => {
	const permission = Notification.permission;
	// if (!chat?.muted && !document.hasFocus()) {

	if (permission === "granted") {
		addNotification({ title, icon, body, onClick });
	} else if (permission === "default") {
		Notification.requestPermission(function (permission) {
			if (permission === "granted") {
				addNotification({ title, icon, body, onClick });
			}
		});
	}
};

export default showNotification;
