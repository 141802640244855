/* eslint-disable eqeqeq */
/* eslint-disable no-redeclare */
import { toastr as t } from "react-redux-toastr";

export class toastr {
	static success(message) {
		t.success("Klar", message, { disableCloseButtonFocus: true });
	}
	static message(message) {
		t.light("Meddelande", message, { disableCloseButtonFocus: true });
	}
	static warning(message) {
		t.warning("Varning", message, { disableCloseButtonFocus: true });
	}
	static error(object) {
		if (typeof object == "object") {
			if ("response" in object && object.response) {
				if (object.response.status == 401 || object.response.status == 403) {
					window.location.href = "/login";
					return;
				} else {
					if ("message" in object.response.data) {
						var message = object.response.data.message;
					} else if ("error" in object.response.data) {
						if (typeof object.response.data.error == "object") {
							if ("message" in object.response.data.error) {
								var message = object.response.data.error.message;
							}
						} else {
							var message = object.response.data.error;
						}
					}

					if (object.response.status == 302) {
						//This doesnt work anymore because suddenly axios/browser follow redirects
						window.location.href = message;
						return;
					}
				}
			} else {
				console.log("No response or response is null in error", object);
			}
		} else {
			var message = object;
		}
		t.error("Något blev fel!", message, { disableCloseButtonFocus: true });
	}
}
