import React, { useState } from "react";
import { useEffect } from "react";
import styled from "styled-components";

const VersionInfo = () => {
	const [info, setInfo] = useState();

	const getInfo = async () => {
		const name = await window.electron?.getName?.();
		const version = await window.electron?.getVersion?.();

		return { name, version };
	};

	useEffect(() => {
		(async () => {
			if (window.electron) {
				const info = await getInfo();
				setInfo(info);
			}
		})();
	}, []);

	return (
		<Wrapper>
			{info?.version && (
				<p>
					Version:{" "}
					<b>
						{info?.name} - {info?.version}
					</b>
				</p>
			)}
			{process.env.REACT_APP_VERSION && (
				<p>
					Version (Web):{" "}
					<b>
						{process.env.REACT_APP_NAME} - {process.env.REACT_APP_VERSION}
					</b>
				</p>
			)}
		</Wrapper>
	);
};
export default VersionInfo;

const Wrapper = styled.div`
	color: #ffffff80;
	font-size: 0.85rem;
`;
