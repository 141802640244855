import { Button, FormLayout, ChoiceList, TextField, Modal } from "@shopify/polaris";
import React, { useEffect, useState } from "react";
import SearchField from "components/search_field";
import { toastr } from "components/toastr";
import { store } from "store/index";
import { useNavigate } from "react-router-dom";
import API from "API";
import { StyledMembersList } from "./ChatWindowHeader";
import ChatMemberListItem from "components/ChatMemberListItem";

const NewChatModal = ({ type, onClose, style, open }) => {
	const navigate = useNavigate();
	const [users, setUsers] = useState([]);
	const [title, setTitle] = useState("");
	const [loading, setLoading] = useState();
	const [access, setAccess] = useState("open");

	useEffect(() => {
		if (open) {
		} else {
			setTitle("");
			setUsers([]);
			setAccess("private");
			setLoading(false);
		}
	}, [open]);

	const toggleUser = (user) => {
		if (type === "direct") {
			createChatRoom([user]);
			return;
		}

		var curIndex = users.findIndex((usr) => String(usr.id) === String(user.id));
		if (curIndex >= 0) {
			removeUser(curIndex);
		} else {
			if (type !== "group") {
				setUsers([]);
				setTitle(user.name);
			}
			addUser(user);
		}
	};

	const addUser = (user) => {
		setUsers((c) => [...c, user]);
	};

	const removeUser = (index) => {
		setUsers((c) => {
			c.splice(index, 1);
			return [...c];
		});
	};

	const createChatRoom = (usr = users) => {
		setLoading(true);

		API.post("/chats.json", {
			users: usr,
			group: type === "group",
			type: access,
			title: title,
		})
			.then((result) => {
				if (result.data.info) {
					toastr.message(result.data.info);
				} else {
					toastr.success("Skapade kanal " + result.data.chat.title);
				}
				setLoading(false);

				store.dispatch({ type: "ADD_CHAT", chat: result.data.chat });
				navigate(`/${store.getState().account.id}/chat/${result.data.chat?.id}`);
				onClose();
			})
			.catch((error) => {
				setLoading(false);

				toastr.error(error);
			});
	};

	return (
		<Modal title={`${type === "group" ? "Ny kanal" : "Lägg till medarbetare"}`} open={open} onClose={onClose}>
			<Modal.Section>
				<div className="newchat-wrapper" style={style}>
					<FormLayout>
						{type === "group" ? (
							<ChoiceList
								title="Åtkomst"
								choices={[
									{ label: "Privat (bara inbjudna kan se)", value: "private" },
									{ label: "Öppen för alla", value: "open" },
								]}
								selected={[access]}
								onChange={(v) => setAccess(v[0])}
							/>
						) : null}
						{type === "group" ? (
							<TextField
								label="Rubrik"
								value={title}
								onChange={(v) => {
									setTitle(v);
								}}
								autoFocus
								placeholder="Rubrik.."
							/>
						) : null}

						<SearchField
							placeholder="Sök efter användare att lägga till"
							resourceName={{
								singular: "användare",
								plural: "användare",
							}}
							resource="users"
							label_handle="name"
							onSelect={toggleUser}
							autoFocus={type !== "group"}
							renderLabel={(user) => {
								return <ChatMemberListItem fontSize={"13px"} key={user?.id} member={{ user }} />;
							}}
						/>

						{/* {users.map((user = {}, index) => (
							<Tag key={user.id || index} onRemove={() => removeUser(user)}>
								{user.name}
							</Tag>
						))} */}
						<StyledMembersList>
							{users.map((user, index) => {
								return <ChatMemberListItem key={user.id} showAll={false} member={{ user }} onRemove={() => removeUser(index)} />;
							})}
						</StyledMembersList>

						{type === "group" && (
							<Button primary loading={loading} disabled={!title || (access !== "open" && users.length < 0)} onClick={() => createChatRoom(users)}>
								Skapa
							</Button>
						)}
					</FormLayout>
				</div>
			</Modal.Section>
		</Modal>
	);
};
export default NewChatModal;
