import React from "react";

const ChatIcon = ({ color = "var(--textColor)" }) => (
	<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M14.1901 11.1831C14.7401 11.1831 15.1901 10.7331 15.1901 10.1831V1.18311C15.1901 0.633105 14.7401 0.183105 14.1901 0.183105H1.19006C0.640063 0.183105 0.190063 0.633105 0.190063 1.18311V15.1831L4.19006 11.1831H14.1901ZM2.19006 10.3531V2.18311H13.1901V9.1831H3.36006L2.19006 10.3531ZM20.1901 5.1831C20.1901 4.6331 19.7401 4.1831 19.1901 4.1831H17.1901V13.1831H4.19006V15.1831C4.19006 15.7331 4.64006 16.1831 5.19006 16.1831H16.1901L20.1901 20.1831V5.1831Z"
			fill={color}
		/>
	</svg>
);

export default ChatIcon;
