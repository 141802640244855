import moment from "moment";
import { Buffer } from "buffer";

export const RemoveCookie = (cookieName) => {
	document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; SameSite=Lax`;
	document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};

export const AddCookie = (cookieName, value, options = {}) => {
	const optionsEntries = Object.entries(options);
	const cookie = `${cookieName}=${value}; ${optionsEntries?.map((i) => `${i[0]}=${i[1]}`)?.join("; ")}${
		optionsEntries && optionsEntries.length ? "; " : ""
	}path=${"path" in options ? options.path : "/"}; SameSite=${"sameSite" in options ? options.sameSite : "Lax"}`;
	document.cookie = cookie;
};

export const getCookie = (cname) => {
	var name = cname + "=";
	var decodedCookie = decodeURIComponent(document.cookie);
	var ca = decodedCookie.split(";");
	for (var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) === " ") {
			c = c.substring(1);
		}
		if (c.indexOf(name) === 0) {
			if (c.substring(name.length, c.length) === "null") {
				return null;
			} else if (c.substring(name.length, c.length) === "false") {
				return false;
			} else if (c.substring(name.length, c.length) === "true") {
				return true;
			}

			return c.substring(name.length, c.length);
		}
	}
	return null;
};

export const getLocalstorage = (name) => {
	const item = localStorage.getItem(name);
	try {
		const itemParsed = JSON.parse(item);

		if (itemParsed === "null") return null;
		if (itemParsed === "NaN") return NaN;
		if (itemParsed === "undefined") return undefined;
		return itemParsed;
	} catch (error) {
		if (item === "null") return null;
		if (item === "NaN") return NaN;
		if (item === "undefined") return undefined;
		return item;
	}
};
export const getLocalStorage = getLocalstorage;

export const setLocalStorage = (name, data) => {
	try {
		localStorage.setItem(name, typeof data === "string" ? data : JSON.stringify(data));
		return data;
	} catch (error) {
		console.warn(`setLocalStorage - ${name}: `, error);
	}
};

export const removeLocalStorage = (name) => {
	localStorage.removeItem(name);
};

export const truncate = (input, max) => {
	if (input && input.length > max) return input.substring(0, max) + "..";
	return input;
};

export const chunk = (array, size) => {
	if (array?.length <= size) return [array];
	const chunked_arr = [];
	let index = 0;
	while (index < array.length) {
		chunked_arr.push(array.slice(index, size + index));
		index += size;
	}
	return chunked_arr;
};

export const convertArrayToObject = (array, key) => {
	const initialValue = {};
	return array.reduce((obj, item) => {
		return {
			...obj,
			[item[key]]: item,
		};
	}, initialValue);
};

export const askForBrowserNotificationPermission = async () => {
	if (Notification && Notification.permission === "default") {
		const result = await Notification.requestPermission();
		console.log("Notifications: ", result);
		return result;
	}
};

// export const msToHMS = (ms) => {
//   const duration = moment.duration(ms);
//   const hours = duration.hours() ? `${duration.hours()}h` : '';
//   const minutes = duration.minutes() || duration.minutes() ? ` ${duration.minutes()}m` : '';
//   const seconds = duration.seconds() ? ` ${duration.seconds()}s` : '';
//   const HMS = `${hours}${minutes}${seconds}`;
//   return HMS;
// };

export const msToHMS = (ms) => {
	if (!ms) return "-";
	const duration = moment.duration(ms);

	const hm = duration
		.format("h[h] m[m] s[s]")
		//removes suffixes if there are 0 or nothing
		.replace(" h", "h")
		.replace(" m", "m")
		.replace(" 0m", "")
		.replace(" 0s", "");
	if (!hm) return duration.format("s [s]");
	return hm;
	// console.log('duration:', duration);
	// const days = duration.hours() ? `${duration.hours()}h` : '';
	// const hours = duration.hours() ? `${duration.hours()}h` : '';
	// console.log('hours:', hours);
	// const minutes = duration.minutes() || duration.minutes() ? ` ${duration.minutes()}m` : '';
	// console.log('minutes:', minutes);
	// const seconds = duration.seconds() ? ` ${duration.seconds()}s` : '';
	// console.log('seconds:', seconds);
	// const HMS = `${hours}${minutes}${seconds}`;
	// return HMS;
};

export const getUniqueListByNoMerge = (arr, key, alternativeKey) => {
	return [...new Map(arr.map((item) => [item[key] || (alternativeKey && item[alternativeKey]), item])).values()];
};

export const getUniqueListBy = (arr, key) => {
	if (!arr) return [];
	return arr.reduce((acc, item) => {
		const foundIndex = acc.findIndex((i) => i[key] === item[key]);
		if (foundIndex >= 0 && item) {
			acc[foundIndex] = { ...acc[foundIndex], ...item };
		} else {
			acc.push({ ...item });
		}
		return acc;
	}, []);
};

export const isInViewport = (element) => {
	const rect = element.getBoundingClientRect();
	return (
		rect.top >= 0 &&
		rect.left >= 0 &&
		rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
		rect.right <= (window.innerWidth || document.documentElement.clientWidth)
	);
};

export const isInParentView = (element, parent) => {
	const rect = element?.getBoundingClientRect() || {};
	const parentRect = parent?.getBoundingClientRect() || {};

	return rect.top <= parentRect?.top && rect.left >= parentRect?.left && rect.bottom >= parentRect?.bottom && rect.right <= parentRect?.right;
};

export const isImageFromUrl = (url) => {
	return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
};

export const isImage = (type) => {
	if (type?.includes?.("image/")) return true;
	return /\/(jpg|jpeg|png|webp|avif|gif|svg)$/.test(type);
};

export const decodeTokenUser = (token) => {
	if (!token || !token?.split(".")?.[1]) return null;
	const tokenData = JSON.parse(Buffer.from(token?.split(".")[1], "base64").toString());
	return tokenData;
};
export const getSessionStorage = (name) => {
	const item = sessionStorage.getItem(name);
	try {
		const itemParsed = JSON.parse(item);

		if (itemParsed === "null") return null;
		if (itemParsed === "NaN") return NaN;
		if (itemParsed === "undefined") return undefined;
		return itemParsed;
	} catch (error) {
		if (item === "null") return null;
		if (item === "NaN") return NaN;
		if (item === "undefined") return undefined;
		return item;
	}
};
export const setSessionStorage = (name, data) => {
	try {
		sessionStorage.setItem(name, typeof finallValue === "string" ? data : JSON.stringify(data));
		return data;
	} catch (error) {
		console.warn(`setSessionStorage - ${name}: `, error);
	}
};
