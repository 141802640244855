import React, { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { Tabs } from "@shopify/polaris";
import SearchContent from "./SearchContent";
import API from "API";
import Badge from "components/Badge";
import Colors from "Colors";
import { store } from "store/index";

const SearchIndex = () => {
	const [searchParams] = useSearchParams();

	const [loading, setLoading] = useState();
	const [tabIndex, setTabIndex] = useState(0);
	const [messageData, setMessageData] = useState({});
	const [fileData, setFileData] = useState({});
	const [userData, setUserData] = useState({});
	const [chatData, setChatData] = useState({});
	const tabs = [
		{
			content: "Meddelanden",
			id: "messages",
			accessibilityLabel: "messages",
			panelID: "messages",
		},
		{
			content: "Filer",
			id: "files",
			accessibilityLabel: "files",
			panelID: "files",
		},
		{
			content: "Kanaler",
			id: "chats",
			accessibilityLabel: "chats",
			panelID: "chats",
		},
		{
			content: "Användare",
			id: "users",
			accessibilityLabel: "users",
			panelID: "users",
		},
	];

	const onChangeTab = (index) => {
		setTabIndex(index);
	};

	const fetchMessages = useCallback(async () => {
		searchParams.set("limit", 5);
		// return await API.get("/messages.json", { params: { q: searchParams.get("q") } }).then((res) => res.data?.messages);
		return await API.get("/messages.json?" + searchParams).then((res) => res.data);
	}, [searchParams]);

	const fetchFiles = useCallback(async () => {
		searchParams.set("limit", 50);
		// return await API.get("/chat/files.json", { params: { q: searchParams.get("q") } }).then((res) => res.data?.files);
		return await API.get("/files.json?" + searchParams).then((res) => res.data);
	}, [searchParams]);

	const fetchUsers = useCallback(async () => {
		searchParams.set("limit", 50);
		// return await API.get("/chat/files.json", { params: { q: searchParams.get("q") } }).then((res) => res.data?.files);
		return await API.get("/users.json?" + searchParams).then((res) => res.data);
	}, [searchParams]);

	const fetchChats = useCallback(async () => {
		searchParams.set("limit", 50);
		// return await API.get("/chat/files.json", { params: { q: searchParams.get("q") } }).then((res) => res.data?.files);
		return await API.get("/chats.json?" + searchParams).then((res) => res.data);
	}, [searchParams]);

	useEffect(() => {
		(async () => {
			setLoading(true);
			const messageData = await fetchMessages();
			const fileData = await fetchFiles();
			const userData = await fetchUsers();
			const chatData = await fetchChats();

			store.dispatch({ type: "ADD_MESSAGES", messages: messageData?.messages || [] });

			setMessageData(messageData);
			setFileData(fileData);
			setUserData(userData);
			setChatData(chatData);
			setLoading(false);
		})();
	}, [fetchMessages, fetchFiles, fetchUsers, fetchChats]);

	const getCount = (id) => {
		switch (id) {
			case "messages":
				return messageData?.count;

			case "files":
				return fileData?.count;

			case "chats":
				return chatData?.count;

			case "users":
				return userData?.count;

			default:
				return null;
		}
	};
	return (
		<Wrapper>
			<Header>
				<Tabs
					tabs={tabs.map((tab) => ({
						...tab,
						content: (
							<div style={{ display: "flex", alignItems: "center" }}>
								{tab.content}
								{typeof getCount(tab.id) === "number" && (
									<span style={{ marginLeft: 5 }}>
										<Badge color={Colors.lightblue} round>
											{getCount(tab.id)}
										</Badge>
									</span>
								)}
							</div>
						),
					}))}
					selected={tabIndex}
					onSelect={onChangeTab}
				/>
			</Header>
			<SearchContent
				loading={loading}
				params={[...searchParams.entries()].reduce((acc, [key, value]) => {
					return { ...acc, [key]: value };
				}, {})}
				tabId={tabs[tabIndex]?.id}
				fileData={fileData}
				messageData={messageData}
				userData={userData}
				chatData={chatData}
			/>
		</Wrapper>
	);
};
export default SearchIndex;

const Wrapper = styled.div`
	/* flex: 1; */
	/* background-color: red; */
	grid-column: 2;
	grid-row: 2/5;
	overflow: auto;
`;
const Header = styled.div.attrs({ className: "search-header" })``;
