import React, { useState } from "react";
import { useParams } from "react-router-dom";
import ChatMemberListItem from "components/ChatMemberListItem";
import SearchField from "components/search_field";
import { store } from "store/index";
import styled from "styled-components";
import { toastr } from "components/toastr";
import API from "API";
import { useSelector } from "react-redux";

const Members = () => {
	const id = useParams()?.id;
	const [search, setSearch] = useState();
	const chat = useSelector((state) => state.chats.find((chat) => String(chat.id) === String(id)));

	const inviteUser = (user) => {
		store.dispatch({ type: "UPDATE_CHAT", chat: { ...chat, members: [...chat.members, { user }] } });

		API.post("/chats/" + chat.id + "/members.json", { user })
			.then((result) => {
				store.dispatch({ type: "UPDATE_CHAT", chat: { ...result.data.chat } });
			})
			.catch((error) => {
				toastr.error(error);
			});
	};

	const regexp = new RegExp(search, "i");
	return (
		<>
			<SearchField
				placeholder="Sök efter användare"
				resourceName={{
					singular: "användare",
					plural: "användare",
				}}
				params={{ enabled: 1 }}
				resource="users"
				label_handle="name"
				onSelect={(user) => inviteUser(user)}
				onChange={(search) => setSearch(search)}
				filterResults={(users) => {
					const ids = chat?.members.map((mem) => mem.user.id);
					return users.filter((user) => !ids.includes(user.id));
				}}
				renderLabel={(user) => {
					return <ChatMemberListItem fontSize={"13px"} key={user?.id} member={{ user }} />;
				}}
			/>

			<StyledMembersListTitle>Kanalmedlemmar</StyledMembersListTitle>
			<StyledMembersList>
				{chat?.members
					?.filter(
						(mem) =>
							String(mem?.user?.id) !== String(store.getState().user?.id) &&
							(!search || regexp.test(mem?.user?.name) || regexp.test(mem?.user?.id) || regexp.test(mem?.user?.email)) &&
							mem?.user?.enabled
					)
					.sort((a, b) => (a?.user?.name || a?.user?.email)?.localeCompare(b?.user?.name || b?.user?.email))
					.map((mem) => (
						<ChatMemberListItem chat={chat} key={mem.id || mem?.user?.id} member={mem} />
					))}
			</StyledMembersList>
		</>
	);
};
export default Members;
export const StyledMembersListTitle = styled.h2`
	font-weight: 400;
	font-size: 1.2rem;
	padding: 2rem 0;
`;

export const StyledMembersList = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
`;
