import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { io } from "socket.io-client";
import API from "../API";
import { toastr } from "../components/toastr";
import showNotification from "../showNotification";
import { store } from "store/index";
import { useSelector } from "react-redux";
import moment from "moment";

const ChatContext = React.createContext();

export const ChatProvider = ({ children }) => {
	// const socket = useMemo(() => io("http://localhost:4551"), []);
	const socket = useMemo(() => io("https://chatsocket.sellfinity.com"), []);
	const messagesTimer = useRef();
	const timeout = useRef();
	const [loading, setLoading] = useState();
	const messages = useSelector((state) => state.chat_messages);
	const chats = useSelector((state) => state?.chats);

	const fetchMessages = useCallback((n) => {
		API.get("/messages.json", { params: {} })
			.then((result) => {
				if (result.data.messages.length) {
					store.dispatch({
						type: "ADD_MESSAGES",
						messages: result.data.messages,
					});
				}
			})
			.catch((error) => toastr.error(error));
	}, []);

	useEffect(() => {
		const timer = messagesTimer.current;
		return () => {
			clearTimeout(timer);
			clearTimeout(timeout.current);
		};
	}, []);

	const fetchChats = useCallback(
		(doFetchMessages) => {
			clearTimeout(timeout.current);
			timeout.current = null;
			setLoading(true);
			API.get("/chats.json")
				.then((result) => {
					store.dispatch({
						type: "SET_CHATS",
						chats: result.data.chats.map((chat) => {
							if (chat.group) return chat;

							const title = (() => {
								if (chat?.members?.length === 1 && String(chat?.members[0]?.user?.id) === String(store.getState().user.id)) {
									return "(Yourself)";
								}

								const otherMember = chat.members?.find((mem) => String(mem.user?.id) !== String(store.getState().user.id));

								return otherMember?.user?.name || otherMember?.user?.email || otherMember?.user?.phone || "Unknown";
							})();

							return { ...chat, title };
						}),
					});

					if (doFetchMessages) fetchMessages(false);
				})
				.catch((error) => toastr.error(error))
				.finally(() => {
					setLoading(false);
				});
		},
		[fetchMessages]
	);

	useEffect(() => {
		if (store.getState().user?.id) fetchChats(true);
	}, [fetchChats]);

	const onTypingStart = (chat_id) => {
		socket.emit("user_typing", {
			user_id: store.getState().user.id,
			chat_id,
			typing: true,
		});
	};

	const onTypingCancel = (chat_id) => {
		socket.emit("user_typing", {
			user_id: store.getState().user.id,
			chat_id,
			typing: false,
		});
	};

	useEffect(() => {
		const onConnect = () => {
			console.log("onConnect");
			socket.emit("auth", { user_id: store.getState().user?.id, token: store.getState().token, acc_id: store.getState().account?.id });
			store.dispatch({ type: "SET_SOCKET_CONNECTED", connected: true });
			store.dispatch({
				type: "ADD_SOCKET_LOG",
				log: {
					title: "Connected",
					type: "connect",
					created_at: moment().format("YYYY-MM-DD HH:mm"),
				},
			});
		};

		const onReconnect = (attempt) => {
			console.log("reconnect:");
			console.log("attempt:", attempt);

			store.dispatch({
				type: "ADD_SOCKET_LOG",
				log: {
					title: `reconnect ${attempt}`,
					type: "reconnect",
					created_at: moment().format("YYYY-MM-DD HH:mm"),
				},
			});
			// ...
		};

		const onReconnectAttempt = (attempt) => {
			console.log("reconnect_attempt:");
			console.log("attempt:", attempt);

			store.dispatch({
				type: "ADD_SOCKET_LOG",
				log: {
					title: `reconnect attempt ${attempt}`,
					type: "reconnect_attempt",
					created_at: moment().format("YYYY-MM-DD HH:mm"),
				},
			});
			// ...
		};

		const onConnectionError = (error) => {
			console.log("reconnect_error:");
			console.log("error:", error.message);

			store.dispatch({
				type: "ADD_SOCKET_LOG",
				log: {
					title: "reconnect error",
					type: "error",
					created_at: moment().format("YYYY-MM-DD HH:mm"),
					error,
				},
			});
			// ...
		};

		const onReconnectFailed = () => {
			console.log("reconnect_failed:");

			store.dispatch({
				type: "ADD_SOCKET_LOG",
				log: {
					title: "Connected",
					type: "error",
					created_at: moment().format("YYYY-MM-DD HH:mm"),
				},
			});
			// ...
		};

		const onConnectError = () => {
			setTimeout(() => {
				socket.connect();
			}, 1000);
			store.dispatch({
				type: "ADD_SOCKET_LOG",
				log: {
					title: "An connection error",
					type: "error",
					created_at: moment().format("YYYY-MM-DD HH:mm"),
				},
			});
			store.dispatch({ type: "SET_SOCKET_CONNECTED", connected: false });
		};

		const onConnectTimeout = function (err) {
			console.log("connect_timeout:");
			console.log("err:", err);

			store.dispatch({
				type: "ADD_SOCKET_LOG",
				log: {
					title: "Connection timeout",
					type: "error",
					created_at: moment().format("YYYY-MM-DD HH:mm"),
				},
			});
		};

		const onDisconnect = (reason) => {
			console.log("Socket disconnect, reason:", reason);
			store.dispatch({ type: "SET_SOCKET_CONNECTED", connected: false });
			store.dispatch({
				type: "ADD_SOCKET_LOG",
				log: {
					title: "Disconnected",
					type: "disconnect",
					created_at: moment().format("YYYY-MM-DD HH:mm"),
					text: reason,
				},
			});
		};
		const onJoin = ({ chat, user }) => {
			// console.log("join:");
			// console.table({ chat, user });
			if (String(user?.id) === String(store.getState().user.id)) {
				store.dispatch({
					type: "ADD_CHAT",
					chat: chat,
				});
			}

			if (!store.getState().users.find((usr) => String(usr.id) !== String(user.id))) {
				store.dispatch({
					type: "ADD_USER",
					user,
				});
			}
		};

		const onLeave = ({ user, chat }) => {
			if (String(user.id) === String(store.getState().user.id)) {
				store.dispatch({
					type: "REMOVE_CHAT",
					chat: { id: chat.id },
				});
			}
		};

		const onDeleteMessage = ({ message }) => {
			store.dispatch({
				type: "REMOVE_CHAT_MESSAGE",
				message: message,
			});
		};

		const onEditMessage = ({ message }) => {
			store.dispatch({
				type: "UPDATE_CHAT_MESSAGE",
				message: message,
			});
		};

		const onUpdateMember = ({ member }) => {
			console.log("update_member member:", member);

			store.dispatch({
				type: "UPDATE_MEMBER",
				member: member,
			});
		};

		const onUserTyping = ({ user_id, chat_id, typing }) => {
			if (typing) {
				store.dispatch({ type: "ADD_TYPING", user_id: user_id, chat_id: chat_id });
			} else {
				store.dispatch({ type: "REMOVE_TYPING", user_id: user_id, chat_id: chat_id });
			}
		};

		const onOline = ({ onlineUsers }) => {
			if (onlineUsers?.includes(store.getState().user.id)) {
				store.dispatch({ type: "UPDATE_ME", user: { online: true } });
			}

			if (onlineUsers) store.dispatch({ type: "SET_ONLINE_USERS", userIds: onlineUsers });
		};

		const onOffline = ({ user }) => {
			if (user.id === store.getState().user.id) {
				store.dispatch({ type: "UPDATE_ME", user: { ...(user || {}), online: false } });
			}
			console.log("user offline:", user);
			store.dispatch({ type: "UPDATE_USER", user: { ...(user || {}), online: false } });
		};

		const onError = (errro) => {
			console.log("onError:");
			console.log("errro:", errro);
		};

		const onMessage = ({ ref, message }) => {
			const user = store.getState().users.find((usr) => String(usr.id) === String(message.user_id));
			const chat = store.getState().chats.find((chat) => String(chat.id) === String(message.chat_id));

			store.dispatch({
				type: "ADD_CHAT_MESSAGE",
				chat_id: message.chat_id,
				message: message,
				ref,
			});

			if (chat) {
				store.dispatch({
					type: "UPDATE_CHAT",
					chat: { ...(chat || {}), last_message: message },
				});
			}

			// document.hasFocus() - window have focus
			// document.visibilityState - tab is not visible (another tab is open)
			// document.hidden - window is minimized

			if (!chat?.me_as_member?.mute && !document.hasFocus() && String(message.user_id) !== String(store.getState().user.id)) {
				window.electron?.flash?.(true);
				showNotification({
					title: `${user?.name}${chat?.group ? ` (#${chat?.title})` : ""}`,
					icon: user?.avatar,
					body: message.content,
					onClick: function () {
						// 	window.open(`http://localhost:3000/chat/${data.chat_id}`, "asd");

						window?.moveTop?.();
						window?.focus?.();
					},
				});
			}
		};

		const permission = Notification.permission;
		if (permission === "default") {
			Notification.requestPermission();
		}

		socket.on("connect", onConnect);
		socket.io.on("reconnect", onReconnect);
		socket.io.on("reconnect_attempt", onReconnectAttempt);
		socket.io.on("reconnect_error", onConnectionError);
		socket.io.on("reconnect_failed", onReconnectFailed);
		socket.io.on("connect_error", onConnectError);
		socket.io.on("connect_error", onError);
		socket.io.on("connect_timeout", onConnectTimeout);

		socket.on("disconnect", onDisconnect);
		socket.on("join", onJoin);
		socket.on("leave", onLeave);
		socket.on("delete_message", onDeleteMessage);
		socket.on("edit_message", onEditMessage);
		socket.on("update_member", onUpdateMember);

		socket.on("user_typing", onUserTyping);
		socket.on("online", onOline);
		socket.on("offline", onOffline);
		socket.on("message", onMessage);

		return () => {
			socket.off("connect", onConnect);

			socket.io.off("reconnect", onReconnect);
			socket.io.off("reconnect_attempt", onReconnectAttempt);
			socket.io.off("reconnect_error", onConnectionError);
			socket.io.off("reconnect_failed", onReconnectFailed);
			socket.io.off("connect_error", onConnectError);
			socket.io.off("connect_error", onError);
			socket.io.off("connect_timeout", onConnectTimeout);

			socket.off("disconnect", onDisconnect);
			socket.off("join", onJoin);
			socket.off("leave", onLeave);
			socket.off("delete_message", onDeleteMessage);
			socket.off("edit_message", onEditMessage);
			socket.off("update_member", onUpdateMember);

			socket.off("user_typing", onUserTyping);
			socket.off("online", onOline);
			socket.off("offline", onOffline);
			socket.off("message", onMessage);
		};
	}, [socket]);

	useEffect(() => {
		const counts = Object.entries(messages || {})?.reduce((acc, [chatId, messages]) => {
			const chat = chats.find((ch) => String(ch.id) === String(chatId));
			return {
				...acc,
				[chatId]:
					messages?.filter(
						(message) => String(message.id) > (chat?.me_as_member?.last_read_id || 0) && String(message.user_id) !== String(store.getState().user.id)
					)?.length || 0,
			};
		}, {});

		const total = Object.entries(counts)?.reduce((acc, [chatId, count]) => {
			const chat = chats.find((ch) => String(ch.id) === String(chatId));
			if (!chat?.me_as_member?.mute) {
				return acc + count;
			}

			return acc;
		}, 0);

		window.electron?.setBadgeCount?.(total);
		store.dispatch({ type: "SET_MESSAGES_COUNTS", counts });
	}, [messages, chats]);

	return <ChatContext.Provider value={{ onTypingStart, onTypingCancel, loading, socket }}>{children}</ChatContext.Provider>;
};

export default ChatContext;
