import { Button } from "@shopify/polaris";
import API from "API";
import Colors from "Colors";
import Popover from "components/Popover";
import React, { useState } from "react";
import { Virtuoso } from "react-virtuoso";
import { store } from "store";
import styled from "styled-components";
import LoadingPinnedMessageSmall from "./LoadingPinnedMessageSmall";
import PinnedMessageSmall from "./PinnedMessageSmall";

const PinnedMessages = ({ chat }) => {
	const [open, setOpen] = useState();
	const [loading, setLoading] = useState();
	const [messages, setMessages] = useState([]);
	const controller = new AbortController();

	const onToggle = () => {
		setOpen((c) => {
			if (!c) {
				setMessages([]);
				fetchPinnedMessages();
			} else {
				controller.abort();
			}
			return !c;
		});
	};
	const onClose = () => {
		setOpen(false);
		controller.abort();
	};

	const fetchPinnedMessages = async () => {
		setLoading(true);
		const res = await API.get(`/chats/${chat.id}/messages.json`, { params: { pinned: 1, chat_id: chat.id }, signal: controller.signal }).catch(
			(error) => {
				console.log("error:", error);
			}
		);
		if (res?.data?.messages) {
			store.dispatch({ type: "ADD_MESSAGES", messages: res.data.messages });
			setMessages(res.data.messages);
		}
		setLoading(false);
	};

	const renderMessage = (index, message) => {
		if (!message.id && !message.ref) return <LoadingPinnedMessageSmall />;
		return <PinnedMessageSmall message={message} />;
	};

	return (
		<Popover
			preventCloseOnChildOverlayClick
			style={{ padding: "0.75rem", maxWidth: "unset", maxHeight: "unset" }}
			activator={
				<StyledButtonWrapper>
					<Button onClick={onToggle} ariaExpanded={open} disclosure={open ? "down" : "up"} outline={false}>
						Fästa meddelanden
					</Button>
				</StyledButtonWrapper>
			}
			onClose={onClose}
			active={open}
		>
			<Virtuoso
				// atTopThreshold={0}
				style={{ flex: 1, height: !messages?.length ? 350 : document.querySelector(".messages")?.clientHeight || 400, width: 350 }}
				increaseViewportBy={{ top: 200, bottom: 200 }}
				data={loading ? [...Array(2)].map((_, key) => ({ key })) : messages?.filter(({ pinned }) => pinned)}
				computeItemKey={(_, message) => {
					return message.key || message.id || message.ref || message.position;
				}}
				itemContent={renderMessage}
				components={{
					EmptyPlaceholder: () => {
						return (
							<div style={{ display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "center", height: "100%" }}>
								<p>Inga fästa meddelanden</p>
								<img alt="" style={{ width: "75%" }} src={`${process.env.PUBLIC_URL}/images/NoResults.png`} />
							</div>
						);
					},
				}}
			/>
		</Popover>
	);
};
export default PinnedMessages;

const StyledButtonWrapper = styled.div`
	button {
		border: none;
		background: ${Colors.secondary};
	}
`;
