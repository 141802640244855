import React from "react";
import { Popover as PolarisPopover } from "@shopify/polaris";
// import { isMobile } from "react-device-detect";
import styled from "styled-components";
import { useEffect } from "react";
import { useRef } from "react";

const Popover = ({ children, active, open, onClose, style, dark = true, ...rest }) => {
	const ref = useRef();

	useEffect(() => {
		if ((open || active) && style && ref?.current) {
			const ele = ref.current?.closest(".Polaris-Popover__Content");

			if (ele) {
				Object.entries(style)?.forEach(([key, value]) => {
					ele.style[key] = value;
				});
			}
		}
	}, [open, active, style]);

	return (
		<PolarisPopover active={active || open} onClose={onClose} {...rest}>
			<StyledWrapper ref={ref} style={style} dark={dark}>
				{children}
				{/* {(isMobile || true) && (
					<button
						style={{
							paddingTop: "15px",
							paddingBottom: "15px",
							marginTop: "1rem",
							width: "100%",
							background: "transparent",
							borderLeft: "none",
							borderRight: "none",
							fontSize: "1em",
							cursor: "pointer",
							color: "var(--textColor)",
						}}
						onClick={(e) => {
							e.stopPropagation();
							if (onClose) onClose();
						}}
					>
						Stäng
					</button>
				)} */}
			</StyledWrapper>
		</PolarisPopover>
	);
};
export default Popover;

const StyledWrapper = styled.div.attrs({ className: "popover_content_wrapper" })`
	background: ${({ dark }) => (dark ? "var(--tertiary)" : "transparent")};
	color: ${({ dark }) => (dark ? "var(--textColor)" : "rgb(240,240,240)")};
	padding: 1rem;
`;
