import { Icon, Collapsible } from "@shopify/polaris";
import React, { useState } from "react";
import { store } from "store/index";
import { CirclePlusMajor, MobileChevronMajor } from "@shopify/polaris-icons";
import { useSelector } from "react-redux";
import NewChatModal from "./NewChatModal";
import ChatsListItem from "./ChatsListItem";
import ProfileAvatar from "components/ProfileAvatar";
import Settings from "../settings/Settings";

const ChatsList = ({ loading }) => {
	const [newChat, setNewChat] = useState({});
	// eslint-disable-next-line no-unused-vars

	// const scrollbarVisible = (element) => {
	// 	if (!element) return null;
	// 	return element.scrollHeight > element.clientHeight;
	// };

	const openNewChatModal = (type) => {
		setNewChat({ open: true, type });
		// this.setState({ newChatModalIsOpen: true, newChatType });
	};
	const closeNewChatModal = () => {
		setNewChat({ open: false, type: null });
	};

	return (
		<div className={`chats-list`}>
			<div className="notifications-list noscroll">
				<List loading={loading} title={"Kanaler"} openNewChatModal={() => openNewChatModal("group")} group />
				<List loading={loading} title={"Meddelanden"} openNewChatModal={() => openNewChatModal("direct")} />
				<NewChatModal type={newChat?.type} open={newChat?.open} onClose={closeNewChatModal} />
			</div>
			<Settings />
		</div>
	);
};

export default ChatsList;

const List = ({ title, openNewChatModal, loading, group }) => {
	const [open, setOpen] = useState(true);
	const chats = useSelector((state) => state.chats.filter((chat) => (group ? chat?.group : !chat?.group)));

	const toggleOpen = () => {
		setOpen((c) => !c);
	};

	const items = group
		? chats
		: chats.filter((chat) => {
				return chat?.group
					? true
					: chat.members?.length === 1 ||
							chat?.members?.filter((mem) => {
								return String(mem?.user?.id) !== String(store.getState().user?.id) && mem?.user?.enabled;
							})?.length;
		  });

	return (
		<React.Fragment>
			<div className="list-section-header" aria-expanded={open} onClick={toggleOpen}>
				<div style={{ display: "flex", alignItems: "center" }}>
					<h2>{title}</h2>

					<span className={`open-icon`}>
						<Icon source={MobileChevronMajor} />
					</span>
				</div>
				{group && (
					<span
						className="open-newchat-button"
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							openNewChatModal();
						}}
					>
						<Icon source={CirclePlusMajor} />
					</span>
				)}
			</div>
			<Collapsible open={open}>
				<div className="List">
					{/* <div className="List hideScrollbar"> */}
					{loading && !!items?.length && <SkeletonLoader />}
					{items
						.sort((a, b) => {
							const aTitle = a.title || a.name || a.email;
							const bTitle = b.title || b.name || b.email;
							return aTitle?.localeCompare(bTitle);
						})
						.map((item, index) => (
							<ChatsListItem key={item.id} chat={!item.email && item} user={item.email && item} />
						))}

					<div type="button" className="chat-list-item new" onClick={openNewChatModal}>
						<ProfileAvatar initials="+" />
						<h1 className="name">{group ? "Lägg till ny kanal" : "Lägg till medarbetare"}</h1>
					</div>
				</div>
			</Collapsible>
		</React.Fragment>
	);
};

const SkeletonLoader = () => {
	return (
		<div type="button" className={`SkeletonLoader`}>
			<div className="profile" />
			<div className="name" />
		</div>
	);
};
