import React, { useContext, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
// import { useSelector } from "react-redux";
import styled from "styled-components";
import Colors from "../Colors";
import ChatContext from "../contexts/ChatContext";
import { store } from "store/index";
import { isInParentView } from "util/index";
import RichTextEditor from "./RichTextEditor/index";
import { debounce } from "lodash";

const InputField = React.forwardRef(({ chat, onSave, message, buttonTitle, onClose, style, ...rest }, ref) => {
	const { onTypingStart, onTypingCancel } = useContext(ChatContext);

	const setUserWriting = debounce(() => onTypingStart(chat?.id), 2500, { leading: true });

	const onTypingCancelDelayed = debounce(
		() => {
			setUserWriting.cancel();
			onTypingCancel(chat?.id);
		},
		10000,
		{ maxWait: 30000 }
	);

	useLayoutEffect(() => {
		setTimeout(() => {
			if (
				ref?.current &&
				!isInParentView(ref.current, document.querySelector(".mainWindow"))
				// && conchatmetainerRef.current?.getBoundingClientRect()?.height < document.querySelector(".mainWindow")?.getBoundingClientRect()?.height
			) {
				ref.current?.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "center" });
			}
		}, 100);
	}, [ref]);

	return (
		<div className="CommentInputContainer" style={style} ref={ref}>
			<WritingUsers chat={chat} />

			<RichTextEditor
				onSave={onSave}
				message={message}
				chat={chat}
				onTypingStart={onTypingStart}
				onTypingCancel={onTypingCancel}
				setUserWriting={setUserWriting}
				onTypingCancelDelayed={onTypingCancelDelayed}
				onClose={onClose}
			/>
		</div>
	);
});
export default InputField;

const WritingUsers = ({ chat }) => {
	const users = useSelector((state) => state.users_typing?.[chat.id]?.filter((user_id) => String(user_id) !== String(store.getState().user.id)));

	return (
		<StyledWritingUsersWrapper>
			{users?.map((user_id, index, arr) => {
				return <span className="loading-text">{store.getState().users.find((u) => String(u.id) === String(user_id))?.name} skriver</span>;
			})}
		</StyledWritingUsersWrapper>
	);
};

const StyledWritingUsersWrapper = styled.div`
	position: absolute;
	top: 0;
	transform: translateY(-100%);
	padding: 5px 0;
	color: ${Colors.subdued};
	font-size: 0.9em;
`;
