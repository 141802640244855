export const rgba = (color, alpha = 1) => {
	if (color.includes("#")) {
		const [r, g, b] = color.match(/\w\w/g).map((x) => parseInt(x, 16));
		return `rgba(${r},${g},${b},${alpha})`;
	}
	if (!color.includes("rgba") && color.includes("rgb")) {
		const [r, g, b] = color.match(/\d+/g);
		return `rgba(${r}, ${g}, ${b}, ${alpha})`;
	}

	return color;
};

const Colors = {
	blue: "#3588cc",
	lightblue: "#6eafe4",
	green: "#2abf5b",
	orange: "#eba342",
	red: "#f55353",
	pink: "#e368b3",
	yellow: "#f2c61b",
	purple: "#aa6df7",
	raspberry: "#ff0060",
	accent: "#02f4c4",
	primary: "#15182e",
	secondary: "#22253b",
	tertiary: "#2b2f48",
	textColor: "rgb(240, 240, 240)",
	subdued: "#72758e",

	rgba,
};

export default Colors;
