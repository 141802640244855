import { Modal, ResourceList } from "@shopify/polaris";
import Colors from "Colors";
import Badge from "components/Badge";
import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

export const getSocketStatusBadge = (item) => {
	switch (item.type) {
		case "connect":
		case "reconnect":
			return (
				<Badge fontSize={12} color={Colors.green}>
					{item.type}
				</Badge>
			);
		case "disconnect":
			return (
				<Badge fontSize={12} color={Colors.orange}>
					{item.type}
				</Badge>
			);
		case "error":
			return (
				<Badge fontSize={12} color={Colors.red}>
					{item.type}
				</Badge>
			);
		case "reconnect_attempt":
			return (
				<Badge fontSize={12} color={Colors.purple}>
					{item.type}
				</Badge>
			);
		default:
			return null;
	}
};

const SocketLogModal = ({ open, onClose }) => {
	const storeSocket = useSelector((state) => state.socket);

	const renderItem = (item, id, index) => {
		return (
			<ItemWrapper>
				<div style={{ display: "flex", justifyContent: "space-between" }}>
					{getSocketStatusBadge(item)} <ItemDate>{item.created_at}</ItemDate>
				</div>
				<b>{item.title}</b>
				{item.text && <p>{item.text}</p>}
			</ItemWrapper>
		);
	};

	return (
		<Modal title="Socket logs" open={open} onClose={onClose}>
			<Modal.Section>
				<ResourceList items={storeSocket?.logs || []} renderItem={renderItem} />
			</Modal.Section>
		</Modal>
	);
};
export default SocketLogModal;

const ItemWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	border-radius: 1rem;
	box-shadow: 0 3px 3px rgba(0, 0, 0, 0.25);
	padding: 1rem;
	margin: 1rem;
	background: ${Colors.primary};
`;

const ItemDate = styled.p`
	font-size: 0.9em;
	opacity: 0.5;
	text-align: end;
`;
