import { Avatar } from "@shopify/polaris";
import React, { Component } from "react";
import OnlineDot from "components/OnlineDot";
import MessageUserPopover from "./MessageUserPopover";

class ChatMemberAvatar extends Component {
	constructor(props) {
		super(props);
		this.state = { open: false };
	}

	toggleOpen() {
		if (this.props.disabled) return;
		if (this.props.onClick) {
			this.props.onClick();
			return;
		}
		this.setState((c) => ({ open: !c.open }));
	}

	onClose() {
		this.setState({ open: false });
	}

	render() {
		const { user = {} } = this.props;
		return (
			<MessageUserPopover
				user={user}
				activator={
					<span className="chat-member-avatar" onClick={this.toggleOpen.bind(this)}>
						<div style={{ position: "relative" }}>
							{!this.props.hideStatus && <OnlineDot user={user} />}
							<Avatar source={user.avatar || null} initials={user.initials} name={user.name} />
						</div>
					</span>
				}
			/>
		);
	}
}
export default ChatMemberAvatar;
