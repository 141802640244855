import Colors from "Colors";
import React from "react";
import { useMemo } from "react";
import styled from "styled-components";

const LoadingPinnedMessageSmall = () => {
	const titleWidth = useMemo(() => {
		return Math.max(15, Math.random() * 50);
	}, []);

	const textWidth = useMemo(() => {
		return Math.max(40, Math.random() * 100);
	}, []);

	return (
		<StyledOuterWrapper>
			<StyledWrapper>
				<StyledHeader>
					<StyledProfileWrapper>
						<StyledLoadingProfile />

						<StyledTitle width={titleWidth} />

						<StyledDate />
					</StyledProfileWrapper>
				</StyledHeader>
				<StyledText width={textWidth} />
			</StyledWrapper>
		</StyledOuterWrapper>
	);
};
export default LoadingPinnedMessageSmall;

const StyledOuterWrapper = styled.div`
	padding: 0.375rem;
`;
const StyledWrapper = styled.div`
	/* margin: 0.75rem; */
	background-color: ${Colors.primary};
	padding: 0.75rem;
	width: 100%;
	border-radius: 0.35rem;
	border: 1px solid ${Colors.secondary};
	min-height: 68px;
`;

const StyledHeader = styled.div`
	display: flex;
	justify-content: space-between;

	margin-bottom: 0.35rem;
`;

const StyledProfileWrapper = styled.div`
	display: flex;
	gap: 0.5rem;
	align-items: center;
	width: 100%;

	button.Profile-Avatar {
		padding: 0;
	}
`;

const StyledDate = styled.span`
	opacity: 0.5;
	width: 40px;
	background: ${Colors.secondary};
	height: 14px;
	border-radius: 5px;
`;
const StyledTitle = styled.span`
	width: ${({ width }) => width}%;
	background: ${Colors.secondary};
	line-height: 16px;
	height: 15px;
	border-radius: 5px;
`;

const StyledLoadingProfile = styled.div`
	border-radius: 13px;
	width: 26px;
	height: 26px;
	background: ${Colors.secondary};
`;
const StyledText = styled.div`
	border-radius: 5px;
	width: ${({ width }) => width}%;
	height: 22px;
	background: ${Colors.secondary};
`;
