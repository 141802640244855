import { Icon, Tooltip } from "@shopify/polaris";
import { PinMajor } from "@shopify/polaris-icons";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
// import ReactLinkify from "react-linkify";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import API from "API";
import Colors, { rgba } from "Colors";
import Attachment from "components/Attachment";
import InputField from "components/InputField";
import LinkPreview from "components/LinkPreview";
import { toastr } from "components/toastr";
import { store } from "store/index";
import ChatMemberAvatar from "./ChatMemberAvatar";
import ChatMessageActions, { StyledActionsTrigger } from "./ChatMessageActions";
import MessageUserPopover from "./MessageUserPopover";
import Viewer from "components/RichTextEditor/Viewer";

const ChatMessage = React.memo(({ message, prevMessage, nextMessage, index, setLockedScrollItem, handleScroll }) => {
	const [searchParams] = useSearchParams();
	const [editing, setEditing] = useState();
	const inputRef = useRef();
	const navigate = useNavigate();

	const chat = store.getState().chats.find((ch) => String(ch.id) === String(message.chat_id));

	const onSaveEdit = (msg) => {
		if (setLockedScrollItem) {
			setLockedScrollItem({
				currentScrollTop: document.querySelector('div[data-test-id="virtuoso-scroller"]')?.scrollTop,
				item: {
					offset: document.querySelector(`div[data-index="${index}"]`).offsetTop,
					index,
				},
			});
		}

		store.dispatch({ type: "UPDATE_CHAT_MESSAGE", message: { ...message, ...msg } });

		API.put(`/chats/${msg.chat_id}/messages/${message.id}.json`, msg)
			.then((result) => {
				console.log(" result.data.message :", result.data.message);
				store.dispatch({ type: "UPDATE_CHAT_MESSAGE", message: result.data.message });
				if (handleScroll) handleScroll();
			})
			.catch((error) => {
				toastr.error(error);
			});
	};

	useEffect(() => {
		const editor = inputRef.current?.querySelector("[contenteditable=true]");

		const keydown = (e) => {
			if (e.key === "Escape") {
				setEditing(false);
			}
		};

		//Condition: If textfield has focus
		//Maybe add if chatWindow have focus OR check if no modal/popover is open
		if (editing && editor && document.activeElement === editor) {
			editor.addEventListener("keydown", keydown);
		}

		return () => {
			if (editor && document.activeElement === editor) {
				editor.removeEventListener("keydown", keydown);
			}
		};
	}, [editing]);

	if (!message) return <StyledWrapper />;

	const user = store.getState().users.find((usr) => String(usr.id) === String(message.user_id));

	//FIX When the topmost message is not compact (have username and avatar) and we prepend messages, the latest topmost message should keep its not compact mode, otherwise it's height will shrink and make the list "jump/lagg"
	const compact =
		String(prevMessage?.user_id) === String(message.user_id) && moment(message.created_at).diff(moment(prevMessage?.created_at), "m") < 10;
	const nextSameUser = String(nextMessage?.user_id) === String(message.user_id);

	const navigateToUserChat = (e) => {
		e.preventDefault();
		e.stopPropagation();
		const userChatId = store
			.getState()
			?.chats.find((ch) => !ch.group && ch?.members.find((mem) => String(mem.user?.id) === String(message?.user_id)))?.id;
		const tempUserChatId = `${store.getState().user.id}-${user?.id}`;

		navigate(`/${store.getState().account.id}/chat/${userChatId || tempUserChatId}`);
	};

	// const imageFormats = ["png", "jpg", "jpeg"];

	const loading = message.files.some((file) => file.loading);

	return (
		<StyledWrapper
			loading={String(loading)}
			pinned={message.pinned}
			system={!message?.user_id}
			focused={String(searchParams.get("message_id")) === String(message.id)}
			mentioned={message?.content?.match?.(new RegExp("@" + store.getState().user.name, "i"))}
			sameuser={compact}
			nextsameuser={nextSameUser}
		>
			{compact ? (
				<StyledEmptyPlaceholderAvatar>{moment(message.created_at).format("HH:mm")}</StyledEmptyPlaceholderAvatar>
			) : user ? (
				<ChatMemberAvatar user={user} hideStatus />
			) : (
				<ChatMemberAvatar user={{ name: "[System]" }} hideStatus />
			)}

			<StyledInnerWrapper>
				{!compact && (
					<StyledTitleWrapper>
						{user && (
							<MessageUserPopover user={user} activator={<StyledTitle onClick={navigateToUserChat}>{user?.name || user?.email}</StyledTitle>} />
						)}
						<Tooltip content={moment(message.created_at).format("YYYY-MM-DD HH:mm:ss")}>
							<StyledDate>{moment(message.created_at).locale("sv").format("YYYY-MM-DD HH:mm")}</StyledDate>
						</Tooltip>
						{message.created_at && message.updated_at && !moment(message.created_at).isSame(moment(message.updated_at), "seconds") && (
							<Tooltip content={moment(message.updated_at).format("YYYY-MM-DD HH:mm:ss")}>
								<StyledIsEditedText>(redigerad)</StyledIsEditedText>
							</Tooltip>
						)}
						{message.pinned && (
							<Tooltip content={`Fäst av ${store.getState().users?.find((u) => String(u.id) === String(message?.pinned_by_user_id))?.name || "-"}`}>
								<StyledPinnedIcon>
									<Icon source={PinMajor} />
								</StyledPinnedIcon>
							</Tooltip>
						)}
					</StyledTitleWrapper>
				)}

				<ChatMessageActions
					message={message}
					editMessage={() => {
						setEditing(true);
					}}
					onSaveEdit={onSaveEdit}
				/>

				<StyledContentWrapper>
					{editing ? (
						<InputField
							ref={inputRef}
							chat={chat}
							message={message}
							onSave={(msg) => {
								onSaveEdit(msg);
								setEditing(false);
							}}
							onClose={() => {
								setEditing(false);
							}}
							style={{ paddingBottom: "10px" }}
						/>
					) : (
						<>
							<div>
								<Viewer message={message} />
								{compact && message.created_at && message.updated_at && !moment(message.created_at).isSame(moment(message.updated_at), "seconds") && (
									<Tooltip content={moment(message.updated_at).format("YYYY-MM-DD HH:mm:ss")}>
										<StyledIsEditedText>(redigerad)</StyledIsEditedText>
									</Tooltip>
								)}
							</div>

							{message.metadata && <LinkPreview data={message.metadata} />}
							{!!message?.files?.length && (
								<StyledMedia>
									{message?.files?.map(
										(file = {}, index) => (
											<Attachment key={file.id || index} file={file} />
										)

										// <StyledMediaLink key={`files-${index}`} href={file.url} target="_blank" rel="noreferrer">
										// 	{imageFormats.some((format) => file.url && file.url.endsWith(`.${format}`)) ? (
										// 		<Thumbnail size="large" source={file.url} />
										// 	) : (
										// 		<div>{file.filename}</div>
										// 	)}
										// </StyledMediaLink>
									)}
								</StyledMedia>
							)}
						</>
					)}
				</StyledContentWrapper>
			</StyledInnerWrapper>
		</StyledWrapper>
	);
});
export default ChatMessage;

const StyledEmptyPlaceholderAvatar = styled.div`
	width: 40px;
	float: left;
	height: 1px;
	margin-right: 1rem;
	opacity: 0;
	position: absolute;
	transition: opacity 250ms;

	&:hover {
		opacity: 1;
	}
`;

const StyledWrapper = styled.div`
	padding: ${({ sameuser, nextsameuser }) => (sameuser || nextsameuser ? 5 : 15) + "px"} 5px;
	position: relative;
	/* display: flex;
	gap: 1.25rem; */
	color: ${({ system }) => (system ? "rgba(240, 240, 240, 0.5)" : "var(--textColor)")};
	transition: background-color 100ms;
	border-radius: 2px;
	background: ${({ mentioned, pinned }) => (pinned ? rgba(Colors.blue, 0.2) : mentioned ? rgba(Colors.accent, 0.15) : "transparent")};
	border-left-color: ${({ mentioned }) => (mentioned ? Colors.accent : "transparent")};
	border-left-style: solid;
	border-left-width: 5px;
	opacity: ${({ loading }) => (loading === "true" ? 0.5 : 1)};

	box-shadow: ${({ focused }) => (focused ? "0px 0px 1px 1px white" : "none")};
	min-height: 25px;

	.CommentInputContainer {
		background: var(--secondary);
	}

	.mention {
		border-radius: 3px;
		padding: 2px 4px;

		&.me {
			color: ${Colors.yellow};
			background-color: ${rgba(Colors.yellow, 0.25)};
		}

		&:not(.me) {
			color: ${Colors.lightblue};
			background-color: ${rgba(Colors.lightblue, 0.25)};
		}
	}

	&:hover {
		/* background-color: var(--secondary); */
		background-color: ${({ mentioned, pinned, focused }) =>
			pinned ? rgba(Colors.blue, 0.2) : mentioned ? rgba(Colors.accent, 0.25) : Colors.secondary};

		${StyledEmptyPlaceholderAvatar} {
			opacity: 1;
		}
		${StyledActionsTrigger},
		.styled_actions_trigger {
			opacity: 1;
		}
	}
`;

const StyledInnerWrapper = styled.div`
	/* padding-left: 2rem; */
	margin-left: 55px;
	position: relative;
`;

export const StyledContentWrapper = styled.div`
	font-size: 13px;
	line-height: 15px;
	word-break: break-word;
	position: relative;

	pre.public-DraftStyleDefault-pre {
		background-color: ${rgba(Colors.secondary, 0.75)};
		color: #ffffff;
		border-radius: 5px;
		white-space: pre-wrap;
		margin-bottom: 5px;
		margin-top: 5px;
		padding: 5px 10px;
		border: 1px solid ${Colors.tertiary};
	}

	.mention {
		font-weight: bold;
		font-size: 0.9em;
		padding: 3px 5px;
		border-radius: 2px;
		color: ${Colors.accent};
		background-color: ${rgba(Colors.accent, 0.25)};
	}

	/* .mention[data-id="${store.getState().user?.id}"] { */
	.mention[data-id="${store.getState().user?.id}"] {
		color: ${Colors.yellow};
		background-color: ${rgba(Colors.yellow, 0.25)};
	}

	a {
		color: var(--linkColor);
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}
`;

const StyledTitleWrapper = styled.div``;

const StyledTitle = styled.span`
	font-size: 16px;
	font-weight: 700;
	line-height: 19px;
	margin-bottom: 0.35rem;
	padding-right: 1rem;
	color: var(--textColor);
	text-decoration: none;
	/* float: left; */
`;

const StyledDate = styled.span`
	opacity: 0.5;
`;

export const StyledMedia = styled.div`
	display: flex;
	gap: 1rem;
	flex-wrap: wrap;
`;

// const StyledMediaLink = styled.a`
// 	.Polaris-Thumbnail {
// 		width: 150px;
// 	}
// `;

const StyledIsEditedText = styled.span`
	color: var(--textColor2);
	margin-left: 1ch;
`;

// const StyledUnreadIndicator = styled.svg`
// 	position: absolute;
// 	left: 40px;
// 	top: 0;
// 	height: 25px;
// 	width: 25px;
// 	fill: ${Colors.lightblue};
// `;

const StyledPinnedIcon = styled.span`
	margin-left: 1ch;

	.Polaris-Icon {
		margin: 0;
		display: inline-block;

		svg {
			fill: ${Colors.textColor};
		}
	}
`;
