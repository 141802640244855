import { MentionData, MentionPluginTheme } from "@draft-js-plugins/mention";
import { ReactElement } from "react";
import ProfileAvatar from "../../ProfileAvatar";

export interface EntryComponentProps {
	className?: string;
	onMouseDown(event: MouseEvent): void;
	onMouseUp(event: MouseEvent): void;
	onMouseEnter(event: MouseEvent): void;
	role: string;
	id: string;
	"aria-selected"?: boolean | "false" | "true";
	theme?: MentionPluginTheme;
	mention: MentionData;
	isFocused: boolean;
	searchValue?: string;
}

export const Entry = (props: EntryComponentProps): ReactElement => {
	const {
		mention,
		theme,
		searchValue, // eslint-disable-line @typescript-eslint/no-unused-vars
		isFocused, // eslint-disable-line @typescript-eslint/no-unused-vars
		...parentProps
	} = props;
	const user = mention.user;

	return (
		//@ts-ignore
		<div {...parentProps}>
			<div className={theme?.wrapper}>
				<div className={theme?.wrapperLeft}>
					<ProfileAvatar user={user} />
				</div>

				<div className={theme?.wrapperRight}>
					<div className={theme?.mentionName}>{mention.name}</div>
				</div>
			</div>
		</div>
	);
};
