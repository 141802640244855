import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import ChatMessage from "views/chat/ChatMessage";
import ChatsListItem from "views/chat/ChatsListItem";
import StyledLoadingMessage from "views/chat/StyledLoadingMessage";
import Colors from "Colors";
import ChatMemberListItem from "components/ChatMemberListItem";
import PaginationList from "components/PaginationList";
import SmallAttachment from "components/SmallAttachment";
import { store } from "store/index";

const SearchContent = ({ tabId, messageData, fileData, params, loading, userData, chatData }) => {
	const navigate = useNavigate();
	console.log(messageData?.messages);
	if (tabId === "messages") {
		return (
			<PaginationList
				items={messageData?.messages}
				count={messageData.count}
				url={"/messages.json"}
				params={params}
				loading={loading}
				dataKey={"messages"}
				resourceName={"meddelanden"}
				onClick={(message, index) => {
					navigate(
						`/${store.getState().account.id}/chat/${message.chat_id}?` +
							new URLSearchParams({
								message_id: message.id,
							})
					);
				}}
				renderItem={(m, index) => {
					const chat = store.getState().chats?.find((chat) => String(chat.id) === String(m?.chat_id));
					const message = store.getState().chat_messages?.[chat?.id]?.find((me) => String(me.id) === String(m.id)) || m;

					if (loading) {
						return <StyledLoadingMessage index={index} />;
					}

					return (
						<React.Fragment key={message.id}>
							<div style={{ color: Colors.subdued }}>
								<span>{chat?.group ? `# ${chat?.title}` : chat?.title} - </span>
								<span>{moment(message.created_at).locale("sv").format("YYYY-MM-DD")}</span>
							</div>
							<ChatMessage key={message.id} message={message} />
						</React.Fragment>
					);
				}}
			/>
		);
	}
	if (tabId === "files") {
		return (
			<PaginationList
				items={fileData?.files}
				count={fileData.count}
				url={"/files.json"}
				params={params}
				loading={loading}
				dataKey={"files"}
				resourceName={"filer"}
				renderItem={(file, index) => {
					if (loading) {
						return <div className="loading" style={{ height: 25, borderRadius: 6, background: Colors.secondary }} />;
					}

					return <SmallAttachment key={file.id} file={file} />;
				}}
			/>
		);
	}
	if (tabId === "chats") {
		console.log("chatData:", chatData);
		return (
			<PaginationList
				items={chatData?.chats}
				count={chatData.count}
				url={"/chats.json"}
				params={params}
				loading={loading}
				dataKey={"chats"}
				resourceName={"kanaler"}
				renderItem={(chat, index) => {
					if (loading) {
						return <div className="loading" style={{ height: 25, borderRadius: 6, background: Colors.secondary }} />;
					}
					return <ChatsListItem key={chat.id} chat={!chat.email && chat} user={chat.email && chat} />;
				}}
			/>
		);
	}

	if (tabId === "users") {
		return (
			<PaginationList
				items={userData?.users}
				count={userData.count}
				url={"/users.json"}
				params={params}
				dataKey={"users"}
				loading={loading}
				resourceName={"användare"}
				renderItem={(user, index) => {
					if (loading) {
						return <div className="loading" style={{ height: 25, borderRadius: 6, background: Colors.secondary }} />;
					}
					return <ChatMemberListItem key={user.id} member={{ user }} />;
				}}
			/>
		);
	}

	return null;
};

const mapStateToProps = (state, ownProps) => ({
	chat_messages: state.chat_messages,
	users: state.users,
});

export default connect(mapStateToProps)(SearchContent);
