import { Tooltip } from "@shopify/polaris";
import moment from "moment";
import React, { useState } from "react";
import styled from "styled-components";
import ProfileAvatar from "components/ProfileAvatar";

const MessageUserPopover = ({ activator, user, disabled, chat }) => {
	const [open, setOpen] = useState();

	const onOpen = () => {
		setOpen(true);
	};

	const onClose = () => {
		setOpen(false);
	};

	if (disabled) return activator;

	return (
		<Tooltip
			onOpen={onOpen}
			onClose={onClose}
			originalActive={open}
			active={open}
			preferredPosition="above"
			content={
				<StyledWrapper>
					{user ? (
						<ProfileAvatar size={"large"} user={user} initials={chat?.group ? "#" : null} tooltip={false}>
							<StyledTextWrapper>
								<b>{user?.name || chat?.title}</b>
								<p>{user?.email}</p>
							</StyledTextWrapper>
						</ProfileAvatar>
					) : (
						<StyledTextWrapper>
							<b>{user?.name || chat?.title}</b>
							{chat?.last_message?.created_at && <p>Senast aktivitet {moment(chat?.last_message?.created_at).format("YYYY-MM-DD HH:mm")}</p>}
						</StyledTextWrapper>
					)}
				</StyledWrapper>
			}
		>
			{React.cloneElement(activator, { className: `${activator.props.className} activator` })}
		</Tooltip>
	);
};
export default MessageUserPopover;

const StyledWrapper = styled.div`
	padding: 0.5rem;
`;
const StyledTextWrapper = styled.div`
	text-align: start;
`;
