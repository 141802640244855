import React from "react";
import styled from "styled-components";
import Colors from "../Colors";

const TypingDots = ({ style }) => (
	<WrapperTyping className="typing" style={style}>
		<div></div>
		<div></div>
		<div></div>
	</WrapperTyping>
);

export default TypingDots;

const dotWidth = 3;
const dotColor = Colors.textColor;
const speed = "1.5s";

const WrapperTyping = styled.div`
	position: relative;
	z-index: 2;
	width: 30px;
	height: 30px;

	display: flex;
	align-items: center;
	justify-content: center;

	div {
		content: "";
		animation: blink ${speed} infinite;
		animation-fill-mode: both;
		height: ${dotWidth}px;
		width: ${dotWidth}px;
		background: ${dotColor};

		border-radius: 50%;

		&:nth-child(2) {
			animation-delay: 0.2s;
			margin-left: ${dotWidth}px;
		}

		&:nth-child(3) {
			animation-delay: 0.4s;
			margin-left: ${dotWidth}px;
		}
	}

	@keyframes blink {
		0% {
			opacity: 0.1;
		}
		20% {
			opacity: 1;
		}
		100% {
			opacity: 0.1;
		}
	}
`;
