import Colors from "Colors";
import { DocumentsIcon } from "icons";
import { useState } from "react";
import styled from "styled-components";

const CodeBlock = (props) => {
	const [alert, setAlert] = useState(false);
	// eslint-disable-next-line no-unused-vars
	const { block, contentState } = props;
	// const { foo } = props.blockProps;
	// try {
	// 	const data = contentState.getEntity(block.getEntityAt(0)).getData();
	// 	console.log("data:", data);
	// } catch (error) {
	// 	console.log("error:", error);
	// }

	// eslint-disable-next-line no-unused-vars
	const handleCopy = () => {
		window.navigator.clipboard.writeText(block.getText()).then(
			function () {
				console.log("Async: Copying to clipboard was successful!");
				setAlert("Kopierat till urklipp");
				setTimeout(() => {
					setAlert(false);
				}, 3500);
			},
			function (err) {
				console.error("Async: Could not copy text: ", err);
			}
		);
	};

	return (
		<>
			{block.getText()}

			<Button onClick={handleCopy} active={!!alert} disabled={alert}>
				{alert || <DocumentsIcon />}
			</Button>
		</>
	);
};

export default CodeBlock;

// eslint-disable-next-line no-unused-vars
const Button = styled.button`
	position: absolute;
	right: 30px;
	top: 0;
	color: #ffffff;

	background-color: ${({ active }) => (active ? Colors.tertiary : Colors.primary)};
	opacity: ${({ active }) => (active ? 1 : 0.5)};
	outline-color: none;
	border: 1px solid ${Colors.tertiary};
	margin: 5px;
	padding: 5px;
	transition: background-color 250ms, opacity 250ms;
	border-radius: 6px;

	svg {
		width: 1.5em;
		height: 1.5em;
		aspect-ratio: 1/1;
	}

	&:hover {
		opacity: 1;
		background-color: ${Colors.tertiary};
	}
`;
