import { Buffer } from "buffer";

const decodeTokenUser = (token: string | null): any => {
	if (!token || !token?.split(".")?.[1]) return null;
	const tokenData = JSON.parse(Buffer.from(token?.split(".")[1], "base64").toString());
	return tokenData;
};

const TTL = 1000 * (60 * 60) * 12;

const getTokenTTL = (token: string): number => {
	try {
		// eslint-disable-next-line new-cap
		const tokenData = decodeTokenUser(token);

		// eslint-disable-next-line no-unsafe-optional-chaining
		return ((tokenData?.exp || 0) * 1000 || Date.now() + TTL) - 60000;
	} catch (error) {
		console.error("getTokenTTL error:", error);
		return Date.now() + TTL - 60000;
	}
};

// eslint-disable-next-line import/prefer-default-export
export { decodeTokenUser, getTokenTTL, TTL };
