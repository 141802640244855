import React from "react";
import styled from "styled-components";

const DateLine = ({ children, hideLines, lineColor }) => {
	return (
		<StyledDateLine hideLines={hideLines} lineColor={lineColor}>
			<div className="line" />
			<span className="date">{children}</span>
			<div className="line" />
		</StyledDateLine>
	);
};

const StyledDateLine = styled.div.attrs({ className: "date-line" })`
	display: flex;
	align-items: center;

	/* position: sticky;
	top: 0; */

	.date {
		padding: 1rem;
	}

	.line {
		flex: 1;
		height: 2px;
		background: ${({ hideLines, lineColor }) => (hideLines ? "transparent" : lineColor || "var(--secondary)")};
	}
`;

export default DateLine;
