import { useEffect } from "react";
import { removeLocalStorage } from "util";
import { store } from "store";
import Spinner from "components/Spinner";
import { RemoveCookie } from "util";

const Logout = () => {
	useEffect(() => {
		const logout = () => {
			removeLocalStorage("userToken");
			removeLocalStorage("accountId");
			removeLocalStorage("jwt_token");
			removeLocalStorage("token");
			removeLocalStorage("refresh_token");
			// setLocalStorage("refresh_token", null);

			store.dispatch({ type: "SET_USER", user: null });
			store.dispatch({ type: "SET_ACCOUNT", user: null });

			store.dispatch({ type: "SET_CHATS", chats: [] });
			store.dispatch({ type: "SET_CHAT_MESSAGES", messages: {} });
			store.dispatch({ type: "SET_REF", ref: 0 });

			store.dispatch({ type: "SET_ACCOUNTS", accounts: [] });
			store.dispatch({ type: "SET_USERS", users: [] });
			store.dispatch({ type: "SET_TYPING", users: {} });
			store.dispatch({ type: "SET_MESSAGES_COUNTS", counts: {} });

			RemoveCookie("JWTTOKEN");

			window.location = "/login";
			// navigate("/login");
		};

		logout();
	}, []);

	return (
		<div className="wrapper">
			<div
				className="toolbar"
				style={{ height: "35px", width: "100%", position: "fixed", "-webkit-app-region": "drag", left: 0, right: 0, top: 0 }}
			/>
			<div className="left_wrapper">
				<h1>Loggar ut...</h1>
			</div>

			<div className="right_wrapper">
				<div className="form_wrapper" style={{ maxWidth: "unset", display: "flex", justifyContent: "center", alignItems: "center" }}>
					<Spinner size={Math.min(window.innerWidth, Math.min(window.innerHeight, window.innerHeight * 0.15))} />
				</div>
			</div>
		</div>
	);
};
export default Logout;
