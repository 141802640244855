import { useEffect, useState } from "react";
import Popover from "../../Popover";
import { Tooltip } from "@shopify/polaris";
import EmojiIcon from "../../../icons/EmojiIcon";
import buttonStyles from "./../buttonStyles.module.scss";
import Picker from "@emoji-mart/react";

import data from "@emoji-mart/data";
import styled from "styled-components";

const EmotePicker = ({ onChange, onOpen, onClose }) => {
	const [open, setOpen] = useState(false);

	useEffect(() => {
		if (open && onOpen) {
			onOpen();
		} else if (onClose) {
			onClose();
		}
	}, [onOpen, open, onClose]);

	return (
		<Wrapper
			onClick={(e) => {
				e.preventDefault();
				e.stopPropagation();
			}}
		>
			<Popover
				active={open}
				style={{ padding: 0 }}
				fluidContent
				onClose={() => setOpen(false)}
				activator={
					<Tooltip content="Emoji" hoverDelay={500} preferredPosition="above">
						<button
							className={buttonStyles.button}
							aria-expanded={open}
							onClick={() => {
								setOpen((c) => !c);
							}}
						>
							<EmojiIcon />
						</button>
					</Tooltip>
				}
			>
				<Picker
					autoFocus
					data={data}
					onEmojiSelect={(data) => {
						onChange(data.native);
						setOpen(false);
					}}
				/>
			</Popover>
		</Wrapper>
	);
};
export default EmotePicker;

const Wrapper = styled.span`
	& > div {
		display: inline-block;
	}
`;
