import React, { useState } from "react";
import { Button, TextField, Tooltip, Modal, Spinner } from "@shopify/polaris";
import { CancelMajor, EditMajor, SaveMinor } from "@shopify/polaris-icons";
import styled from "styled-components";
import { isImage } from "util/index";
import Colors from "../Colors";
import { store } from "store";

const Attachment = ({ file: originalFile, onRemove, onChange }) => {
	const [open, setOpen] = useState();
	const [editOpen, setEditOpen] = useState();
	const [file, setFile] = useState(originalFile);

	const onEditOpen = (e) => {
		e?.stopPropagation?.();
		setEditOpen(true);
		setOpen(false);
	};
	const onEditClose = (e) => {
		e?.stopPropagation?.();
		setEditOpen(false);
		setFile(originalFile);
	};

	const onOpen = (e) => {
		if (editOpen) return;
		e?.stopPropagation?.();
		setOpen(true);
	};
	const onClose = (e) => {
		e?.stopPropagation?.();
		setOpen(false);
	};

	const openImageDataInNewTab = () => {
		if (file.url) {
			const imageWindow = window.open(
				// `${file.url?.replace("/admin/", "/api/")}`
				`${file.url?.replace("/api/", "/admin/")}`
				// `${file.url?.replace("/admin/", "/api/")}?token=${store.getState().user.token}&acc_id=${store.getState()?.account?.id}`
			);
			imageWindow.document.close();
			return;
		}
		const image = new Image();
		image.src = file.data;

		const imageWindow = window.open("file", file.data);
		imageWindow.document.write(image.outerHTML);

		setTimeout(function () {
			imageWindow.document.title = file.name;
		}, 0);

		setTimeout(function () {
			imageWindow.document.title = file.name;
		}, 100);

		imageWindow.document.close();
	};

	const fileIconName = (() => {
		switch (file.type) {
			case "application/vnd.ms-excel":
			case "application/vnd.openxmlformats-officedocument.spreadsheetml":
			case "application/vnd.openxmlformats-officedocument.spreadsheetml.":
				return "XLS.png";
			case "application/x-zip-compressed":
			case "application/zip":
				return "ZIP.png";
			case "text/plain":
				return "TXT.png";
			case "application/msword":
				return "DOC.png";
			case "application/pdf":
				return "PDF.png";
			// case "application/psd":
			// 	return "PSD.png";
			// case "application/exe":
			// 	return "EXE.png";
			case "audio/mpeg":
				return "MP3.png";
			case "video/avi":
				return "AVI.png";
			case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
			case "application/vnd.ms-powerpoint":
				return "PPT.png";
			case "image/gif":
				return "GIF.png";
			case "image/jpeg":
			case "image/png":
				return "JPG.png";
			// case "image/png":
			// 	return "PNG.png";
			default:
				return null;
			// return "File.png";
		}
	})();

	// const url = `${file.url?.replace("/admin/", "/api/")}?token=${store.getState().user.token}&acc_id=${store.getState()?.account?.id}`;
	// const url = `${file.url?.replace("/admin/", "/api/")}`;
	const url = `${file.url?.replace("/api/", "/admin/")}?token=${store.getState().user.token}&acc_id=${store.getState()?.account?.id}`;

	return (
		<Wrapper onClick={onOpen} isImage={isImage(file.path || file.name || file.url)}>
			{isImage(file.type) ? (
				<div style={{ position: "relative" }}>
					<StyledImage src={file.data || url} />
					{fileIconName && <StyledSmallIcon alt="icon" src={`/icons/${fileIconName}`} />}
				</div>
			) : (
				<StyledFile onClick={(e) => e.stopPropagation()} href={url} download={file.filename || file.name} target="_blank" rel="noreferrer">
					{fileIconName && <img alt="icon" src={`/icons/${fileIconName}`} />}
					<StyledTextWrapper>
						<StyledFileName>{file.filename || file.name}</StyledFileName>
						<StyledFileType filname={file.filename || file.name}>{file.type}</StyledFileType>
					</StyledTextWrapper>
				</StyledFile>
			)}

			{file.loading ? (
				<StyledLoadingSpinner>
					<Spinner size="small" />
				</StyledLoadingSpinner>
			) : (
				<StyledActionMenu size={file.id ? "large" : "small"} onClick={(e) => e.stopPropagation()}>
					{file.id && (
						<Tooltip content="Ladda ner">
							<Button
								download
								url={
									url
									// `https://chatsocket.sellfinity.com/download?` +
									// new URLSearchParams({
									// 	token: store.getState().user?.token,
									// 	acc_id: store.getState().account?.id,
									// 	url: encodeURI(file.url),
									// })
								}
								target="_blank"
								icon={SaveMinor}
							/>
						</Tooltip>
					)}
					<Modal
						activator={
							onChange && (
								<Tooltip content="Redigera fil">
									<Button onClick={onEditOpen} icon={EditMajor} />
								</Tooltip>
							)
						}
						open={editOpen}
						onClose={onEditClose}
						title={`Redigera fil "${originalFile?.name || originalFile?.filename || ""}"`}
						primaryAction={{
							content: "Spara",
							onAction: (e) => {
								onChange?.(file, false);
								setEditOpen(false);
							},
						}}
						secondaryActions={[
							{
								content: "Ta bort",
								onAction: onRemove,
								destructive: true,
							},
							{
								content: "Avbryt",
								onAction: onEditClose,
							},
						]}
					>
						<Modal.Section>
							<TextField
								label="Fil namn"
								placeholder={"Skriv in namn på filen..."}
								value={file.name || file.filename}
								onChange={(value) => {
									setFile((c) => ({
										...c,
										name: value,
										filename: value,
									}));
								}}
							/>
						</Modal.Section>
					</Modal>
					<Modal
						large
						fullScreen={true}
						noScroll={true}
						open={open}
						onClose={onClose}
						title={
							<div>
								<span style={{ marginRight: "1rem" }}> {`${file.name || originalFile?.filename || ""}`}</span>
								{onChange && (
									<Tooltip content="Redigera fil">
										<Button plain icon={EditMajor} onClick={onEditOpen} />
									</Tooltip>
								)}
								<StyledSubText>
									{file.size > 1048576 ? (
										<span style={{ whiteSpace: "nowrap", fontSize: "0.9em" }}>{(file.size / 1048576).toFixed(1) || "-"} MB</span>
									) : (
										<span style={{ whiteSpace: "nowrap", fontSize: "0.9em" }}>{Math.ceil(file.size / 1024) || "-"} kB</span>
									)}
								</StyledSubText>
								<StyledSubText>{file.type}</StyledSubText>
							</div>
						}
						limitHeight={false}
					>
						<Modal.Section>
							<StyledFullscreenImage onClick={openImageDataInNewTab} src={file.data || url} />
						</Modal.Section>
					</Modal>
					{onRemove && (
						<Tooltip content="Ta bort fil">
							<Button icon={CancelMajor} onClick={onRemove} />
						</Tooltip>
					)}
				</StyledActionMenu>
			)}
		</Wrapper>
	);
};

const StyledActionMenu = styled.div`
	position: absolute;
	right: -10px;
	top: -10px;
	display: flex;
	flex-direction: row;
	gap: 0.5rem;
	z-index: 2;
	opacity: 0;
	transition: opacity 250ms;

	button,
	a {
		padding: 0.5rem;
		border-radius: 50%;
		min-height: unset;
		min-width: unset;
		border: none;

		&:not(.Polaris-Button--destructive) {
			background: var(--secondary);
		}

		.Polaris-Icon {
			width: ${({ size }) => (size === "large" ? 20 : 12)}px;
			height: ${({ size }) => (size === "large" ? 20 : 12)}px;
		}
	}
`;

const StyledSmallIcon = styled.img`
	position: absolute;
	left: 3px;
	bottom: 6px;
	z-index: 2;
	width: 30px;
	max-width: 100%;
	border-bottom-left-radius: 7px;
	opacity: 0;
	transition: opacity 250ms;
`;

const Wrapper = styled.div`
	position: relative;
	width: max-content;
	cursor: pointer;
	transition: filter 250ms;
	min-height: 40px;
	height: ${({ isImage }) => (isImage ? "150px" : "max-content")};

	align-items: center;
	display: flex;
	max-width: 400px;

	&:hover {
		filter: brightness(1.2);
		${StyledActionMenu} {
			opacity: 1;
		}
		${StyledSmallIcon} {
			opacity: 1;
		}
	}

	.Polaris-Icon {
		margin: auto 0;
		width: 1.4em;
		height: 1.4em;
	}
`;

const StyledImage = styled.img`
	max-height: 150px;
	max-width: 300px;
	border-radius: 10px;
	box-shadow: 2px 2px 4px #00000061;
	transition: height 250ms;
	object-fit: contain;
`;
const StyledFullscreenImage = styled.img`
	height: 100%;
	border-radius: 10px;
	box-shadow: 2px 2px 4px #00000061;
	cursor: pointer;
	width: 100%;
	object-fit: contain;
`;

const StyledSubText = styled.p`
	font-size: 12px;
	color: var(--textColor2);
`;

export const RemoveButton = styled.div.attrs({ className: "removeButton" })`
	position: absolute;
	right: 0;
	top: 0;
	transform: translate(40%, -50%);

	button {
		padding: 0.5rem;
		border-radius: 50%;
		min-height: unset;
		min-width: unset;
	}

	.Polaris-Icon {
		width: 12px;
		height: 12px;
	}
`;

const StyledFile = styled.a`
	border-radius: 10px;
	height: 50px;
	text-overflow: ellipsis;
	overflow: hidden;
	-webkit-line-clamp: 1;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	word-break: break-all;
	padding: 0.5rem;
	display: flex;
	align-items: center;
	border: 1px solid ${Colors.tertiary};
	gap: 0.75rem;

	&&& {
		text-decoration: none;
		color: ${Colors.textColor};
	}

	img {
		height: 80%;
	}
`;

const StyledTextWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

const StyledFileName = styled.p`
	text-decoration: none;
	text-overflow: ellipsis;
	overflow: hidden;
	-webkit-line-clamp: 1;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	word-break: break-all;
`;
const StyledFileType = styled.p`
	color: rgba(255, 255, 255, 0.5);
	font-size: 0.9em;
	text-overflow: ellipsis;
	overflow: hidden;
	-webkit-line-clamp: 1;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	word-break: break-all;
	max-width: ${({ filname }) => filname?.length}ch;
`;

const StyledLoadingSpinner = styled.div`
	position: absolute;
	top: 0;
	right: 0;
`;

export default Attachment;
