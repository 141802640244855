import { ActionList, Button, Modal } from "@shopify/polaris";
import { CancelMinor, EditMajor, MobileVerticalDotsMajor, PinMajor } from "@shopify/polaris-icons";
import React, { useState } from "react";
import ReactLinkify from "react-linkify";
import nl2br from "react-nl2br";
import styled from "styled-components";
import API from "API";
import Attachment from "components/Attachment";
import LinkPreview from "components/LinkPreview";
import Popover from "components/Popover";
import { toastr } from "components/toastr";
import { store } from "store/index";
import { isImage } from "util/index";
import { StyledContentWrapper, StyledMedia } from "./ChatMessage";
import { useEffect } from "react";
import { useCallback } from "react";

const ChatMessageActions = ({ message, editMessage, onSaveEdit }) => {
	const [open, setOpen] = useState();
	const [removeOpen, setRemoveOpen] = useState();

	const onClose = () => {
		setOpen(false);
	};
	const onToggle = () => {
		setOpen((c) => !c);
	};

	const onRemoveClose = () => {
		setRemoveOpen(false);
	};
	const onRemoveToggle = () => {
		setRemoveOpen((c) => !c);
	};

	const removeMessage = () => {
		store.dispatch({ type: "REMOVE_CHAT_MESSAGE", message });

		API.delete(`/chats/${message?.chat_id}/messages/${message?.id}.json`)
			.then((result) => {
				store.dispatch({ type: "REMOVE_CHAT_MESSAGE", message: result.data.message });
			})
			.catch((error) => {
				toastr.error(error);
			});
	};

	const togglePinned = useCallback(() => {
		message.pinned = !message.pinned;

		store.dispatch({ type: "UPDATE_CHAT_MESSAGE", message: { ...message } });

		API.put(`/chats/${message.chat_id}/messages/${message.id}/pin.json`, message)
			.then((result) => {
				store.dispatch({ type: "UPDATE_CHAT_MESSAGE", message: result.data.message });
			})
			.catch((error) => {
				toastr.error(error);
			});
	}, [message]);

	const actions = [
		{
			content: (
				<TextWrapper>
					<span>{message.pinned ? "Lossa medddlandet" : "Fäst meddelandet"}</span>
					<span>(P)</span>
				</TextWrapper>
			),
			onAction: togglePinned,
			disabled: !message.id,
			icon: PinMajor,
		},
		{
			content: (
				<TextWrapper>
					<span>Redigera meddelande</span>
					<span>(E)</span>
				</TextWrapper>
			),
			onAction: editMessage,
			disabled: !message.id,
			icon: EditMajor,
			enabled: String(message?.user_id) === String(store.getState().user.id),
		},
		{
			content: <TextWrapper>Ta bort meddelande</TextWrapper>,
			onAction: onRemoveToggle,
			disabled: !message.id,
			icon: CancelMinor,
			enabled: String(message?.user_id) === String(store.getState().user.id),
		},
	].filter((action) => !("enabled" in action) || action.enabled);

	useEffect(() => {
		const onKeyDown = (e) => {
			switch (e.key) {
				case "p":
					togglePinned();
					break;
				case "e":
					if (String(message?.user_id) === String(store.getState().user.id)) {
						editMessage();
						onClose();
					}
					break;
				default:
					break;
			}
		};

		if (open) {
			window.addEventListener("keydown", onKeyDown);
		}

		return () => {
			window.removeEventListener("keydown", onKeyDown);
		};
	}, [open, togglePinned, editMessage, message?.user_id]);

	return (
		<>
			<Popover
				active={open}
				onClose={onClose}
				activator={
					<StyledActionsTrigger open={open}>
						<Button onClick={onToggle} plain icon={MobileVerticalDotsMajor} />
					</StyledActionsTrigger>
				}
			>
				<Wrapper onClick={onClose}>
					<ActionList items={actions} />
				</Wrapper>
			</Popover>
			<Modal
				title="Ta bort meddelande"
				open={removeOpen}
				onClose={onRemoveClose}
				secondaryActions={[
					{ content: "Avbryt", onAction: onRemoveClose },
					{
						content: "Ta bort",
						onAction: removeMessage,
						destructive: true,
					},
				]}
			>
				<Modal.Section>
					<StyledContentWrapper>
						<>
							<ReactLinkify
								componentDecorator={(decoratedHref, decoratedText, key) => (
									<a href={decoratedHref} key={Math.random()} target="_blank" rel="noreferrer">
										{decoratedText}
									</a>
								)}
							>
								<div
								// dangerouslySetInnerHTML={{
								// 	__html: nl2br(message.content)?.map((m) => {
								// 		return m?.replace("@Robin Persson", `<b class="mention me">@Robin Persson</b>`);
								// 	}),
								// }}
								>
									{nl2br(message.content)}
								</div>
							</ReactLinkify>

							{message.metadata && <LinkPreview data={message.metadata} />}
							{!!message?.files?.length && (
								<StyledMedia>
									{message?.files?.map(
										(file = {}, index) =>
											isImage(file.path || file.url || file.filename || file.name) ? (
												<Attachment key={file.id || index} file={file} />
											) : (
												<a href={file.url} key={file.id || index} target="_blank" rel="noreferrer">
													{file.filename}
												</a>
											)

										// <StyledMediaLink key={`files-${index}`} href={file.url} target="_blank" rel="noreferrer">
										// 	{imageFormats.some((format) => file.url && file.url.endsWith(`.${format}`)) ? (
										// 		<Thumbnail size="large" source={file.url} />
										// 	) : (
										// 		<div>{file.filename}</div>
										// 	)}
										// </StyledMediaLink>
									)}
								</StyledMedia>
							)}
						</>
					</StyledContentWrapper>
				</Modal.Section>
			</Modal>
		</>
	);
};
export default ChatMessageActions;

export const StyledActionsTrigger = styled.div.attrs({ className: "styled_actions_trigger" })`
	position: absolute;
	right: 1rem;
	top: 0;
	opacity: ${({ open }) => (open ? 1 : 0)};
	transition: opacity 150ms;
	cursor: pointer;
	padding: 0rem;
	z-index: 2;

	.Polaris-Icon svg {
		color: #ffffff;
	}

	&:hover {
		opacity: 1;
	}
`;

const Wrapper = styled.div`
	width: 100%;
	min-width: 200px;
`;

const TextWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	gap: 1rem;
`;
